<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="box">
            <div class="login-acct">
              <div class="logo">
                <img src="../assets/_logo1.png" alt="supportive" />
              </div>
              <h3>Purchase EasyVSL plan</h3>
              <div class="contact">
                You must have a subscription plan to log in to the system.
                <br />
                Please click the button to proceed to purchase.
              </div>
              <div>
                <button class="submitButton">
                  <a href="https://easyvsl.com/oto2-v4/" target="_blank"
                    >Purchase</a
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script setup></script>
  <style lang="scss" scoped>
h3 {
  text-align: center !important;
}
div {
  color: #353535;
  margin-top: 16px;
  a {
    color: gray;
    &:hover {
      color: blue;
    }
  }
}
.contact {
  margin-bottom: 36px;
}
button.submitButton {
  font-family: "Rubik Regular";
  border: none;
  width: 100%;
  background: #0967d2;
  border-radius: 30px;
  padding: 24px 0;
  margin: 0px 0 44px 0;
  color: #fff;
  &:hover {
    background-color: #0967d2c7;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
}
</style>
  