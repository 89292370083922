<template>
  <div
    class="modal fade modal-lg"
    id="upgradeCreativePlan"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="upgradeCreativePlanLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="upgradeCreativePlanLabel">
            Upgrade to Creative Club
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>The 10,000 stock music, 1M stock photos, 50,000 stock videos, 40,000 stock illustrations, 2,000 stock vectors and 150+ kinetic video templates are for Creative Club members only.</p>
          <p>Click the button below to upgrade to Creative Club for $29/mo or $297/yr.</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn cancelButton d-flex gap-2 align-items-center"
            data-bs-dismiss="modal"
          >
            <span>Cancel</span> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
          </button>
          <button @click="showSecondModal('monthly')" type="button" class="btn successButton d-flex gap-2 align-items-center"><span>Monthly</span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check"><circle cx="12" cy="12" r="10"/><path d="m9 12 2 2 4-4"/></svg></button>
          <button @click="showSecondModal('yearly')" type="button" class="btn successButton d-flex gap-2 align-items-center"><span>Yearly</span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check"><circle cx="12" cy="12" r="10"/><path d="m9 12 2 2 4-4"/></svg></button>
        </div>
      </div>
    </div>
  </div>
  
  <div
    class="modal fade modal-lg"
    id="upgradeCreativeTwoPlan"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="upgradeCreativePlanTwoLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="upgradeCreativePlanTwoLabel">
            Upgrade to Creative Club
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ secondModalText }}</p>
          <p>Do you wish to proceed?</p>
        </div>
        <div class="modal-footer w-100">
          <button
            style="width: 48%;"
            type="button"
            class="btn cancelButton d-flex gap-2 align-items-center"
            data-bs-dismiss="modal"
          >
            <span>Cancel</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
          </button>
          <a
            :href="upgradeLink"
            style="width: 48%;"
            class="btn successButton d-flex gap-2 align-items-center justify-content-center text-decoration-none"
          >
            <span>Upgrade</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check"><circle cx="12" cy="12" r="10"/><path d="m9 12 2 2 4-4"/></svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Modal } from 'bootstrap';

const secondModalText = ref('');
const upgradeLink = ref('');

const showSecondModal = (pricing) => {
  if (pricing === 'monthly') {
    secondModalText.value = 'You will be charged $29 per month and will be instantly upgraded to the Creative Club.';
    upgradeLink.value = 'https://app.paykickstart.com/checkout/7AB6aKpmg5x2vjVDPvW9MlXQ0R3r1yOo';
  } else {
    secondModalText.value = 'You will be charged $297 per year and will be instantly upgraded to the Creative Club.';
    upgradeLink.value = 'https://app.paykickstart.com/checkout/v0AylB9GaweQ4WodwYk6JNYbMKdm7gLO';
  }

  const firstModalElement = document.getElementById('upgradeCreativePlan');
  const firstModal = Modal.getInstance(firstModalElement);
  if (firstModal) firstModal.hide();

  const secondModalElement = document.getElementById('upgradeCreativeTwoPlan');
  const secondModal = new Modal(secondModalElement);
  secondModal.show();
};

// Commented out API-related upgrade code
/*
const upgradeSubscription = async (billingPeriod) => {
  // ... (previous code)
};
*/
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    top: 0px;
    height: 100%;
    max-height: 100vh;
    display: flex;
    align-items: center;
  }
  .modal-content {
    width: 100% !important;
    padding: 18px 15px 18px 15px !important;
    border-radius: 6px !important;
    border-bottom: 1px solid #e9ecf4 !important;
  }
  .modal-header {
    color: #a3adcd !important;
    padding: 16px 4px;
  }
  .modal-body {
    padding: 16px 4px;
    text-align: left;
    label {
      margin-bottom: 12px;
    }
  }
  .modal-footer {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    gap: 12px;
    padding: 12px 0 !important;
    border: none;
    justify-content: flex-start;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 225px;
      height: 38px;
      border-radius: 6px;
      font-size: 13px;
    }
    .successButton {
      background: #03d670 !important;
      color: #ffffff;
    }
    .cancelButton {
      border: 1.5px solid #ebedf3 !important;
      color: #333333;
    }
  }
}
</style>