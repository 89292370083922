
<template>
  <div>
    <section>
      <!-- integrations strat -->
      <section class="myAccHead integrations">
        <div class="container-fluid contentContainer">
          <div class="row">
            <div class="detailNav">
              <a href="#"><img
                src="../assets/IntegrationsIcon.png"
                class="img-fluid"
                alt="img"
              >Integrations</a>                        
            </div>
            <div class="hrLine" />
          </div>
                  
        </div>
      </section>
      <!-- integrations end -->
      <!-- images start -->
      <section class="imgInterSec">
        <div class="container-fluid contentContainer">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="interImgList">
                <img
                  src="../assets/youTubeIcon.png"
                  alt="img"
                  class="img-fluid"
                >
                <div class="interBtnFotter">
                  <button
                    type="button"
                    class="btn btn-danger remove"
                  >Remove</button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="interImgList">
                <img
                  src="../assets/2560px-Vimeo_Logo-1.png"
                  alt="img"
                  class="img-fluid"
                >
                <div class="interBtnFotter">
                  <button
                    type="button"
                    class="btn btn-danger remove"
                  >Remove</button>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </section>
    </section>
  </div>
    
     
  </template>
<script setup>

  import { onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  const router = useRouter()
  onMounted(() => {
  if(!localStorage.getItem('vsl-token')){
    router.push('/login')
  }
})
</script>