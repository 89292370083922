<template>
    <nav>
        <the-default-layout v-if="auth">
            <template #default>
                        <section
                          v-if="adminLoginasUser"
                          class="myAccHead"
                          :class="localStorageItem ? 'myAccHeadForAdmin' : ''"
                        >
                          <div class="container-fluid contentContainer">
                            <section class="alert loginAlert switch" role="alert">
                              <div class="switch__text">
                                You have logged in to the
                                {{
                                  `${firstName ? firstName : ""} ${lastName ? lastName : ""}'s`
                                }}
                                page
                              </div>
                              <div class="switch__back" @click="switchBack">
                                <UtilsIcon
                                  class="dropdownIcon"
                                  name="logout"
                                  width="23"
                                  height="23"
                                  color="#fff"
                                ></UtilsIcon>
                                <span>Switch back</span>
                              </div>
                            </section>
                          </div>
                        </section>
                        <router-view
                          @updateProfileHeader="updateProfileHeader"
                          @updateLicenseKeys="updateLicenseKeys"
                        />
</template>
    </the-default-layout>
    <the-login-layout v-else>
<template #login>
    <router-view @login="makeLogin" @loginUserPage="loginUserPage" @updateProfileHeader="updateProfileHeader" />
</template>
    </the-login-layout>
  </nav>
</template>

<script setup>
import { ref, computed, onMounted, watch, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "./store";
import UtilsIcon from "./components/utils/UtilsIcon.vue";
import TheDefaultLayout from "./layouts/TheDefaultLayout.vue";
import TheLoginLayout from "./layouts/TheLoginLayout.vue";
const route = useRoute();
const emitter = inject("emitter");
const router = useRouter();
const auth = computed(() => {
    return route.meta.auth;
});

const adminLoginasUser = ref(false);
const localStorageItem = computed(() => localStorage.getItem("globalToken"));
const authToken = computed(() => store.getters.getAuthToken);
const refreshToken = computed(() => store.getters.getRefreshToken);
const userData = computed(() => store.getters.getCurrentUser);
const userDataRole = ref();

function updateProfileHeader() {
    store.dispatch("updateCurrentUser");
}
const updateLicenseKeys = () => {
    store.dispatch("updateLicenseKeys", { status: "VALID" });
    store.dispatch("updateLicenseKeys", { status: "ACTIVE" });
};
const firstName = computed(() => userData.value.firstName);
const lastName = computed(() => userData.value.lastName);
const userDataSubscription = ref();

const makeLogin = ({ email, password }) => {
    store.dispatch("signIn", {
        email: email.value,
        password: password.value,
    });
};
const loginUserPage = () => {
    store.dispatch("updateCurrentUser");
};

function switchBack() {
    store.dispatch("logoutUserPage");
    adminLoginasUser.value = null;
    if (localStorage.getItem("globalRole")) {
        localStorage.setItem("vsl-token", authToken);
        localStorage.setItem("refresh-token", refreshToken);
        localStorage.removeItem("globalRefreshToken");
        localStorage.removeItem("globalToken");
        localStorage.removeItem("globalRole");
        store.dispatch("updateLicenseKeys", { status: "ACTIVE" });
        store.dispatch("updateLicenseKeys", { status: "VALID" });
    }
    // nextTick(() => {
    //   router.push("/users");
    // });
    router.push("/users");
}

watch(userData, () => {
    if (userData.value) {
        userDataSubscription.value = userData.value.subscription;
        userDataRole.value = userData.value.role;
        if (userData.value.status === "DEACTIVATED") {
            store.dispatch("logOut");
            router.push("/deactivate-profile-page");
        }
    }
    // store.dispatch("updateLicenseKeys", { status: "ACTIVE" });
    // store.dispatch("updateLicenseKeys", { status: "VALID" });
});

onMounted(() => {
    store.dispatch("updateCurrentUser");
    if (localStorage.getItem("globalToken")) {
        adminLoginasUser.value = true;
    } else {
        adminLoginasUser.value = false;
    }
});

emitter.on("loginUserPage", () => {
    setTimeout(() => {
        if (localStorage.getItem("globalToken")) {
            adminLoginasUser.value = true;
        } else {
            adminLoginasUser.value = false;
        }
    }, 700);
});

watch(
    () => localStorage.getItem("globalToken"),
    () => {
        console.log(
            'localStorage.getItem("globalToken")',
            localStorage.getItem("globalToken")
        );
    }
);
</script>

<style lang="scss">
#app {
    font-family: Nunito Medium;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    a {
        font-weight: bold;
        color: #2c3e50;
        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    &__back {
        display: flex;
        gap: 15px;
        color: white;
        background: blue !important;
        padding: 11px 20px;
        border-radius: 6px;
        cursor: pointer;
        font-size: 16px !important;
    }
}

.loginAlert {
    background: #ecf1fe !important;
    border: 1px solid #2a61ff !important;
    font-size: 20px;
    color: #2a61ff !important;
    font-weight: 600;
}

.myAccHead {
    padding: 60px 0 20px 0 !important;
}
</style>
