import { get } from "@/utils";

const charts = {
    state: () => ({
        // subscription chart
        chartsDataLoading: false,
        chartsErrorMessage: null,
        subscriptionCharts: {
            titles: new Set(),
            charts: [],
            months: new Set()
        },
        // ChatGPT chart
        chatGptUsage: null,
        chatGptMonths: [],
        chatGptDataLoading: false,
        chatGptErrorMessage: null,
        // DeepGram chart
        deepGramUsage: null,
        deepGramMonths: [],
        deepGramDataLoading: false,
        deepGramErrorMessage: null,

        monthlyChartData: null,
    monthlyChartDataLoading: false,
    monthlyChartDataError: null,
    
    }),

    getters: {
        // subscription chart
        getSubscriptionCharts: state => state.subscriptionCharts,
        getChartsDataLoading: (state) => state.chartsDataLoading,
        getChartsErrorMessage: state => state.chartsErrorMessage,
        // ChatGPT chart
        getChatGptData: (state) => [
            {
                name: "Base Credits",
                group: "credits",
                data: state.chatGptUsage ? state.chatGptUsage.baseCredit.map(item => parseFloat(item.totalScryptGenerator) || 0) : [],
            },
            {
                name: "Extra Credits",
                group: "credits",
                data: state.chatGptUsage ? state.chatGptUsage.extraCredit.map(item => parseFloat(item.totalScryptGenerator) || 0) : [],
            },
        ],
        getChatGptMonth: state => state.chatGptMonths,
        getChatGptDataLoading: state => state.chatGptDataLoading,
        getChatGptErrorMessage: state => state.chatGptErrorMessage,
        // DeepGram chart
        getDeepGramData: (state) => [
            {
                name: "Base Credits",
                group: "credits",
                data: state.deepGramUsage ? state.deepGramUsage.baseCredit.map(item => parseFloat(item.totalAudioText) || 0) : [],
            },
            {
                name: "Extra Credits",
                group: "credits",
                data: state.deepGramUsage ? state.deepGramUsage.extraCredit.map(item => parseFloat(item.totalAudioText) || 0) : [],
            },
        ],
        getDeepGramMonth: state => state.deepGramMonths,
        getDeepGramDataLoading: state => state.deepGramDataLoading,
        getDeepGramErrorMessage: state => state.deepGramErrorMessage,
        getMonthlyChartData: state => state.monthlyChartData,
    getMonthlyChartDataLoading: state => state.monthlyChartDataLoading,
    getMonthlyChartDataError: state => state.monthlyChartDataError,
        
    },

    mutations: {
        // subscription chart
        setChartsDataObject: (state, data) => {
            if (data?.length > 0) {
                for (const item of data) {
                    state.subscriptionCharts.charts = []
                    state.subscriptionCharts.titles.add(item.productNameGroup)
                    state.subscriptionCharts.months.add(item.month)
                }
                let arr1 = Array.from(state.subscriptionCharts.titles)
                for (let i = 0; i < arr1.length; i++) {
                    state.subscriptionCharts.charts.push({ name: arr1[i], group: "credits", data: [] })
                    const typeArr = data.filter((d) => d.productNameGroup === arr1[i])
                    let arr = Array.from(state.subscriptionCharts.months)
                    for (let j = 0; j < arr?.length; j++) {
                        let dateOfMonth = typeArr.filter((type) => type.month === arr[j])
                        if (dateOfMonth?.length) {
                            state.subscriptionCharts.charts[i].data.push(dateOfMonth[0].userCount)
                        } else {
                            state.subscriptionCharts.charts[i].data.push(0)
                        }
                    }
                }

                const monthsArr = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                state.subscriptionCharts.titles = Array.from(state.subscriptionCharts.titles)
                state.subscriptionCharts.months = Array.from(state.subscriptionCharts.months).map((month) => monthsArr[month])
            } else {
                state.subscriptionCharts = {
                    titles: new Set(),
                    charts: [],
                    months: new Set()
                }
            }
        },
        setChartsDataLoading: (state, isLoading) => state.chartsDataLoading = isLoading,
        setChartsErrorMessage: (state, message) => state.chartsErrorMessage = message,
        SET_CHAT_GPT_USAGE(state, data) {
            state.chatGptUsage = data;
            if (data) {
                const monthsArr = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                state.chatGptMonths = data.baseCredit.map(item => monthsArr[parseInt(item.month)]);
            }
        },
        SET_CHAT_GPT_LOADING(state, isLoading) {
            state.chatGptDataLoading = isLoading;
        },
        SET_CHAT_GPT_ERROR(state, error) {
            state.chatGptErrorMessage = error;
        },
        // DeepGram chart mutations
        SET_DEEP_GRAM_USAGE(state, data) {
            state.deepGramUsage = data;
            if (data) {
                const monthsArr = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                state.deepGramMonths = data.baseCredit.map(item => monthsArr[parseInt(item.month)]);
            }
        },
        SET_DEEP_GRAM_LOADING(state, isLoading) {
            state.deepGramDataLoading = isLoading;
        },
        SET_DEEP_GRAM_ERROR(state, error) {
            state.deepGramErrorMessage = error;
        },
        SET_MONTHLY_CHART_DATA(state, data) {
            state.monthlyChartData = data;
          },
          SET_MONTHLY_CHART_DATA_LOADING(state, isLoading) {
            state.monthlyChartDataLoading = isLoading;
          },
          SET_MONTHLY_CHART_DATA_ERROR(state, error) {
            state.monthlyChartDataError = error;
          },
    },

    actions: {
        // subscription chart
        getChartsUserProducts: ({ commit }) => {
            commit("setChartsDataLoading", true)
            get('/chart/user-products').then(({ data }) => {
                commit("setChartsDataObject", data)
                commit("setChartsErrorMessage", null)
            }).catch((err) => {
                commit("setChartsDataObject", null)
                commit("setChartsErrorMessage", "Error Message")
                console.log(err, "err")
            }).finally(() => {
                setTimeout(() => {
                    commit("setChartsDataLoading", false)
                }, 1000);
            })
        },
        /// ChatGPT chart actions
        async fetchChatGptUsage({ commit }, dateRange) {
            commit("SET_CHAT_GPT_LOADING", true);
            try {
              const response = await get("/credits/usage/chat-gpt", { params: dateRange });
              commit("SET_CHAT_GPT_USAGE", response.data);
              commit("SET_CHAT_GPT_ERROR", null);
              console.log("Chatgpt", response.data)
            } catch (error) {
              console.error("Error fetching ChatGPT usage:", error);
              commit("SET_CHAT_GPT_USAGE", null);
              commit("SET_CHAT_GPT_ERROR", "Error fetching ChatGPT usage");
            } finally {
              commit("SET_CHAT_GPT_LOADING", false);
            }
          },
          async fetchDeepGramUsage({ commit }, dateRange) {
            commit("SET_DEEP_GRAM_LOADING", true);
            try {
              const response = await get("/credits/usage/deep-gram", { params: dateRange });
              commit("SET_DEEP_GRAM_USAGE", response.data);
              commit("SET_DEEP_GRAM_ERROR", null);
              console.log("Deepgram", response.data);
            } catch (error) {
              console.error("Error fetching DeepGram usage:", error);
              commit("SET_DEEP_GRAM_USAGE", null);
              commit("SET_DEEP_GRAM_ERROR", "Error fetching DeepGram usage");
            } finally {
              commit("SET_DEEP_GRAM_LOADING", false);
            }
          },
          async fetchMonthlyChartData({ commit }, { fromYear, toYear, fromMonth, toMonth }) {
            commit("SET_MONTHLY_CHART_DATA_LOADING", true);
            try {
              const response = await get("/chart/monthly", { params: { fromYear, toYear, fromMonth, toMonth } });
              commit("SET_MONTHLY_CHART_DATA", response.data);
              commit("SET_MONTHLY_CHART_DATA_ERROR", null);
            } catch (error) {
              console.error("Error fetching monthly chart data:", error);
              commit("SET_MONTHLY_CHART_DATA", null);
              commit("SET_MONTHLY_CHART_DATA_ERROR", "Error fetching monthly chart data");
            } finally {
              commit("SET_MONTHLY_CHART_DATA_LOADING", false);
            }
          },
    }
};

export default charts;