<template>
  <div
    class="modal fade modal-lg"
    id="editSubUserModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="editSubUserModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editSubUserModalLabel">Edit user</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group modal-parent" v-if="userData.parentData">
            <div>
              <UtilsIcon
                @click="emits('loginUserPage', userData.parentData.id)"
                data-bs-dismiss="modal"
                class="dropdownIcon"
                name="profile"
                width="23"
                height="23"
                color="#333333"
                id="profile"
              ></UtilsIcon>
            </div>
            <div>
              {{
                `${userData.parentData.firstName} ${userData.parentData.lastName}`
              }}
            </div>
            <div class="modal-parent-div">(Parent User)</div>
          </div>
          <div class="row">
            <div class="col-md-6">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input
              v-model="userData.firstName"
              type="text"
              class="form-control"
              id="firstName"
              placeholder="Joe"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input
              v-model="userData.lastName"
              type="text"
              class="form-control"
              id="lastName"
              placeholder="Doe"
            />
          </div>
        </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
            <label for="userEmail">Email Address</label>
            <input
              v-model="userData.email"
              type="email"
              class="form-control"
              id="userEmail"
              placeholder="example@gmail.com"
              @input="validateEmail(userData.email)"
            />
            <div v-if="emailError" class="text-danger">{{ emailError }}</div>
          </div>
          </div>
        </div>
          <form
            v-if="userData.role == 'SubUser'"
            :class="
              userData.status === 'ACCEPTED'
                ? 'was-validated'
                : userData.status === 'DEACTIVATED'
                ? 'was-invalidated'
                : 'was-warning'
            "
          >
            <label for="licenseStatus">Profile Status</label>
            <select
              required
              name="licenseStatus"
              id="licenseStatus"
              class="form-select"
              aria-label="Default select example"
              @change="(e) => onChange(e, 'main')"
            >
              <option :value="userData.status" selected hidden>
                {{ userData.status === "ACCEPTED" ? "Activate" : "Deactivate" }}
              </option>
              <option value="ACCEPTED">Activate</option>
              <option value="DEACTIVATED">Deactivate</option>
            </select>
          </form>
          <div class="form-group" v-if="userData.role == 'User'">
            <form
              :class="
                userData.subscription.status === 'ACCEPTED'
                  ? 'was-validated'
                  : userData.subscription.status === 'DEACTIVATED'
                  ? 'was-invalidated'
                  : 'was-warning'
              "
            >
              <label for="licenseStatus">License Key</label>
              <select
                required
                name="licenseStatus"
                id="licenseStatus"
                class="form-select"
                aria-label="Default select example"
                @change="(e) => onChange(e, 'main')"
              >
                <option :value="userData.subscription.status" selected hidden>
                  {{
                    userData.subscription.status=== "ACCEPTED" ? "Activate" : "Deactivate"
                  }}
                </option>
                <option value="ACCEPTED">Activate</option>
                <option value="DEACTIVATED">Deactivate</option>
              </select>
            </form>
          </div>
          <div class="row" v-if="userData.role === 'User'">
          <div class="col-md-6">
            <div class="form-group" >
              <label for="subscriptionStatus">Current Subscription:</label>
              <div class="subscription-name">{{ currentSubscription }}</div>
            </div>
          </div>
          
        <div class="col-md-6" v-if="showUpgradeOptions">
          <label>Upgrade To:</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="subscriptionChange"
              id="creativeClub"
              value="CREATIVE CLUB"
              v-model="selectedSubscription"
            />
            <label class="form-check-label mt-0 subscription-name" for="creativeClub">Creative Club</label>
          </div>
        </div>
        <div class="col-md-6" v-if="showDowngradeOptions">
          <label>Downgrade To:</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="subscriptionChange"
              id="professionalLicense"
              value="PROFESSIONAL LICENSE"
              v-model="selectedSubscription"
            />
            <label class="form-check-label mt-0 subscription-name" for="professionalLicense">Professional License</label>
          </div>
        </div>
        </div>
        <div class="row" v-if="userData.role === 'User'">
      <div class="col-md-4">
        <div class="form-group">
          <label>Remaining Audio Credits:</label>
          <div>{{ userCredits.credits?.audioText }} seconds</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Manually Added Audio Credits:</label>
          <div>{{ manuallyAddedAudioText }} seconds</div>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <label for="extraAudioText">Add Extra Audio Credits:</label>
          <input
            v-model="extraAudioText"
            type="number"
            class="form-control"
            id="extraAudioText"
          />
        </div>
      </div>
    </div>
          <div class="row" v-if="userData.role === 'User'">
      <div class="col-md-4">
        <label>Remaining Scrypt Credits:</label>
        <div>{{ userCredits.credits?.scryptGenerator }} words</div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Manually Added Scrypt Credits:</label>
          <div>{{ manuallyAddedScryptGenerator }} words</div>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <label for="extraScryptGenerator">Add Extra Scrypt Credits:</label>
          <input
            v-model="extraScryptGenerator"
            type="number"
            class="form-control"
            id="extraScryptGenerator"
          />
        </div>
      </div>
          </div>
          <div class="row">
            <div class="col-md-6">
          <div class="form-group" v-if="userData.role === 'User'">
            <label for="newPassword">New Password</label>
            <input v-model="newPassword" @input="validatePassword(newPassword)" type="password" class="form-control" id="newPassword" placeholder="New Password" />
            <small style="font-size: 11px; color: #888888;">Minimum 10 characters. A special character is required.</small>
          </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" v-if="userData.role === 'User'">
            <label for="confirmPassword">Confirm Password</label>
            <input v-model="confirmPassword" type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password" />

            </div>
        </div>
        <div v-if="passwordError" class="text-danger">{{ passwordError }}</div>
        </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline cancelButton"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn createButton"
            data-bs-dismiss="modal"
            @click="confirmButton"
             :disabled="isProcessing || !!emailError || !!passwordError"
          >
          {{ isProcessing ? 'Processing...' : 'Confirm Changes' }}

          </button>
        </div>
        <div class="col-md-12 mt-3" v-if="subscriptionMessage">
          <div :class="{'alert': true, 'alert-success': !store.getters.getSubscriptionUpgradeFailure, 'alert-danger': store.getters.getSubscriptionUpgradeFailure}">
            {{ subscriptionMessage }}
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script setup>
import { defineProps, ref, watch, defineEmits, computed, onMounted, onBeforeUnmount } from "vue";
import UtilsIcon from "@/components/utils/UtilsIcon.vue";
import { useStore } from "vuex";
import saasProductService from "@/services/saasProductService";

const store = useStore();
const props = defineProps({
  data: Object,
  userType: String,
});
const emits = defineEmits(["editUserStatus", "loginUserPage"]);

const userData = ref({});
const newPassword = ref('');
const confirmPassword = ref('');
const licenseKeyStatus = ref("");
const userCredits = ref({});
const extraAudioText = ref(0);
const extraScryptGenerator = ref(0);
const selectedSubscription = ref("");
const subscriptionMessage = ref('');
const isProcessing = ref(false);
const currentProduct = ref(null);


const emailError = ref("");
const passwordError = ref("");

// Validation functions
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if(!email) {
    emailError.value = "Email is required";
    return false
  }
  if(!re.test(email)) {
    emailError.value = "Please enter a valid Email Address";
    return false
  }
  emailError.value = "";
  return true
}

const validatePassword = (password) => {
  if (!password) {
    passwordError.value = '';
    return true; 
  }

  if (password.length < 10) {
    passwordError.value = "Password must be at least 10 characters long.";
    return false;
  } 

  if (!/\d/.test(password)) {
    passwordError.value = 'Password must contain at least one number';
    return false;
  }

  if (!/[A-Z]/.test(password)) {
    passwordError.value = 'Password must contain at least one capital letter';
    return false;
  }

  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    passwordError.value = 'Password must contain at least one special character';
    return false;
  }

  passwordError.value = '';
  return true;
};

const getUserCredits = (userId) => store.getters.getUserCredits(userId);

const manuallyAddedAudioText = computed(() => {
  const manualCredits = userCredits.value?.extraCredit?.manuallyAddedExtraCredit;
  if (Array.isArray(manualCredits)) {
    return manualCredits.reduce((total, credit) => total + (credit.audioText || 0), 0);
  }
  return 0;
});

const manuallyAddedScryptGenerator = computed(() => {
  const manualCredits = userCredits.value?.extraCredit?.manuallyAddedExtraCredit;
  if (Array.isArray(manualCredits)) {
    return manualCredits.reduce((total, credit) => total + (credit.scryptGenerator || 0), 0);
  }
  return 0;
});

function fetchUserCredits(userId) {
  store.dispatch('getUserCredits', userId).then(() => {
    const credits = getUserCredits(userId);
    if (credits) {
      userCredits.value = credits;
    }
  });
}


function onChange(e, type) {
  if (type === "main") {
    userData.value.status = e.target.value;
  } else if (type === "club") {
    userData.value.clubStatus = e.target.value === "true";
  } else if (type === "mainUser") {
    userData.value.status = licenseKeyStatus.value;
  }
}

const handleUpgrade = async () => {
  console.log('Starting upgrade process...');
  try {
    const ccProductsResponse = await saasProductService.getProductsCC('monthly');
    if (ccProductsResponse.length === 0) {
      throw new Error("No Creative Club products available for upgrade.");
    }
    
    const ccProductId = ccProductsResponse[0].id;
    const payload = {
      userId: userData.value.id,
      ccProductId: ccProductId
    };

    const result = await store.dispatch('subscriptions/upgradeSubscriptionToCreativeClub', payload);

    if (result && result.success) {
      subscriptionMessage.value = "Upgrade successful! User is now a Creative Club member.";
      await fetchCurrentProduct(userData.value.id);
    } else {
      throw new Error(result && result.message ? result.message : "Upgrade failed for unknown reason");
    }
  } catch (upgradeError) {
    console.error('Upgrade error:', upgradeError);
    subscriptionMessage.value = `Upgrade failed: ${upgradeError.message || "An unexpected error occurred"}`;
  }
};

const handleDowngrade = async () => {
  console.log('Starting downgrade process...');
  try {
    const result = await store.dispatch('subscriptions/downgradeSubscriptionFromCreativeClub', userData.value.id);

    if (result && result.success) {
      subscriptionMessage.value = "Downgrade successful! User is no longer a Creative Club member.";
      await fetchCurrentProduct(userData.value.id);
    } else {
      throw new Error(result && result.message ? result.message : "Downgrade failed for unknown reason");
    }
  } catch (downgradeError) {
    console.error('Downgrade error:', downgradeError);
    subscriptionMessage.value = downgradeError.message || "An unexpected error occurred during the downgrade process.";
  }
};

const confirmButton = async () => {
  isProcessing.value = true;
  subscriptionMessage.value = "";
  emailError.value = "";
  
  // Validate email
  if (!validateEmail(userData.value.email)) {
    isProcessing.value = false;
    return;
  }

  // Validate password only if it's being changed
  if (newPassword.value && !validatePassword(newPassword.value)) {
    isProcessing.value = false;
    return;
  }

  try {
    if (userData.value.role === 'User') {
      // Handle password reset
      if (newPassword.value && confirmPassword.value) {
        console.log('Attempting to reset password...');
        const passwordResetResult = await store.dispatch('resetUserPassword', {
          userId: userData.value.id,
          newPassword: newPassword.value,
          confirmPassword: confirmPassword.value
        });
        console.log('Password reset result:', passwordResetResult);
        newPassword.value = '';
        confirmPassword.value = '';
      }

      // Handle subscription changes if needed
      if (selectedSubscription.value === "CREATIVE CLUB" && showUpgradeOptions.value) {
        await handleUpgrade();
      } else if (selectedSubscription.value === "PROFESSIONAL LICENSE" && showDowngradeOptions.value) {
        await handleDowngrade();
      }

      // Handle adding extra credits
      if (extraAudioText.value > 0 || extraScryptGenerator.value > 0) {
        await store.dispatch('addExtraUserCredits', {
          userId: userData.value.id,
          audioText: extraAudioText.value,
          scryptGenerator: extraScryptGenerator.value
        });
        // Reset the input fields after successful update
        extraAudioText.value = 0;
        extraScryptGenerator.value = 0;
      }
    }

    await store.dispatch('changeUserFullNameWithAdmin', {
      firstName: userData.value.firstName,
      lastName: userData.value.lastName,
      id: userData.value.id,
      email: userData.value.email,
      status: userData.value.status
    });

    emits('editUserStatus', userData.value);
    // Set a success message
    subscriptionMessage.value = "Changes saved successfully!";
    
    // Clear the success message after 3 seconds
    setTimeout(() => {
      subscriptionMessage.value = "";
    }, 3000);
  } catch (error) {
    if (error.response && error.response.status === 422 && error.response.data.message === "Provided email is already taken") {
      emailError.value = "Email is already taken";
    } else {
      subscriptionMessage.value = error.message || "An error occurred during the process.";
    }
  } finally {
    isProcessing.value = false;
  }
};

const fetchCurrentProduct = async (userId) => {
  try {
    currentProduct.value = await saasProductService.getUserProduct(userId);
    if (!currentProduct.value) {
      console.log('No current product found for user');
    }
  } catch (error) {
    console.error('Error fetching current product:', error);
  }
};

// const updateUserExtraCredits = (userId, extraCredits) => {
//   store.dispatch('updateUserExtraCredits', { userId, extraCredits });
// };

const licenseId = computed(() => store.getters.getSubUserLicense);



const resetForm = () => {
  newPassword.value = '';
  confirmPassword.value = '';
  extraAudioText.value = 0;
  extraScryptGenerator.value = 0;
  selectedSubscription.value = "";
  subscriptionMessage.value = "";
  emailError.value = '';
  passwordError.value = '';
};

const initializeForm = (data) => {
  if (data) {
    userData.value = { ...data };
    validateEmail(userData.value.email);
  }
  resetForm();
};

// Function to handle modal opening
const handleModalOpen = () => {
  initializeForm(props.data);
};

// Function to handle modal closing
const handleModalClose = () => {
  resetForm();
};

onMounted(() => {
  const modalElement = document.getElementById('editSubUserModal');
  if (modalElement) {
    modalElement.addEventListener('show.bs.modal', handleModalOpen);
    modalElement.addEventListener('hidden.bs.modal', handleModalClose);
  }
});

onBeforeUnmount(() => {
  const modalElement = document.getElementById('editSubUserModal');
  if (modalElement) {
    modalElement.removeEventListener('show.bs.modal', handleModalOpen);
    modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
  }
});

watch(
  () => props.data,
  async () => {
    userData.value = props.data;
    selectedSubscription.value = userData.value?.subscription?.product?.productName || "";
    licenseKeyStatus.value = userData.value.status;
    if (props.userType === "Admin") {
      await getSubUserLicense(userData.value.id);
      store.dispatch("getLicenseKeyStatus", { id: licenseId.value });
      fetchUserCredits(userData.value.id);
    }
    await fetchCurrentProduct(userData.value.id);
  },
  () => {
    userData.value = { ...props.data };
  },
  { deep: true }
);

function getSubUserLicense(id) {
  return new Promise((resolve) => {
    store.dispatch("getSubUserLicense", { id, resolve });
  });
}

const currentSubscription = computed(() => 
  userData.value?.subscription?.product?.productName || ""
);

const showUpgradeOptions = computed(() => {
  return currentSubscription.value === "Professional License";
});

const showDowngradeOptions = computed(() => {
  return ["All Access Plan Monthly (includes Creative Club)", "Creative Club (Yearly)"].includes(currentSubscription.value);
});

watch(() => userData.value.email, (newValue) => {
  validateEmail(newValue);
});

watch(newPassword, (newValue) => {
  validatePassword(newValue);
});

watch(confirmPassword, (newValue) => {
  if (newPassword.value && newValue && newPassword.value !== newValue) {
    passwordError.value = 'Passwords do not match';
  } else {
    passwordError.value = '';
  }
});

</script>




<style lang="scss" scoped>

@font-face {
  font-family: "Nunito Medium";
  src: url("./../../assets/fonts/Nunito-Medium.woff") format("woff"),
    url("./../../assets/fonts/Nunito-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Nunito-Medium', 'sans-serif' !important;
}

.modal-content {
  width: 100%;
  padding: 24px 36px;
  background: #fff;
}
.modal-header {
  padding: 0 0 15px 0;
}
.modal-title {
  font-size: 20px !important;
  font-weight: 400;
  color: #a3adcd;
}
.modal-body {
  padding: 0;
}
label {
  justify-content: flex-start;
  display: flex;
  font-size: 14px;
  color:#959595;
  margin: 20px 0 8px;
}
.cancelButton {
  background-color: #ebedf3 !important;
  color: #333333;
}
.createButton:hover {
  background-color: #69c900 !important;
  color: #ffffff !important;
}
.createButton {
  color: #69c900 !important;
  background-color: #fff !important;
  border: 1px solid #69c900;
}
.modal-footer {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4%;
  width: 100%;
  margin: 28px 0 0 0;
  padding: 0;
  button {
    margin: 0;
    padding: 6px 0;
    width: 48%;
  }
}
.circle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background-color: #03d670b0 !important;
}
.modal-parent {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  &-div {
    color: #959595 !important;
  }
}
.subscription-name {
  font-weight: 600;
  color: #333333;
  font-size: medium;
}
.form-select.is-valid,
.was-invalidated .form-select:valid {
  border-color: red;
}
.was-invalidated .form-select:valid:not([multiple]):not([size]),
.was-invalidated.form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-valid,
.was-warning .form-select:valid {
  border-color: orange;
}
.was-warning .form-select:valid:not([multiple]):not([size]),
.was-warning.form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
