<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="box">
            <div class="back">
              <div v-if="localStorageItem" @click="router.push('/contact')">
                <svg
                  width="28"
                  height="20"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27 10H1"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 19L1 10L10 1"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Back</span>
              </div>
            </div>
            <div class="login-acct">
              <div class="logo">
                <img src="../assets/_logo1.png" alt="supportive" />
              </div>
              <h3>Your license key has been deactivated !</h3>
              <div class="contact">
                You can contact our support team with any questions <br />
                regarding your account.
              </div>
              <div>
                <a href="#">support@easyvsl.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const localStorageItem = ref();
const router = useRouter();
onMounted(() => {
  if (localStorage.getItem("globalToken")) {
    localStorageItem.value = localStorage.getItem("globalToken");
  }
});
</script>
<style lang="scss" scoped>
h3 {
  text-align: center !important;
}
div {
  color: #353535;
  margin-top: 16px;
  a {
    color: gray;
    &:hover {
      color: blue;
    }
  }
}
.contact {
  margin-bottom: 36px;
}
.back {
  display: flex;
  align-items: flex-start;
  width: 60%;
  div {
    max-width: 150px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    span {
      color: var(--text-color, #353535);
      text-align: center;
      font-family: Biryani;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }
}
.box {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
</style>
