<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="box">
            <div class="login-acct">
              <div class="logo">
                <img src="../assets/_logo1.png" alt="supportive" />
              </div>
              <h3>Register to your EasyVSL account as sub-user</h3>
              <div v-if="errorMessage" class="errorMessage alert alert-danger">
                {{
                  typeof errorMessage == "string"
                    ? errorMessage
                    : errorMessage[0]
                }}
              </div>
              <div class="form">
                <input
                  id="firstName"
                  v-model="userData.firstName"
                  type="text"
                  class="form-control"
                  placeholder="First Name"
                  @focusin="oninput"
                />
                <input
                  id="lastName"
                  v-model="userData.lastName"
                  type="text"
                  class="form-control"
                  placeholder="Last Name"
                  @focusin="oninput"
                />
                <input
                  id="password"
                  v-model="userData.password"
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  @focusin="oninput"
                />
                <input
                  id="passwordConfirmation"
                  v-model="userData.passwordConfirmation"
                  type="password"
                  class="form-control"
                  placeholder="Confirm Password"
                  @focusin="oninput"
                />
                <button type="submit" class="submitButton" @click="submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const token = computed(() => {
  return router.currentRoute.value?.query?.token;
});
const errorMessage = computed(() => {
  return store.getters.getSubUserSignInError;
});
const successMessage = computed(() => {
  return store.getters.getSubUserSignInSuccess;
});
const userData = ref({
  token: token.value,
  firstName: "",
  lastName: "",
  password: "",
  passwordConfirmation: "",
});
async function submit() {
  await store.dispatch("signInSubUser", {
    token: token.value,
    firstName: userData.value.firstName.trim(),
    lastName: userData.value.lastName.trim(),
    password: userData.value.password.trim(),
    passwordConfirmation: userData.value.passwordConfirmation.trim(),
  });
}

function oninput() {
  if (errorMessage.value) {
    store.dispatch("hideErrorSubUserSignIn");
  }
}

watch(
  () => successMessage.value,
  () => {
    if (successMessage.value) {
      router.push("/login");
    }
  }
);

onMounted(() => {
  if (!token.value || localStorage.getItem("vsl-token")) {
    router.push("/login");
  }
});
</script>
<style lang="scss"></style>
