<template>
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            Create New Video
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="videoName">Video Name</label>
            <input
              type="text"
              class="form-control"
              id="videoName"
              placeholder="ie: My Video"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn cancelButton"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="button" class="btn successButton">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    top: 0;
  }
  .modal-content {
    width: 100% !important;
    padding: 18px 24px 36px 24px !important;
    border-radius: 6px !important;
    border-bottom: 1px solid #e9ecf4 !important;
  }
  .modal-header {
    color: #a3adcd !important;
    padding: 16px 4px;
  }
  .modal-body {
    padding: 16px 4px;
    text-align: left;
    label {
      margin-bottom: 12px;
    }
  }
  .modal-footer {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    gap: 12px;
    padding: 12px 0 !important;
    border: none;
    justify-content: flex-start;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 225px;
      height: 38px;
      border-radius: 6px;
      font-size: 13px;
    }
    .successButton {
      background: #69c900 !important;
      color: #ffffff;
    }
    .cancelButton {
      border: 1.5px solid #ebedf3 !important;
      color: #333333;
    }
  }
}
</style>