<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="box">
            <div class="login-acct">
              <div class="logo">
                <img src="../assets/_logo1.png" alt="supportive" />
              </div>
              <h3>Your account has been deactivated!</h3>
              <div class="contact">
                You can contact our support team with any questions <br />
                regarding your account.
              </div>
              <div>
                <a href="#">support@easyvsl.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>
<style lang="scss" scoped>
h3 {
  text-align: center !important;
}
div {
  color: #353535;
  margin-top: 16px;
  a {
    color: gray;
    &:hover {
      color: blue;
    }
  }
}
.contact {
  margin-bottom: 36px;
}
</style>
