<template>
  <section class="header">
    <nav class="navbar navbar-expand-lg navbar-light bg-light-blue">
      <div class="container-fluid">
        <a href="#" @click="router.push('/')" class="navbar-brand"
          ><img src="@/assets/logo-white.png" alt="supportive"
        /></a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="navOpen = !navOpen"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div
          id="navbarNavDarkDropdown"
          :class="{ collapse: navOpen }"
          class="navbar-collapse justify-content-end easyVsl-header"
        >
          <ul class="navbar-nav">
            <li class="nav-item active d-flex align-items-center">
              <a
                class="support"
                href="https://support.digitalkickstart.com"
                target="_blank"
                >Support</a
              >
            </li>
            <li></li>
            <li>
              <button
                type="button"
                class="btn"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div v-if="userData?.avatar">
                  <img :src="userData.avatar.url" :alt="userData.avatar.name" />
                </div>
                <div v-else>
                  <img class="img-fluid" src="@/assets/avatar.png" />
                </div>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <router-link to="/contact" exact class="d-flex">
                    <UtilsIcon
                      class="dropdownIcon"
                      name="profile"
                      width="23"
                      height="23"
                      color="#2A3F7A"
                      id="profile"
                    ></UtilsIcon>
                    <span>My Account</span>
                  </router-link>
                </li>

                <!-- <li>
                  <router-link to="/integrations" exact>
                    <UtilsIcon
                      class="dropdownIcon"
                      name="setting"
                      width="23"
                      height="23"
                      color="#2A3F7A"
                      id="setting"
                    ></UtilsIcon>
                    <span>Integrations</span>
                  </router-link>
                </li> -->

                <li>
                  <router-link to="/billing" exact>
                    <UtilsIcon
                      class="dropdownIcon"
                      name="creditCard"
                      width="23"
                      height="30"
                      color="#2A3F7A"
                      id="creditCard"
                    ></UtilsIcon>
                    <span>Billing / Plan</span>
                  </router-link>
                </li>
                <li v-if="showNavItem">
                  <router-link to="/users" exact>
                    <UtilsIcon
                      class="dropdownIcon"
                      name="users"
                      width="23"
                      height="25"
                      color="#2A3F7A"
                      id="users"
                    ></UtilsIcon>
                    <span>{{ role === 'Admin' ? 'Admin' : 'User Management' }}</span>
                  </router-link>
                </li>

                <!-- <li @click="logOutUserPage">
                  <router-link to="/contact" exact>
                    <UtilsIcon
                      class="dropdownIcon"
                      name="users"
                      width="23"
                      height="25"
                      color="#2A3F7A"
                      id="users"
                    ></UtilsIcon>
                    <span>Switch account</span>
                  </router-link>
                </li> -->
                <li class="bg-primary borderNone">
                  <router-link
                    to=""
                    @click="makeLogout"
                    class="d-flex justify-content-center"
                  >
                    <UtilsIcon
                      class="dropdownIcon"
                      name="logout"
                      width="23"
                      height="23"
                      color="#fff"
                    ></UtilsIcon>

                    <span class="textColorWhite">Log Out</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import UtilsIcon from "@/components/utils/UtilsIcon.vue";
import { computed } from "@vue/reactivity";
const store = useStore();
let navOpen = ref(true);
const router = useRouter();

const userData = computed(() => {
  return store.getters.getCurrentUser;
});

const subscription = computed(() => userData?.value?.subscription?.product?.productName || '');
const role = computed(() => userData.value.role);
const showNavItem = computed(() => {
  if (!userData.value) return false;
  return !(role.value === 'SubUser' || (role.value === 'User' && subscription.value === 'Professional License'));
});


function makeLogout() {
  store.dispatch("logOut");
  router.push("/login");
}

// function logOutUserPage() {
//   store.dispatch("logoutUserPage");
// }

onMounted(() => {
  store.dispatch("updateCurrentUser");
});

// Immediate log of initial userData
console.log('Initial userData:', userData.value);
</script>
<style lang="scss">
.btn-group {
  margin-left: 27px;
  color: #0d6efd !important;
  border: #0d6efd !important;
  &:active {
    color: #0d6efd !important;
    border: #0d6efd !important;
  }
}
.nav-item {
  height: 79px !important;
}

.dropdown-menu.show {
  height: auto !important;
  padding: 0;
  border-radius: 0 !important;
  left: -297px !important;
  top: 108%;
  z-index: 5000;

  li {
    display: flex;
    width: 250px;
    background: #ffffff;
    a {
      width: 250px;
      gap: 16px;
      padding: 23px 10px;
      font-family: Montserrat Regular;
      font-size: 16px;
      line-height: 29px;
      color: #2a3f7a !important;
      text-decoration: none;
      display: -webkit-box;
      display: -ms-flexbox;
      justify-content: flex-start !important;
      display: flex;
      align-items: center;
      &:hover {
        background: #0d6efd;
        color: #ffffff !important;
        #profile,
        #setting,
        #creditCard,
        #users {
          cursor: pointer;
          filter: brightness(0) saturate(100%) invert(99%) sepia(0%)
            saturate(7498%) hue-rotate(168deg) brightness(114%) contrast(100%);
        }
      }
    }
    img {
      width: 23px !important;
      height: 23px !important;
      margin-right: 16px !important;
      -o-object-fit: contain !important;
      object-fit: contain !important;
    }
  }
}
.navbar.navbar-expand-md.navbar-dark.bg-light {
  padding: 0 !important;
  height: 79px;
  color: black;
  .navbar-brand.abs {
    background: #2b62ff !important;
    height: 100% !important;
    margin-right: 30px;
  }
  .container-fluid {
    padding: 0 !important;
    height: 100% !important;
    background: white !important;
  }
  .navbar-collapse.collapse,
  ul {
    height: 79px !important;
    .nav-item {
      display: flex;
      align-items: center;

      .nav-link {
        padding: 0 15px !important;
        display: flex !important;
        flex-direction: column !important;
        gap: 0.25rem !important;
        div {
          justify-content: center;
        }
      }
    }
    .nav-item.active {
      border-bottom: 2.5px solid #2b62ff;
    }
  }
}
.solid {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  hr.line {
    color: #2b62ff !important;
    width: 89px;
    margin: 0 !important;
  }
}
.logOutbtn {
  // width: 500px !important;
  /* delete */
  a {
    padding: 18px 0 18px 16px !important;
    display: flex;
    flex-direction: row;
    gap: 17px !important;
    justify-content: flex-start;
    margin: 0;
  }
}
.btn.show,
.btn.show:active,
.btn.show:focus {
  border: none !important;
}
.btn.navbar-menu-button {
  margin: 0 18px;
}
.navbar-menu-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  border: none !important;
}
.notActiveText {
  color: #cacfdf !important;
}
.activeText {
  color: #333333 !important;
}

.dropdownIcon {
  padding: 0;
}
.borderNone {
  border: none !important;
}
.textColorWhite {
  color: #ffffff;
}
</style>
