<template>
    <section class="login">
      <div class="container" v-if="!signInLoading">
        <div class="row">
          <div class="col-sm-12">
            <div class="box">
              <div class="login-acct">
                <div class="logo">
                  <img
                    src="../assets/_logo1.png"
                    alt="supportive"
                  >
                </div>
                <h3>Login to your EasyVSL account</h3>
                <p v-if="errors?.length">
                  <ul>
                    <li style="list-style-type: none;"
                      v-for="error in errors"
                      :key="error"
                    ><span class="error">{{ error }}</span></li>
                  </ul>
                </p>
                <div class="form" @keydown.enter="makeLogin">
                  <input
                    id="exampleInputEmail1"
                    v-model="email"
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Email Address"
                  >
                
                  <input
                    id="exampleInputEmail2"
                    v-model="password"
                    type="password"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Password"
                  >
                  <button
                    type="submit"
                    class="submitButton"
                    @click.prevent="makeLogin"
                  >
                    Login
                  </button>
                </div>
  
                <div class="account  text-start">
                  <div class="bottom-part">
                    <h6>Don't have an account?</h6>
                    <router-link
                      to="/register"
                      exact
                    >Create an account</router-link>
                  </div>
                  <div class="bottom-right">
                    <router-link 
                      to="/reset-password"
                      exact
                    >Forgot Password?</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <LoginLoader></LoginLoader>
      </div>
    </section>
    
  </template>
  <script setup>
import LoginLoader from "@/components/utils/LoginLoader.vue";
import { ref, computed, watch, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const router = useRouter();
const emits = defineEmits(["emits"]);
const store = useStore();
let errors = ref([]);
const signInLoading = computed(() => store.getters.getSignInLoading);
const signInError = computed(() => store.getters.getSignInError);
const email = ref("");
const password = ref("");
async function makeLogin() {
  errors.value = [];
  if (!email.value) {
    errors.value.push("Email is required");
  }
  if (!password.value) {
    errors.value.push("Password is required");
  }

  if (email.value && password.value) {
    emits("login", { email, password });
    // store.dispatch("signIn", {
    //   email: email.value,
    //   password: password.value,
    // });
  }
}

watch(
  () => signInLoading.value,
  () => {
    if (signInError.value) {
      errors.value = [];
      errors.value.push(store.getters.getSignInError);
    } else {
      router.push("/dashboard");
    }
  }
);
</script>