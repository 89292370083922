<template>
  <div v-if="deepGramDataLoading">Loading...</div>
  <div v-else-if="deepGramData.length && deepGramMonths.length">
    <apexchart :options="chartOptions" :series="deepGramData" />
  </div>
 
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import Apexchart from "vue3-apexcharts";

const store = useStore();

onMounted(() => {
  store.dispatch('fetchDeepGramUsage');
});

const deepGramData = computed(() => store.getters.getDeepGramData);
const deepGramMonths = computed(() => store.getters.getDeepGramMonth);
const deepGramDataLoading = computed(() => store.state.deepGramDataLoading);

const chartOptions = computed(() => ({
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    background: "#F7F8FC",
  },
  dataLabels: {
    formatter: () => "",
  },
  fill: {
    opacity: 1,
  },
  plotOptions: {
    bar: {
      hideOverflowingLabels: true,
      horizontal: false,
      columnWidth: "23%",
      barHeight: "30%",
      borderRadius: 6,
      borderRadiusWhenStacked: "last",
    },
  },
  xaxis: {
    categories: deepGramMonths.value,
  },
  colors: ["#2A61FF", "#03d670"],
  yaxis: {
    labels: {
      formatter: (val) => {
        if (val == 0) return '0';
        if (val >= 1000000) return (val / 1000000).toFixed(1) + 'M hours';
        if (val >= 1000) return (val / 1000).toFixed(1) + 'K hours';
        return val + ' hours';
      },
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    markers: {
      radius: 12,
    },
  },
}));
</script>