<template>
  <table class="table">
    <thead>
      <tr>
        <th
          scope="col"
          v-for="title in titles"
          :key="title"
          class="table__title"
        >
          {{ title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in horizontalTitles" :key="row.title">
        <th
          scope="row"
          :class="index == horizontalTitles.length - 1 ? 'last' : ''"
        >
          {{ row.title }}
        </th>
        <td :class="index == horizontalTitles.length - 1 ? 'last' : ''">
          {{ `$ ${row.pricing} / ${row.type} ` }}
        </td>
        <td :class="index == horizontalTitles.length - 1 ? 'last' : ''">
          {{ row.creditAmount }}
        </td>
        <td :class="index == horizontalTitles.length - 1 ? 'last' : ''">
          {{ row.data }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
const titles = ["Name", "Pricing", "Credit Amount", "Date"];
const horizontalTitles = [
  {
    title: "Creative Club",
    pricing: "297",
    type: "yearly",
    creditAmount: "Unlimited",
    data: "13 Sep, 2023",
  },
  {
    title: "Add-on Credits",
    pricing: "2.5",
    type: "one-time",
    creditAmount: "10 hours",
    data: "10 May, 2023",
  },
  {
    title: "Creative Club",
    pricing: "29",
    type: "monthly",
    creditAmount: "Unlimited",
    data: "24 Feb, 2023",
  },
  {
    title: "Auto-Refill Credits",
    pricing: "2.00",
    type: "rebill",
    creditAmount: "75000 words",
    data: "05 Nov, 2022",
  },
  {
    title: "PressPlay",
    pricing: "24",
    type: "month",
    creditAmount: "20.00 hours / 20,000 words",
    data: "18 Oct, 2022",
  },
];
</script>
<style lang="scss" scoped>
.table {
  margin-top: 25px;
  tbody {
    .last {
      border-bottom: none !important;
    }
  }
  td,
  th {
    padding: 20px 8px !important;
  }
  td {
    color: #005cbe;
    word-wrap: break-word;
    max-width: 250px;
  }
  th {
    color: #080e54;
  }
  &__title {
    color: #333333 !important;
  }
}
</style>
