<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="box">
            <div class="login-acct">
              <div class="logo">
                <img src="../assets/_logo1.png" alt="supportive" />
              </div>
              <div v-if="!resetForgotPasswordMessage?.success">
                <h3>Recover Password</h3>
                <div
                  v-if="resetForgotPasswordMessage.failure"
                  class="errorMessage alert alert-danger"
                >
                  {{ resetForgotPasswordMessage.failure }}
                </div>
                <div class="form">
                  <input
                    id="pass"
                    v-model="password"
                    type="password"
                    class="form-control"
                    placeholder="New Password"
                    @focusin="oninput"
                  />
                  <button type="submit" class="submitButton" @click="submit">
                    Confirm
                  </button>
                </div>
              </div>
              <div v-else class="successBox">
                <h3>Recover Password</h3>
                <div class="alert alert-success successBox__message">
                  <UtilsIcon
                    name="checkSuccess"
                    width="16"
                    height="16"
                  ></UtilsIcon>
                  You have successfully changed your password !
                </div>
                <p>
                  Please login into your account by clicking on the link below.
                </p>
                <a href="/login">Login here</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import UtilsIcon from "@/components/utils/UtilsIcon.vue";

import { computed } from "@vue/reactivity";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const password = ref();
const token = ref();

const resetForgotPasswordMessage = computed(() => {
  return store.getters.getResetForgotPasswordMessage;
});
function submit() {
  store.dispatch("resetForgotPassword", {
    token: token.value,
    password: password.value,
  });
}

onMounted(() => {
  if (localStorage.getItem("vsl-token")) {
    router.replace("/login");
  } else {
    if (router?.currentRoute.value?.query?.token) {
      token.value = router.currentRoute.value.query.token;
    } else {
      router.replace("/login");
    }
  }
});
</script>
<style lang="scss" scoped>
.successBox {
  h3 {
    margin-top: 52px;
  }
  p {
    font-size: 22px !important;
    color: #353535;
  }
  div {
    margin: 28px 0;
  }
  font-size: 16px !important;
}
.successBox {
  &__message {
    justify-content: center !important;
    align-items: center !important;
    height: 50px !important;
    display: flex !important;
    gap: 20px !important;
  }
}
</style>
