<template>
  <div
    ref="cancelSubscriptionModal"
    class="modal fade modal-lg"
    id="CancelSubscription"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="CancelSubscriptionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="CancelSubscriptionLabel">
            Cancel Subscription
          </h1>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="!responseMessage">
            <p>Are you sure you want to cancel your subscription?</p>
            <p>This action cannot be undone. Do you wish to proceed?</p>
          </div>
          <div v-else :class="['response-message', responseMessage.success ? 'success' : 'error']">
            <p><strong>{{ responseMessage.message }}</strong></p>
            <div v-if="responseMessage.error">
              <p>Error details:</p>
              <pre>{{ JSON.stringify(responseMessage.error, null, 2) }}</pre>
            </div>
          </div>
        </div>
        <div class="modal-footer w-100">
          <div class="d-flex align-items-center gap-3 w-100">
            <button
              v-if="!responseMessage"
              style="width: 48%;"
              type="button"
              class="btn closeButton d-flex gap-2 align-items-center"
              @click="closeModal"
            >
              <span>Keep Subscription</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-circle-x"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="m15 9-6 6" />
                <path d="m9 9 6 6" />
              </svg>
            </button>
        
            <button
              v-if="!responseMessage"
              type="button"
              style="width: 48%;"
              class="btn cancelSubButton d-flex gap-2 align-items-center"
              @click="confirmCancelSubscription"
              :disabled="isLoading"
            >
              <span>{{ isLoading ? 'Canceling...' : 'Confirm Cancellation' }}</span>
              <svg
                v-if="!isLoading"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-circle-check"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="m9 12 2 2 4-4" />
              </svg>
              <div v-else class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <button
              v-else
              type="button"
              style="width: 100%;"
              class="btn closeButton d-flex gap-2 align-items-center justify-content-center"
              @click="closeModal"
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineExpose, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';

const store = useStore();
const cancelSubscriptionModal = ref(null);
const responseMessage = ref(null);
const isLoading = ref(false);

const emit = defineEmits(['subscriptionCanceled']);
const openModal = () => {
  responseMessage.value = null;
  const modal = new Modal(cancelSubscriptionModal.value);
  modal.show();
};

const closeModal = () => {
  const modal = Modal.getInstance(cancelSubscriptionModal.value);
  modal.hide();
};

const confirmCancelSubscription = async () => {
  isLoading.value = true;
  try {
    const result = await store.dispatch('subscriptions/cancelSubscription');
    responseMessage.value = {
      success: true,
      message: result.message
    };
    emit('subscriptionCanceled');
  } catch (error) {
    responseMessage.value = {
      success: false,
      message: error.message,
      error: {
        statusCode: error.statusCode,
        message: error.message,
        error: error.error
      }
    };
  } finally {
    isLoading.value = false;
  }
};

defineExpose({
  openModal
});
</script>
  
  <style scoped>

.response-message {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.response-message.success {
  background-color: #fff;
  color: #155724;
}

.response-message.error {
  background-color: #f8d7da;
  color: #721c24;
}
 .closeButton {
    border-radius: 10px;
    background-color: #ffffff;
    color: #383838;
    font-size: 14px;
    border: 0.7px solid #383838;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.cancelSubButton {
    border-radius: 10px;
    background-color: #ff0000;
    color: #fff;
    font-size: 14px;
    border: none;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.closeButton:hover {
    border-radius: 10px;
    background-color: #ffffff;
    color: #383838;
    font-size: 14px;
    border: 0.7px solid #383838;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.cancelSubButton:hover {
    border-radius: 10px;
    background-color: #ff0000;
    color: #fff;
    font-size: 14px;
    border: none;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}


  </style>
  