<template>
    <div class="modal fade" id="versionUpdateModal" tabindex="-1" aria-labelledby="versionUpdateModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="versionUpdateModalLabel">Update Version</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="versionInput" class="form-label">New Version Number</label>
              <input type="text" class="form-control" id="versionInput" v-model="newVersion" placeholder="e.g., 1.0.1">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="version-update-btn" @click="confirmUpdate">Update</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import { Modal } from 'bootstrap';
  
  const store = useStore();
  const newVersion = ref('');
  
  const confirmUpdate = async () => {
    if (newVersion.value) {
      await store.dispatch('updateAppVersions', newVersion.value);
      newVersion.value = '';
      const modalElement = document.getElementById('versionUpdateModal');
      const modal = Modal.getInstance(modalElement);
      modal.hide();
    }
  };
  </script>

  <style>
.version-update-btn {
  padding: 8px 12px;
  border-radius: 12px;
  font-family: Nunito Medium;
  font-weight: 600;
  background-color: #1d2031;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease ;
}
</style>