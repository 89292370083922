<template>
  <div
    class="modal fade"
    id="staticBackdrop3"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel3"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel3">
            {{ modalData.type == "activate" ? "Activation" : "Deactivation" }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Are you sure you want to {{ modalData.type }} this license key?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline cancelButton"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn"
            :class="
              modalData.type == 'activate'
                ? 'generateButton'
                : 'deactivateButton'
            "
            data-bs-dismiss="modal"
            @click="generate"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
const emits = defineEmits(["deactivate", "activate"]);
const props = defineProps({
  modalData: Object,
});

function generate() {
  if (props.modalData.type == "activate") {
    emits("activate", props.modalData.data);
  } else {
    emits("deactivate", props.modalData.data);
  }
}
</script>

<style lang="scss" scoped>
.modal-dialog {
  top: 150px;
  background-color: #ffffff;
  width: 536px !important;
  border-radius: 6px;
}
.modal-content {
  width: 100%;
  height: 100%;
}
.modal-header {
  padding: 24px 36px 14px 36px;
  color: #a3adcd;
  .modal-title {
    font-size: 20px;
    font-weight: 400;
  }
  button {
    color: #cacfdf;
  }
}
.modal-body {
  padding: 30px 80px;
  color: #333333;
  font-size: 18px;
}
.modal-footer {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4%;
  width: 100%;
  button {
    width: 46%;
  }
  .cancelButton {
    background-color: #ebedf3 !important;
    color: #333333;
  }
  .generateButton:hover {
    background-color: #69c900 !important;
    color: #ffffff !important;
  }
  .generateButton {
    background-color: #fff !important;
    color: #69c900 !important;
    border: 1px solid #69c900;
  }
  .deactivateButton:hover {
    background-color: red !important;
    color: #ffffff !important;
  }
  .deactivateButton {
    background-color: #fff !important;
    color: #f00 !important;
    border: 1px solid #f00;
  }
}
</style>
