<template>
  <!-- detail -->
  <div id="contact">
    <section v-if="!currentUserLoading">
      <section
        class="myAccHead"
        :class="localStorageItem ? 'myAccHeadForAdmin' : ''"
      >
        <div class="container-fluid contentContainer">
          <div class="row">
            <div class="detailNav">
              <a href="#"
                ><img
                  src="../assets/Vector.png"
                  class="img-fluid"
                  alt="img"
                />My Account</a
              >
            </div>
            <div class="hrLine" />
          </div>
          <div class="row">
            <div class="detailContent">
              <div class="detailsText">
                <h2>Contact Details</h2>
                <div class="line" />
              </div>
              <ToastAlert
                :message="currentUserNameMessage"
                :messageTextSuccess="'You have successfully changed your contact details!'"
                :messageTextError="'You have Failed to change your contact details !'"
                top="true"
                @hideElement="
                  () => store.dispatch('resetCurrentUserNameMessage')
                "
              ></ToastAlert>
              <ToastAlert
                :message="updateAvatarMessage"
                :messageTextSuccess="'You have successfully changed your profile picture!'"
                :messageTextError="'You have failed to change your profile picture !'"
                @hideElement="() => store.dispatch('resetAvatarMessage')"
              ></ToastAlert>
              <ToastAlert
                :message="uploadAvatarMessage"
                :messageTextSuccess="'You have  successfully upload your profile picture !'"
                :messageTextError="'You have failed to change your profile picture !'"
                @hideElement="() => store.dispatch('resetAvatarMessage')"
              ></ToastAlert>
              <ToastAlert
                :message="deleteAvatarMessage"
                :messageTextSuccess="'You have successfully deleted your profile picture!'"
                :messageTextError="'You have failed to save the changes !'"
                top="true"
                @hideElement="
                  () => store.dispatch('resetCurrentUserNameMessage')
                "
              ></ToastAlert>
              <div class="contactForm">
                <div class="contactForm__avatar">
                  <img
                    v-if="!userData.avatar"
                    src="@/assets/avatar.png"
                    alt="avatar"
                  />
                  <img
                    v-else
                    class="avatarImg"
                    :src="userData.avatar.url"
                    alt="avatar"
                  />
                  <div class="contactForm__avatar--buttonGroup">
                    <div id="pencil" @click.prevent="editAvatarBoxFunction">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2489 20.7313L25.5352 6.44498C25.9815 5.99847 26.2323 5.39295 26.2323 4.76158C26.2323 4.13022 25.9815 3.5247 25.5352 3.07818L23.1541 0.697138C22.7076 0.250761 22.1021 0 21.4707 0C20.8393 0 20.2338 0.250761 19.7873 0.697138L5.50104 14.9834C5.31948 15.1643 5.16832 15.3734 5.0534 15.6025L3.12 23.1123L10.6298 21.1908C10.8598 21.0723 11.0689 20.9171 11.2489 20.7313Z"
                          :fill="editAvatarBoxPencilColor"
                        />
                        <path
                          d="M27.4235 26.1909H1.23203C0.916281 26.1909 0.613466 26.3163 0.3902 26.5396C0.166934 26.7629 0.0415039 27.0657 0.0415039 27.3814C0.0415039 27.6972 0.166934 28 0.3902 28.2233C0.613466 28.4465 0.916281 28.572 1.23203 28.572H27.4235C27.7393 28.572 28.0421 28.4465 28.2654 28.2233C28.4886 28 28.6141 27.6972 28.6141 27.3814C28.6141 27.0657 28.4886 26.7629 28.2654 26.5396C28.0421 26.3163 27.7393 26.1909 27.4235 26.1909Z"
                          :fill="editAvatarBoxPencilColor"
                        />
                      </svg>
                    </div>
                    <div
                      id="recycle"
                      :data-bs-toggle="userData?.avatar?.id ? 'modal' : ' '"
                      data-bs-target="#staticDefaultModal"
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 13 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.928571 17H12.0714V3.77778H0.928571V17ZM13 0.944444H9.75L8.82143 0H4.17857L3.25 0.944444H0V2.83333H13V0.944444Z"
                          fill="gray"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="contactForm__name" v-if="!editFullName">
                  <div class="contactForm__name--static">
                    <div>{{ firstName ? `${firstName} ${lastName}` : "" }}</div>
                    <div
                      id="pencil2"
                      @click.prevent="
                        () => {
                          editFullName = true;
                          changeFullName.firstName = firstName;
                          changeFullName.lastName = lastName;
                        }
                      "
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2489 20.7313L25.5352 6.44498C25.9815 5.99847 26.2323 5.39295 26.2323 4.76158C26.2323 4.13022 25.9815 3.5247 25.5352 3.07818L23.1541 0.697138C22.7076 0.250761 22.1021 0 21.4707 0C20.8393 0 20.2338 0.250761 19.7873 0.697138L5.50104 14.9834C5.31948 15.1643 5.16832 15.3734 5.0534 15.6025L3.12 23.1123L10.6298 21.1908C10.8598 21.0723 11.0689 20.9171 11.2489 20.7313Z"
                          fill="gray"
                        />
                        <path
                          d="M27.4235 26.1909H1.23203C0.916281 26.1909 0.613466 26.3163 0.3902 26.5396C0.166934 26.7629 0.0415039 27.0657 0.0415039 27.3814C0.0415039 27.6972 0.166934 28 0.3902 28.2233C0.613466 28.4465 0.916281 28.572 1.23203 28.572H27.4235C27.7393 28.572 28.0421 28.4465 28.2654 28.2233C28.4886 28 28.6141 27.6972 28.6141 27.3814C28.6141 27.0657 28.4886 26.7629 28.2654 26.5396C28.0421 26.3163 27.7393 26.1909 27.4235 26.1909Z"
                          fill="gray"
                        />
                      </svg>
                      <!-- <UtilsIcon
                        name="pencil"
                        width="14"
                        height="15"
                        color="gray"
                      ></UtilsIcon> -->
                    </div>
                  </div>
                </div>
                <div v-else class="contactForm__editName">
                  <input type="text" v-model="changeFullName.firstName" />
                  <input type="text" v-model="changeFullName.lastName" />

                  <UtilsIcon
                    @click.prevent="saveChangeProfileFullName"
                    name="checkSuccess2"
                    width="29"
                    height="29"
                  ></UtilsIcon>
                </div>
              </div>

              <div class="uploadImage" v-if="editAvatarBox && !previewUrl">
                <div class="uploadImage__box">
                  <div class="ChooseBrowseBox">
                    <div class="ChooseBrowse">
                      <a href="#"
                        ><img
                          src="../assets/Group.png"
                          alt="img"
                          class="img-fluid"
                      /></a>
                      <button class="choose">
                        <input
                          id="customFile"
                          type="file"
                          class="custom-file-input"
                          @change="previewImage"
                        />
                      </button>
                    </div>
                    <button type="button" class="btn btn-secondary avatar">
                      Upload Avatar
                    </button>
                  </div>
                </div>
              </div>
              <div v-else-if="editAvatarBox && previewUrl">
                <div class="avatarPreviewBlock">
                  <template v-if="previewUrl">
                    <img :src="previewUrl" class="avatarPreview" />
                  </template>
                </div>
              </div>
              <div class="saveBtn d-flex" v-if="editAvatarBox && previewUrl">
                <button
                  type="buttsubmiton"
                  class="btn btn-primary"
                  @click.prevent="saveChangeProfileAvatar"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- detail -->
      <!-- login -->
      <section class="loginDetail text-start">
        <div class="container-fluid contentContainer">
          <div class="row">
            <div class="loginDetail">
              <div class="detailsText">
                <h2>Login Details</h2>
                <div class="line" />
              </div>
              <div class="loginForm">
                <form>
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <div class="form-row row">
                      <div class="form-group col-lg-6 col-md-6">
                        <label for="inputEmail4"
                          >Email Adderss
                          <span class="text-danger">*</span></label
                        >
                        <input
                          id="inputEmail4"
                          type="email"
                          class="form-control"
                          v-model="userEmail"
                          placeholder="John.Smith@builders.com"
                          disabled
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-6">
                        <label for="inputEmail8">Current Password *</label>
                        <input
                          id="inputEmail8"
                          v-model="resetPassword.password"
                          type="password"
                          class="form-control"
                          placeholder="Current Password"
                        />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="form-group col-lg-6 col-md-6">
                        <label for="inputEmail5">New Password *</label>
                        <input
                          id="inputEmail5"
                          v-model="resetPassword.newPassword"
                          type="password"
                          class="form-control"
                          placeholder="New Password"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-6">
                        <label for="inputPassword4"
                          >Confirm New Password
                          <span class="text-danger">*</span></label
                        >
                        <input
                          id="inputPassword4"
                          v-model="resetPassword.confirmPassword"
                          type="password"
                          class="form-control"
                          placeholder="Confirm New Password"
                        />
                      </div>
                      <div class="saveBtn">
                        <p
                          v-if="dataChangePassword.errorMessage"
                          class="alert alert-danger"
                          role="alert"
                        >
                          {{
                            dataChangePassword.errorMessage
                              .charAt(0)
                              .toUpperCase() +
                            dataChangePassword.errorMessage.slice(1)
                          }}
                        </p>
                        <p
                          v-else-if="dataChangePassword.success"
                          class="alert alert-success"
                          role="alert"
                        >
                          Password changed successfully!
                        </p>
                        <button
                          type="button"
                          class="saveBtn btn btn-primary"
                          @click.prevent="changePassword"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <div v-else>
      <TableLoader class="m-top"></TableLoader>
    </div>
    <DefaultActionModal
      :header="'Delete Avatar Image'"
      :body="'Are you sure you want to delete your image?'"
      :footer="'Delete'"
      :index="userData?.avatar?.id"
      @confirm="(index) => store.dispatch('deleteAvatar', index)"
      id="staticDefaultModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticDefaultModalLabel"
      aria-hidden="true"
    ></DefaultActionModal>
  </div>
</template>
<script setup>
import ToastAlert from "@/components/utils/ToastAlert.vue";
import { useRouter } from "vue-router";
import { onMounted, computed, ref, watch, defineEmits } from "vue";
import { useStore } from "vuex";
import UtilsIcon from "@/components/utils/UtilsIcon.vue";
import DefaultActionModal from "@/components/popups/DefaultActionModal.vue";
import TableLoader from "@/components/utils/TableLoader.vue";
const store = useStore();
const router = useRouter();
const userData = computed(() => store.getters.getCurrentUser);
const firstName = computed(() => userData.value.firstName);
const lastName = computed(() => userData.value.lastName);
const email = computed(() => userData.value.email);

const changeFullName = ref({
  firstName: "",
  lastName: "",
});

const editFullName = ref(false);
const editAvatarBox = ref(false);
const editAvatarBoxPencilColor = computed(() => {
  return editAvatarBox.value ? "#2a3f7a" : "gray";
});

const emits = defineEmits(["updateProfileHeader"]);
const userEmail = computed(() => {
  return email.value;
});

const dataChangePassword = computed(() => {
  return store.getters.getChangePassword;
});
const currentUserNameMessage = computed(() => {
  return store.getters.getUpdateCurrentUserNameMessage;
});
const updateAvatarMessage = computed(() => {
  return store.getters.getUpdateAvatarMessage;
});
const currentUserLoading = computed(() => {
  return store.getters.getCurrentUserLoading;
});
const uploadAvatarMessage = computed(() => {
  return store.getters.getUploadAvatarMessage;
});

const deleteAvatarMessage = computed(() => {
  return store.getters.getDeleteAvatarMessage;
});
const resetPassword = ref({
  newPassword: "",
  password: "",
  confirmPassword: "",
});

const previewUrl = ref();
const image = ref();

const localStorageItem = computed(() => localStorage.getItem("globalToken"));

function editAvatarBoxFunction() {
  editAvatarBox.value = !editAvatarBox.value;
  if ((previewUrl.value || image.value) && editAvatarBox.value) {
    previewUrl.value = null;
    image.value = null;
  }
}
function previewImage(event) {
  let input = event.target;
  if (input.files) {
    let reader = new FileReader();
    if (reader) {
      reader.onload = (e) => {
        previewUrl.value = e.target.result;
      };
      image.value = input.files[0];
      if (image.value) {
        reader.readAsDataURL(input.files[0]);
      }
    }
  }
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

function saveChangeProfileAvatar() {
  if (userData.value?.avatar?.url) {
    store.dispatch("updateAvatar", {
      name: userData.value?.avatar.name,
      url: previewUrl.value,
      key: userData.value?.avatar.key,
    });
  } else {
    store.dispatch("uploadAvatar", {
      image: dataURItoBlob(previewUrl.value),
    });
  }
  previewUrl.value = null;
  image.value = null;
  editAvatarBox.value = false;
}

function saveChangeProfileFullName() {
  store.dispatch("updateCurrentUserName", {
    firstName: changeFullName.value.firstName,
    lastName: changeFullName.value.lastName,
  });
}
function changePassword() {
  store.dispatch("changePassword", resetPassword.value);
  store.dispatch("resetAlert");
  resetPassword.value.newPassword = "";
  resetPassword.value.password = "";
  resetPassword.value.confirmPassword = "";
}


watch(updateAvatarMessage.value, () => {
  if (updateAvatarMessage.value.success) {
    emits("updateProfileHeader");
    setTimeout(() => {
      store.dispatch("resetAvatarMessage");
    }, 3000);
  } else if (updateAvatarMessage.value.failure) {
    setTimeout(() => {
      store.dispatch("resetAvatarMessage");
    }, 3000);
  }
});
watch(uploadAvatarMessage.value, () => {
  if (uploadAvatarMessage.value.success) {
    emits("updateProfileHeader");
    setTimeout(() => {
      store.dispatch("resetAvatarMessage");
    }, 3000);
  } else if (uploadAvatarMessage.value.failure) {
    setTimeout(() => {
      store.dispatch("resetAvatarMessage");
    }, 3000);
  }
});
watch(deleteAvatarMessage.value, () => {
  if (deleteAvatarMessage.value.success) {
    emits("updateProfileHeader");
    setTimeout(() => {
      store.dispatch("resetAvatarMessage");
    }, 3000);
  } else if (uploadAvatarMessage.value.failure) {
    setTimeout(() => {
      store.dispatch("resetAvatarMessage");
    }, 3000);
  }
});
watch(currentUserNameMessage.value, () => {
  if (
    currentUserNameMessage.value.success ||
    currentUserNameMessage.value.failure
  ) {
    editFullName.value = false;
    setTimeout(() => {
      store.dispatch("resetCurrentUserNameMessage");
    }, 3000);
  }
});


onMounted(() => {
  window.scrollTo(0, 0);
  if (!localStorage.getItem("vsl-token")) {
    router.push("/login");
  }
});
</script>
<style lang="scss" scoped>
.avatarPreviewBlock {
  width: 227px;
  height: 227px;
  background-color: rgba($color: #000000, $alpha: 0.15);
  margin-top: 25px;
}
.avatarPreview {
  border: 1px solid;
  border-radius: 50%;
  max-width: 227px;
  max-height: 227px;
}
.avatarBlock {
  margin-top: 45px;
}

.contactForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  margin-top: 31px;
  position: relative;
  &__avatar {
    img {
      z-index: 1;
    }
    &--buttonGroup {
      z-index: 100;
      position: absolute;
      bottom: -10px;
      left: 27px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 57px;
      height: 29px;
      div {
        background-color: white;
        border: 1px solid gray;
        cursor: pointer;
        display: flex;
        width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
      }
    }
  }
  img {
    max-width: 113px;
    max-height: 113px;
    min-height: 113px;
    min-width: 113px;
    border-radius: 50%;
  }
  &__name {
    width: 400px;
    height: 53px;
    border: 1px solid gray;
    border-radius: 8px;

    &--static {
      padding: 10px 16px 10px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      #pencil2 {
        cursor: pointer;
        padding: 3px 7px;
        border: 1px solid gray;
        border-radius: 6px;
      }
    }
  }

  &__editName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    input {
      padding: 15px;
      width: 200px;
      height: 53px;
      border-radius: 8px;
      border: 1px solid gray;
      &:active {
        border: 1px solid #237fa3 !important;
      }
    }
  }
}

.uploadImage {
  margin-top: 40px !important;
  display: flex;
  flex-direction: row;
  &__box {
    border-radius: 5px;
    background-color: #ebeef642;
    width: 212px;
    height: 193px;
    border: 1px solid #ebeef6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

#pencil:hover > svg *,
#pencil:hover {
  background-color: #2a3f7a;
  fill: #ffffff;
  border: 1px solid #2a3f7a;
}
#pencil2:hover > svg *,
#pencil2:hover {
  background-color: #2a3f7a;
  fill: #ffffff;
  border: 1px solid #2a3f7a;
}
#recycle:hover > svg *,
#recycle:hover {
  background-color: #f00;
  fill: #ffffff;
  border: 1px solid #f00;
}

#recycle {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
#pencil {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.avatarPreviewBlock {
  width: 212px;
  height: 193px;
  img {
    max-width: 212px;
    min-width: 212px;
    max-height: 193px;
    min-height: 193px;
  }
}

.switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  &__back {
    display: flex;
    gap: 15px;
    color: white;
    background: blue;
    padding: 11px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px !important;
  }
}
.myAccHeadForAdmin {
  padding-top: 45px !important;
}
.loginAlert {
  background: #ecf1fe;
  border: 1px solid #2a61ff;
  font-size: 20px;
  color: #2a61ff;
  font-weight: 600;
}
.m-top {
  margin-top: 15% !important;
}
</style>
