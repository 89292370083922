<template>
  <div>
    <section class="creative" style="position: relative">
      <div class="container-fluid contentContainer">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="col-sm-12" v-if="subscripePlanShow && !userData?.subscription?.product?.productName.includes('Creative Club')">
              <div class="join-club">
                <div class="creative-club">
                  <div class="image-border">
                    <div>
                      <h5>
                        <img src="@/assets/Shape.png" alt="support" />
                        JOIN THE CREATIVE CLUB
                      </h5>
                      <div class="creative-club__close">
                        <span> DISMISS</span>
                        <svg
                          @click.prevent="subscripePlanShow = false"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-x-circle cursor-pointer"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          />
                          <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="line"></div>
                  </div>
                  <p>
                    Unlock
                    <span class="text-decoration-underline cursor-pointer"
                      >unlimited access</span
                    >
                    to millions of royalty assets and powerful A.I automation!
                  </p>
                  <ul class="text-start">
                    <li>
                      <img src="@/assets/image.png" alt="support" />Stock Photos
                    </li>
                    <li>
                      <img src="@/assets/music.png" alt="support" />Stock Music
                    </li>
                    <li>
                      <img src="@/assets/illustrater.png" alt="support" />Stock
                      Illustrations
                    </li>
                    <li>
                      <img src="@/assets/audioSync.png" alt="audioSync" />
                      AI-Powered Audio/Text Sync
                    </li>
                    <li>
                      <img
                        src="@/assets/font-svgrepo-com1.png"
                        alt="support"
                      />Stock Fonts
                    </li>
                    <li>
                      <img
                        src="@/assets/painting-landscape-framed-svgrepo-com1.png"
                        alt="support"
                      />Stock Vectors
                    </li>

                    <li>
                      <img
                        src="@/assets/videos-svgrepo-com_1.png"
                        alt="support"
                      />Stock Videos
                    </li>
                    <li>
                      <img src="@/assets/paper.png" alt="paper" />AI-Powered
                      Script Creator
                    </li>
                  </ul>
                  <div class="start-learn-btn">
                    <a
                      class="learn-button"
                      href="https://easyvsl.com/oto1-v4/"
                      target="_blank"
                      >LEARN MORE</a
                    >
                    <button class="start-button" data-bs-toggle="modal" data-bs-target="#upgradeCreativePlan" >
                      UPGRADE NOW
                    </button>
                    <p>THEN JUST <span>$29</span> / mo</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div
                class="latestText text-start"
                :class="!subscripePlanShow ? 'latestV' : ''"
              >
                <div class="latestContent">
                  <h4>Latest Version</h4>
                  <div class="dashLine" />
                </div>

                <div class="secContent">
                  <p>
                    Download EasyVSL to your computer using one of the links
                    below.
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-center gap-4 flex-wrap">
                <a :href="windowsLink" target="_blank" style="text-decoration: none;">
                  <button class="arrow-btn">
                    <img src="@/assets/arrow.png" alt="support" />PC
                  </button>
                </a>
                <a :href="macLink" target="_blank" style="text-decoration: none;">
                  <button class="arrow-btn">
                    <img src="@/assets/arrow.png" alt="support" />MAC (Intel)
                  </button>
                </a>
                <a :href="macSiliconLink" target="_blank" style="text-decoration: none;">
                  <button class="arrow-btn">
                    <img src="@/assets/arrow.png" alt="support" />MAC (Silicon)
                  </button>
                </a>
              </div>
              </div>
            </div>

            <div class="col-sm-12" v-if="userDataRole !== 'Admin'">
              <div class="license">
                <div class="licenseContent">
                  <h4>LICENSING</h4>
                  <div class="dashLine" />
                </div>
                <!-- <div class="sec-licenseContent text-start d-none">
                  <p>Active License Key</p>
                  <div v-if="activeLicenseKeyLoading" class="d-none">
                    <TableLoader></TableLoader>
                  </div>
                  <div v-else-if="activeLicenseKeys?.length > 0">
                    <div
                      v-for="activeLicenseKey in activeLicenseKeys"
                      :key="activeLicenseKey.id"
                      class="EVSL-content"
                    >
                      <div class="activeLicenseKey">
                        <div>{{ activeLicenseKey.licenseKey }}</div>
                        <button
                          class="deactive-box"
                          v-if="index !== isHidden"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop3"
                          @click="
                            () => {
                              modalData.data = activeLicenseKey;
                              modalData.type = 'deactivate';
                            }
                          "
                        >
                          DEACTIVATE
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="d-none">No active license key</div>
                </div> -->
                <div class="sec-licenseContent text-start">
                  <p> Use one of the valid license keys below to activate your software. Each key can only be used on a single computer. </p>
                </div>
                <div v-if="activeLicenseKeyLoading" class="d-none">
                    <TableLoader></TableLoader>
                  </div>
                  <div v-else-if="activeLicenseKeys?.length > 0">
                    <div
                      v-for="activeLicenseKey in activeLicenseKeys"
                      :key="activeLicenseKey.id"
                      class="EVSL-content"
                    >
                      <div class="activeLicenseKey">
                        <div>{{ activeLicenseKey.licenseKey }}</div>
                        <button
                          class="deactive-box"
                          v-if="index !== isHidden"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop3"
                          @click="
                            () => {
                              modalData.data = activeLicenseKey;
                              modalData.type = 'deactivate';
                            }
                          "
                        >
                          DEACTIVATE
                        </button>
                      </div>
                    </div>
                  </div>

                <div v-if="allLicenseKeysLoading">
                        <TableLoader></TableLoader>
                      </div>
                      <div v-else-if="paginatedNonActiveKeys.length > 0">
                        <div v-for="(item, index) in paginatedNonActiveKeys" :key="item.id">
                          <div class="EVSL-content">
                            <p :class="index === isHidden ? 'copied-class' : ''">
                              {{ item.licenseKey }}
                              <img
                                src="@/assets/licensebox.png"
                                alt="support"
                                class="img-fluid license-img-box without-copy"
                                @click="copyToClipboard(item, index)"
                              />
                              <img
                                src="@/assets/licensebox-copied.png"
                                alt="support"
                                class="img-fluid license-img-box with-copy"
                                @click="copyToClipboard(item, index)"
                              />
                              <span v-if="index === isHidden">Copied!</span>
                            </p>
                            <div>
                              <span :class="getStatusClass(item.status)">
                                {{ item.status === "VALID" ? "AVAILABLE"  : item.status === "PENDING" ? "ASSIGNED" : "UNAVAILABLE" }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p>No non-active license keys available.</p>
                      </div>
                       <div class="pagination">
                        <nav aria-label="pagination-license-keys">
                          <ul class="pagination" v-if="nonActiveLicenseKeys.length > itemsPerPage">
                            <li class="page-item" v-for="i in totalPages" :key="i">
                              <a
                                class="page-link"
                                :class="i == currentPage ? 'enableLink' : 'disableLink'"
                                href="#"
                                @click.prevent="goToPage(i)"
                              >{{ i }}</a>
                            </li>
     
                        
                      <!-- <li>
                        <a
                          class="page-link"
                          href="#"
                          aria-label="Next"
                          @click.prevent="nextPage"
                          :class="
                            currentPage == pageCount
                              ? 'disableLink'
                              : 'enableLink'
                          "
                        >
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li> -->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-sm-12 addons" v-if="userDataRole == 'User'">
              <div class="addones">
                <div class="addonesContent">
                  <h4>ADD-ONS</h4>
                  <div class="dashLine" />  
                </div>
                <div class="sec-addonesContent text-start">
                  <p>
                    Upgrade your EasyVSL account with powerful enhancements and
                    additional features.
                  </p>
                </div>

                <div class="main-content text-start">
                  <div class="customer-purchasing-content text-start">
                    
                  </div>
                  <div class="customer-purchasing-content">
                    <div class="creative-section">
                      <div class="innerContent">
                        <h2>
                          {{
                            userData?.subscription?.product?.productName.toUpperCase()
                          }}
                        </h2>
                        <div
                          class="active-box-content"
                          :class="
                            userData?.subscription?.status === 'ACTIVE'
                              ? 'active-box-button'
                              : 'deactive-box-button'
                          "
                        >
                          
                          <div class="d-flex align-items-center gap-1">
                            <div
                              class="circle"
                              :class="
                                userData?.subscription?.status === 'ACTIVE'
                                  ? 'success'
                                  : 'danger'
                              "
                            ></div>
                            <div class="active-text">
                              {{
                                userData?.subscription?.status === "ACTIVE"
                                  ? "Activated"
                                  : "Deactivated"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <p v-if="userData?.subscription?.product?.productName.toUpperCase() === 'PROFESSIONAL LICENSE'">
                          The main license for EasyVSL, the #1 most innovative and easy 
                          to use video creation tool for creating high-converting video sales letters.
                        </p>
                        
                        <p v-if="userData?.subscription?.product?.productName.toUpperCase().includes('CREATIVE CLUB')">

                          Unlock unlimited access to millions of royalty free audio, video and graphical assets to supercharge your EasyVSL 
                          videos! Boost your content creation with high-quality resources at your fingertips.
                        </p>
                        <a
                            v-if="userData?.subscription?.product?.productName.toUpperCase().includes('CREATIVE CLUB')"
                            href="https://easyvsl.com/oto1-v4/"
                            target="_blank"
                            class="d-inline-block"
                          >
                            <button class="learn-more-btn">LEARN MORE</button>
                          </a>
                    </div>
                  </div>
                  <div class="customer-purchasing-content text-start">
                    <div class="creative-section">
                      <div class="innerContent">
                        <h2>PRESSPLAY</h2>
                        <div class="d-flex align-items-center gap-4">
                          <div class="syntex">$197.00</div>
                          <a
                            href="https://easyvsl.com/oto2-v4/"
                            target="_blank"
                            class="d-inline-block"
                          >
                            <button class="deactive-box-content">
                              Purchase
                            </button>
                          </a>
                        </div>
                      </div>
                      <p>
                        Transform your videos with a customizable video player, 
                        designed to enhance engagement and drive conversions. 
                        It features innovative tools like opt-in form overlays, 
                        call-to-action buttons, share gates, and distinctive video skins to make your videos pop. 
                        Plus, leverage popular free hosting providers such as YouTube 
                        and Vimeo to eliminate video hosting costs forever.
                      </p>
                      <a
                        href="https://easyvsl.com/oto2-v4/"
                        target="_blank"
                        class="d-inline-block"
                      >
                        <button class="learn-more-btn">LEARN MORE</button>
                      </a>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="col-12">
              <!-- <BillingHistory
                v-if="userDataRole === 'User'"
              ></BillingHistory> -->
              <DashboardTraining></DashboardTraining>
            </div>
          </div>
        </div>
        <div v-if="upgradeSuccess || upgradeFailure" class="toastAlert">
          <div
            class="alertType alert"
            :class="upgradeSuccess ? 'alert-success' : 'alert-danger'"
          >
            <div class="textW" style="width: 89%">
              {{
                upgradeSuccess
                  ? upgradeSuccess
                  : upgradeFailure
                  ? upgradeFailure
                  : ""
              }}
            </div>
            <div>
              <button
                @click="store.dispatch('hideToastSubscriptionAlert')"
                type="button"
                class="close btn"
                :class="
                  upgradeSuccess ? 'btn-outline-success' : 'btn-outline-danger'
                "
                data-dismiss="alert"
                aria-label="Close"
              >
                &times;
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <UpgradeCreativeClubModal class="modal fade modal-lg"
      id="upgradeCreativePlan"
      data-bs-backdrop="static"
      ref="upgradeCreativeClubModal"
      @open-second-modal="openSecondModal"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="upgradeCreativePlanLabel"
      aria-hidden="true"></UpgradeCreativeClubModal>
    <AddVslModal
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    ></AddVslModal>

    <DeactivateLicenseKeyModal
      class="modal fade"
      id="staticBackdrop3"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel3"
      aria-hidden="true"
      :modalData="modalData"
      @deactivate="deactivateLicenseKey"
      @activate="activateLicenseKey"
    ></DeactivateLicenseKeyModal>
    <!-- <ToastAlert></ToastAlert> -->
  </div>
</template>
<script setup>
/* imports */
import { onMounted, ref, computed, watch, defineEmits } from "vue";
import { useStore } from "vuex";
import TableLoader from "@/components/utils/TableLoader.vue";
import AddVslModal from "@/components/popups/AddVslModal.vue";
import UpgradeCreativeClubModal from "@/components/popups/UpgradeCreativeClubModal.vue"
import DeactivateLicenseKeyModal from "@/components/popups/DeactivateLicenseKeyModal.vue";
import router from "@/router";
// import BillingHistory from "@/components/dashboard/BillingHistory.vue";
import DashboardTraining from "@/components/dashboard/DashboardTraining.vue";
const store = useStore();

const subscripePlanShow = ref(true);
const userData = computed(() => store.getters.getCurrentUser);
const userDataRole = computed(() => userData.value.role);
const emits = defineEmits(["updateLicenseKeys"]);
let isHidden = ref(-1);
const modalData = ref({
  type: null,
  data: null,
});

const allLicenseKeys = computed(() => store.getters.getAllLicenseKeys?.items || []);
const allLicenseKeysLoading = computed(() => store.getters.getAllLicenseKeysLoading);

const nonActiveLicenseKeys = computed(() => 
  allLicenseKeys.value.filter(item => item.status !== 'ACTIVE')
);

const windowsLink = computed(() => {
  const windowsVersion = store.getters.getLatestVersions.find(v => v.device === 'WINDOWS');
  return windowsVersion ? windowsVersion.link : 'https://easyvsl.com/pc-v4/';
});

const macLink = computed(() => {
  const macVersion = store.getters.getLatestVersions.find(v => v.device === 'MAC');
  return macVersion ? macVersion.link : 'https://easyvsl.com/mac-v4/';
});

const macSiliconLink = computed(() => {
  const macVersion = store.getters.getLatestVersions.find(v => v.device === 'MAC');
  if (macVersion) {
    const version = macVersion.version;
    return `https://github.com/EasyVSL/easyvsl-v4-updates/releases/download/v${version}/EasyVSL4-${version}-arm64.dmg`;
  }
  return 'https://easyvsl.com/mac-v4/';
});


const getStatusClass = (status) => {
  switch (status) {
    case 'IS_USED':
      return 'available-key status-invalid';
    case 'VALID':
      return 'available-key status-valid';
    case 'PENDING':
      return 'available-key status-invalid';
    case 'INVALID':
      return ' available-key status-invalid';
    default:
      return '';
  }
};

const itemsPerPage = 10;
const currentPage = ref(1);

const paginatedNonActiveKeys = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return nonActiveLicenseKeys.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => 
  Math.ceil(nonActiveLicenseKeys.value.length / itemsPerPage)
);

function goToPage(page) {
  currentPage.value = page;
}

const items = computed(() => {
  return store.getters.getLicenseKeys?.items;
});
// const metaItems = computed(() => {
//   return store.getters.getLicenseKeys?.meta;
// });
const activeLicenseKeys = computed(() => {
  return store.getters.getActiveLicenseKey?.items;
});


const itemsSort = ref();

const upgradeSuccess = computed(
  () => store.getters.getSubscriptionUpgradeSuccess
);
const upgradeFailure = computed(
  () => store.getters.getSubscriptionUpgradeFailure
);
const activeLicenseKeyLoading = computed(
  () => store.getters.getActiveLicenseKeyLoading
);
// const getLoadingLicenseKey = computed(() => store.getters.getLoadingLicenseKey);

const licenseKeyActionSuccess = computed(
  () => store.getters.getLicenseKeyActionSuccess
);




const openSecondModal = (pricing) => {
  const upgradeCreativeClubModal = ref(null);
  upgradeCreativeClubModal.value.showSecondModal(pricing);
};

function copyToClipboard(text, selected) {
  if (isHidden.value == -1) {
    isHidden.value = selected;
    const elem = document.createElement("textarea");
    elem.value = text.licenseKey;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    setTimeout(() => {
      isHidden.value = -1;
    }, 1000);
  }
}

function activateLicenseKey(payload) {
  store.dispatch("activateLicenseKey", payload);
  emits("updateLicenseKeys");
}

function deactivateLicenseKey(payload) {
  store.dispatch("deactivateLicenseKey", payload);
  emits("updateLicenseKeys");
}

// function goToPage(numPage) {
//   if (numPage == currentPage.value) {
//     return;
//   }
//   if (numPage > totalPages.value) {
//     return;
//   }
//   store.dispatch("updateLicenseKeys", {
//     page: numPage,
//   });
// }

watch(
  () => items.value,
  () => {
    if (items.value && activeLicenseKeys.value) {
      if (userDataRole.value !== "Admin") {
        setTimeout(() => {
          if (
            items.value.length === 0 &&
            userDataRole.value !== "Admin" &&
            activeLicenseKeys.value.length === 0
          ) {
            if (!localStorage.getItem("globalToken")) {
              store.dispatch("logOut");
            }
            if (userDataRole.value === "User") {
              router.push("/purchase-new-subscription");
            } else {
              router.push("/deactivate-user-license-page");
            }
          }
        }, 100);
        itemsSort.value = items?.value.sort((a, b) => {
          return a.id < b.id ? -1 : 1;
        });
      }
    }
  }
);

watch(
  () => licenseKeyActionSuccess.value,
  () => {
    if (licenseKeyActionSuccess.value) {
      store.dispatch("updateLicenseKeys");
      store.dispatch("updateLicenseKeys", { status: "ACTIVE" });
    }
  }
);
watch(
  () => upgradeSuccess.value,
  () => {
    if (upgradeSuccess.value) {
      setTimeout(() => {
        store.dispatch("hideToastSubscriptionAlert");
      }, 5000);
    }
  }
);

watch(
  () => upgradeFailure.value,
  () => {
    if (upgradeFailure.value) {
      setTimeout(() => {
        store.dispatch("hideToastSubscriptionAlert");
      }, 5000);
    }
  }
);

onMounted(() => {
  store.dispatch("updateLicenseKeys", { status: "ACTIVE" });
  store.dispatch("updateLicenseKeys");
  store.dispatch("fetchAllLicenseKeys");
  store.dispatch('getLatestVersions');
  // if (localStorage.getItem("globalToken")) {
  // } else {
  //   store.dispatch("updateLicenseKeys");
  // }
});
</script>

<style lang="scss" scoped>
.vsl-dashboard {
  background: #e5e5e5;
  padding: 25px 38px 0;

  &__header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    color: #a3adcd;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2.5px;
    text-transform: capitalize;
  }

  &__content {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;

    &--new {
      filter: drop-shadow(1px 3px 9px rgba(0, 0, 0, 0.15));
      cursor: pointer;
      width: 232px;
      height: 197px;
      padding: 40px 50px 30px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: 32px;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 4px;
      background: #fff;
      font-size: 20px;
      font-family: "Montserrat";
      font-style: "Regular";
      margin-bottom: 24px;

      > div {
        span {
          color: #a3adcd !important;
        }
      }
    }

    &--card {
      width: 232px;
      height: 197px;
      border: 1.5px solid #ebedf3;
      border-radius: 4px;
      margin-bottom: 24px;
    }
  }
}


.status-used { background-color: rgba(0, 0, 255, 0.3); }
.status-valid { background-color: #03d670; }
.status-pending { background-color: orange; }
.status-invalid { background-color: red; }

.card__footer {
  padding: 19px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 9px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  h6 {
    font-size: 16px !important;
    text-align: left !important;
    font-weight: 500;
    color: #333333;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 14px;
    font-size: 13px;

    div {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;
      color: #a3adcd;
    }
  }
}

.success {
  background: #03d670b0 !important;
}
.danger {
  background-color: red !important;
}
.toastAlert {
  width: 350px;
  // background-color: gray;
  // border: 1px solid;
  position: absolute;
  right: 20px;
  top: 150px;
  .alertType {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    button {
      top: 15px;
      right: 15px;
      position: absolute;
      width: 24px;
      height: 24px;
      display: flex;
      font-size: 21px;
      align-items: center;
      justify-content: center;
      border-radius: 4px !important;
    }
  }
}
.alert-danger {
  div {
    button {
      border: 2px solid #842029;
      color: #842029;
      &:hover {
        background-color: #842029 !important;
        color: #fff !important;
      }
    }
  }
}
.table {
  text-align: left !important;
}
.creative {
  padding-bottom: 25px;
}
.disableLink {
  color: gray !important;
}
.enableLink {
  color: #0a58ca !important;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px !important;
  li a {
    border-radius: 50% !important;
  }
  li a:hover {
    background-color: blue !important;
    color: white !important;
  }
}

.activeLicenseKey {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  div {
    padding: 0 33px;
  }
}
.available-key {
  display: inline-block;
  padding: 8px 16px;
  color: #fff;
  font-size: small;
  border-radius: 16px;
  font-family: "Montserrat Regular";
}
</style>
