import { get, patch } from "@/utils";

const versions = {
    state: () => ({
        latestVersions: [],
        latestVersionsLoading: false,
        latestVersionsErrorMessage: null,
    }),
    getters: {
        getLatestVersions: (state) => state.latestVersions,
        getLatestVersionsLoading: (state) => state.latestVersionsLoading,
        getLatestVersionsErrorMessage: (state) => state.latestVersionsErrorMessage,
    },
    mutations: {
        updateLatestVersions: (state, versions) => {
            if (versions) {
                state.latestVersions = versions;
            }
        },
        updateLatestVersionsLoading: (state, loading) => {
            if (typeof loading === "boolean") {
                state.latestVersionsLoading = loading;
            }
        }
    },
    actions: {
        async getLatestVersions({ commit }) {
            commit("updateLatestVersionsLoading", true);
            try {
                const response = await get(`/app-download-links/latest`);
                if (response.data) {
                  commit('updateLatestVersions', response.data);
                  return response.data;
                }
              } catch (error) {
                console.error('Error fetching download links:', error);
                throw error;
              } finally {
                commit("updateLatestVersionsLoading", false);
              }
         },
         async updateAppVersions({ dispatch }, newVersion) {
            try {
                const devices = [
                    { id: 1, device: 'WINDOWS' },
                    { id: 2, device: 'MAC' }
                ];

                for (const { id, device } of devices) {
                    await patch(`/app-download-links/${id}`, {
                        version: newVersion,
                        link: `https://github.com/EasyVSL/easyvsl-v4-updates/releases/download/v${newVersion}/EasyVSL4-${device === 'WINDOWS' ? 'Setup-' : ''}${newVersion}.${device === 'WINDOWS' ? 'exe' : 'dmg'}`,
                        device: device
                    });
                }

                // Refresh the latest versions after update
                await dispatch('getLatestVersions');
            } catch (error) {
                console.error('Error updating app versions:', error);
                throw error;
            }
        }
}
}

export default versions;