<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="box">
            <form class="login-acct">
              <div class="logo">
                <img src="../assets/_logo1.png" alt="supportive" />
              </div>
              <h3>Register to your EasyVSL account</h3>
              <div
                v-if="singUpError && singUpError !== true"
                class="alert alert-danger"
              >
                {{
                  typeof singUpError == "string"
                    ? singUpError.charAt(0).toUpperCase() + singUpError.slice(1)
                    : singUpError[0].charAt(0).toUpperCase() +
                      singUpError[0].slice(1)
                }}
              </div>
              <div class="form">
                <input
                  id="exampleInputEmail2"
                  v-model="firstName"
                  type="text"
                  class="form-control"
                  placeholder="First Name"
                  @focusin="clearErrorMessage"
                />
                <input
                  id="exampleInputEmail3"
                  v-model="lastName"
                  type="text"
                  class="form-control"
                  placeholder="Last Name"
                  @focusin="clearErrorMessage"
                />
                <input
                  id="exampleInputEmail1"
                  v-model="email"
                  type="email"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Email Address"
                  @focusin="clearErrorMessage"
                />
                <button
                  type="submit"
                  class="submitButton"
                  @click.prevent="makeRegister"
                >
                  Register
                </button>
              </div>

              <div class="account">
                <div class="bottom-part">
                  <a>
                    <router-link to="/login" exact
                      >Login in here</router-link
                    ></a
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";

let errors = ref([]);
let email = ref("");
let firstName = ref("");
let lastName = ref("");
const router = useRouter();
const singUpError = computed(() => {
  return store.getters.getSignUpErrorMessage;
});

watch(
  () => singUpError.value,
  () => {
    if (singUpError.value === true) {
      email.value = "";
      firstName.value = "";
      lastName.value = "";
      router.push("/login");
    }
  }
);
function clearErrorMessage() {
  if (singUpError.value) {
    store.dispatch("hideSignUpErrorMessage");
  }
}
async function makeRegister() {
  errors.value = [];
  if (!email.value) {
    errors.value.push("Email is required");
  }
  if (!firstName.value) {
    errors.value.push("First name is required");
  }
  if (!lastName.value) {
    errors.value.push("Last name is required");
  }

  if (email.value && firstName.value && lastName.value) {
    store.dispatch("signUp", {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
    });
  }
}
</script>
