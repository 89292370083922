import SaasProductService from '@/services/saasProductService'

const products = {
  state: () => ({
    allProducts: [],
    currentUserProduct: null,
    loading: false,
    error: null,
  }),
  getters: {
    getAllProducts: (state) => state.allProducts,
    getCurrentUserProduct: (state) => state.currentUserProduct,
    getProductsLoading: (state) => state.loading,
    getProductsError: (state) => state.error,
  },
  mutations: {
    setAllProducts: (state, products) => {
      state.allProducts = products;
    },
    setCurrentUserProduct: (state, product) => {
      state.currentUserProduct = product;
    },
    setProductsLoading: (state, loading) => {
      state.loading = loading;
    },
    setProductsError: (state, error) => {
      state.error = error;
    },
  },
  actions: {
    async fetchAllProducts({ commit }, productType = "") {
      commit('setProductsLoading', true);
      commit('setProductsError', null);
      try {
        const response = await SaasProductService.getAll(productType);
        commit('setAllProducts', response.items);
      } catch (error) {
        commit('setProductsError', error.message);
      } finally {
        commit('setProductsLoading', false);
      }
    },
    async fetchProductById({ commit }, productId) {
      commit('setProductsLoading', true);
      commit('setProductsError', null);
      try {
        const product = await SaasProductService.findProductById(productId);
        commit('setCurrentUserProduct', product);
      } catch (error) {
        commit('setProductsError', error.message);
      } finally {
        commit('setProductsLoading', false);
      }
    },
    async fetchCreativeClubProducts({ commit }, billingPeriod = "") {
      commit('setProductsLoading', true);
      commit('setProductsError', null);
      try {
        const ccProducts = await SaasProductService.getProductsCC(billingPeriod);
        commit('setAllProducts', ccProducts);
      } catch (error) {
        commit('setProductsError', error.message);
      } finally {
        commit('setProductsLoading', false);
      }
    },
  },
  }


export default products;