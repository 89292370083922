import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import UUID from "vue3-uuid";
import VueApexCharts from "vue3-apexcharts";
import VueDatePicker from "@vuepic/vue-datepicker";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/styles/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import mitt from "mitt";
const emitter = mitt();

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(VueApexCharts);
app.component("VueDatePicker", VueDatePicker);
app.component("VueMultiselect", VueMultiselect);
app.use(UUID);
app.provide("emitter", emitter);
app.mount("#app");
