import { post, get, del } from "@/utils";


const licenses = {
  state: () => ({
    validLicenseKeys: null,
    activeLicenseKey: null,
    activeLicenseKeyLoading: false,
    activateLicenseKeyErrorMessage: null,
    licenseKeyActionSuccess: null,
    licenseKeyLoading: false,
    currentLicenseKeyStatus: null,
    licenseId: null,
    disabledLicenseKeyMessage: {
      success: null,
      failure: null
    },
    enabledLicenseKeyMessage: {
      success: null,
      failure: null
    },
    allLicenseKeys: null,
    allLicenseKeysLoading: false,
  }),
  getters: {
    getLicenseKeys: (state) => state.validLicenseKeys,
    getActiveLicenseKey: state => state.activeLicenseKey,
    getLoadingLicenseKey: (state) => state.licenseKeyLoading,
    getActiveLicenseKeyLoading: state => state.activeLicenseKeyLoading,
    getLicenseKeyActionSuccess: (state) => state.licenseKeyActionSuccess,
    getCurrentLicenseKeyStatus: state => state.currentLicenseKeyStatus,
    getSubUserLicense: state => state.licenseId,
    getLicenseKeyErrorMessage: state => state.activateLicenseKeyErrorMessage,
    getDisabledLicenseKeyMessage: state => state.disabledLicenseKeyMessage,
    getEnabledLicenseKeyMessage: state => state.enabledLicenseKeyMessage,
    getAllLicenseKeys: state => state.allLicenseKeys,
    getAllLicenseKeysLoading: state => state.allLicenseKeysLoading,

  },
  mutations: {
    updateLicenseKeys: (state, data) => {
      state.validLicenseKeys = data;
    },
    updateActiveLicenseKey: (state, data) => {
      state.activeLicenseKey = data;
    },
    deactivateLicenseKey: (state, bool) => {
      state.licenseKeyActionSuccess = bool;
    },
    resetDisabledLicenseKeyMessage: (state) => {
      state.disabledLicenseKeyMessage.success = null;
      state.disabledLicenseKeyMessage.failure = null;
    },
    resetEnabledLicenseKeyMessage: (state) => {
      state.enabledLicenseKeyMessage.success = null;
      state.enabledLicenseKeyMessage.failure = null;
    },
    loadingActiveLicenseKey: (state, bool) => {
      state.activeLicenseKeyLoading = bool
    },
    activateLicenseKey: (state, bool) => {
      state.licenseKeyActionSuccess = bool;
    },
    loadingLicenseKey: (state, bool) => {
      state.licenseKeyLoading = bool;
    },
    getCurrentLicenseKeyStatus: (state, status) => {
      state.currentLicenseKeyStatus = status
    },
    getSubUserLicense: (state, id) => {
      state.licenseId = id
    },
    getLicenseKeyErrorMessage: (state, message) => {
      state.activateLicenseKeyErrorMessage = message;
    },
    disabledLicenseKey: (state, data) => {
      if (!data.failure) {
        state.disabledLicenseKeyMessage.success = data.message;
        state.disabledLicenseKeyMessage.failure = null;
      } else {
        state.disabledLicenseKeyMessage.success = null;
        state.disabledLicenseKeyMessage.failure = data.message;
      }
    },
    enabledLicenseKey: (state, data) => {
      if (!data.failure) {
        state.enabledLicenseKeyMessage.success = data.message;
        state.enabledLicenseKeyMessage.failure = null;
      } else {
        state.enabledLicenseKeyMessage.success = null;
        state.enabledLicenseKeyMessage.failure = data.message;
      }
    },
    updateAllLicenseKeys: (state, data) => {
      state.allLicenseKeys = data;
    },
    loadingAllLicenseKeys: (state, bool) => {
      state.allLicenseKeysLoading = bool;
    },
  },
  actions: {
    updateLicenseKeys({ commit }, data) {
      let page = 1;
      let limit = 10;
      let status = "VALID"
      commit("loadingLicenseKey", true);

      if (data) {
        if (data?.page) {
          page = data.page;
        }
        if (data?.limit) {
          limit = data.limit;
        }
        if (data?.status) {
          status = data.status;
        }

      }

      if (status === "VALID") {
        commit("loadingLicenseKey", true);
      } else if (status === "ACTIVE") {
        commit("loadingActiveLicenseKey", true);

      }
      let licenseApiEndpoind = `/licenses/?page=${page}&limit=${limit}&status=${status}`;

      get(licenseApiEndpoind)
        .then(({ data }) => {
          if (status == "VALID") {
            commit("updateLicenseKeys", data);
          } else if (status == "ACTIVE") {
            commit("updateActiveLicenseKey", data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (status === "VALID") {
            commit("loadingLicenseKey", false);
          } else if (status === "ACTIVE") {
            commit("loadingActiveLicenseKey", false);

          }
        });
    },
    disabledLicenseKey({ commit }, id) {
      let delData = {};
      del(`/licenses/${id}/invalidate`)
        .then((data) => {
          if (data.status == 200) {
            delData.failure = false;
            delData.message = "You have successfully disabled the license key!";
          } else if (data.statusCode === 400) {
            delData.failure = true;
            delData.message = data.message;
          }
        })
        .catch(() => {
          delData.failure = true;
          delData.message = "You have failed to disabled the license key!";
        })
        .finally(() => {
          commit("disabledLicenseKey", delData);
        });
    },

    // disabledLicenseKey({ commit }, id) {
    //   let delData = {};
    //   patch(`/users/${id}`, {
    //     status: "DEACTIVATED",
    //   })
    //     .then((data) => {
    //       if (data.status == 200) {
    //         delData.failure = false;
    //         delData.message = "You have successfully disabled the license key!";
    //       } else if (data.statusCode === 400) {
    //         delData.failure = true;
    //         delData.message = data.message;
    //       }
    //     })
    //     .catch(() => {
    //       delData.failure = true;
    //       delData.message = "You have failed to disabled the license key!";
    //     })
    //     .finally(() => {
    //       commit("disabledLicenseKey", delData);
    //     });
    // },

    enabledLicenseKey({ commit }, id) {

      let enableData = {};
      post(`/licenses/${id}/validate`)
        .then((data) => {

          if (data.status == 201) {
            enableData.failure = false;
            enableData.message = data.data.message;
          } else if (data.statusCode === 400) {
            enableData.failure = true;
            enableData.message = data.message;
          }
        })
        .catch(() => {
          enableData.failure = true;
          enableData.message = "You have failed to enabled the license key!";
        })
        .finally(() => {
          commit("enabledLicenseKey", enableData);
        });

    },
    deactivateLicenseKey({ commit }, payload) {
      post(`/licenses/${payload.id}/clear`).then(() => {
        commit("deactivateLicenseKey", true);
        setTimeout(() => {
          commit("deactivateLicenseKey", false);
        }, 200);

      });
    },
    resetDisabledLicenseKeyMessage({ commit }) {
      commit("resetDisabledLicenseKeyMessage");
    },
    resetEnabledLicenseKeyMessage({ commit }) {
      commit("resetEnabledLicenseKeyMessage");
    },
    activateLicenseKey({ commit }, payload) {
      post(`/licenses/${payload.id}/activate`).then((data) => {
        if (data.status === 201) {
          commit("activateLicenseKey", true);

          setTimeout(() => {
            commit("activateLicenseKey", false);
          }, 200);
        }
        else if (data.response.status === 400) {
          commit("getLicenseKeyErrorMessage", data.response.data.message)
        }

      }).catch(() => {
        commit("getLicenseKeyErrorMessage", "Bad request")
      });
    },
    getLicenseKeyStatus({ commit }, payload) {
      get(`/licenses/${payload.id}/storage`).then((data) => {
        commit("getCurrentLicenseKeyStatus", data.data.status);
      }).catch((err) => {
        console.log(err, 'err')
      })
    },
    getSubUserLicense({ commit }, { id, resolve }) {
      get(`/licenses/users/${id}`).then((data) => {
        const licenseId = data.data.items[0].id;
        licenseId && commit("getSubUserLicense", licenseId);
        resolve(licenseId)
        console.log(licenseId, "licenseId")
      }).catch((err) => {
        console.log(err, 'err')
      })
    },
    fetchAllLicenseKeys({ commit }, data) {
      let page = 1;
      let limit = 20;
      commit("loadingAllLicenseKeys", true);

      if (data) {
        if (data?.page) {
          page = data.page;
        }
        if (data?.limit) {
          limit = data.limit;
        }
      }

      let licenseApiEndpoint = `/licenses/?page=${page}&limit=${limit}`;

      get(licenseApiEndpoint)
        .then(({ data }) => {
          commit("updateAllLicenseKeys", data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          commit("loadingAllLicenseKeys", false);
        });
    },
  },
  }


export default licenses;
