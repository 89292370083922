<template>
  <div id="chart" v-if="chatGptData.length && chatGptMonths.length">
    <apexchart :options="options" :series="chatGptData"></apexchart>
  </div>
  
</template>

<script setup>
import Apexchart from "vue3-apexcharts";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

onMounted(() => {
  store.dispatch('fetchChatGptUsage');
});

const chatGptData = computed(() => store.getters.getChatGptData);
const chatGptMonths = computed(() => store.getters.getChatGptMonth);


const options = computed(() => ({
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    background: "#F7F8FC",
  },
  dataLabels: {
    formatter: () => "",
  },
  fill: {
    opacity: 1,
  },
  plotOptions: {
    bar: {
      hideOverflowingLabels: true,
      horizontal: false,
      columnWidth: "23%",
      barHeight: "30%",
      borderRadius: 6,
      borderRadiusWhenStacked: "last",
    },
  },
  xaxis: {
    categories: chatGptMonths.value,
  },
  colors: ["#2A61FF", "#03d670"],
  yaxis: {
    labels: {
      formatter: (val) => {
        if (val == 0) return '0';
        if (val >= 1000000) return (val / 1000000).toFixed(1) + 'M words';
        if (val >= 1000) return (val / 1000).toFixed(1) + 'K words';
        return val + ' words';
      },
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    markers: {
      radius: 12,
    },
  },
}));
</script>

<style lang="scss" scoped>
</style>