import { patch, post, get } from "@/utils";
import { createStore } from "vuex";
import subscriptions from "./modules/subscriptions";
import invitedUsers from "./modules/invitedUsers";
import licenses from "./modules/licenses";
import users from "./modules/users";
import charts from "./modules/chart";
import credits from "./modules/credits";
import products from "./modules/product";
import versions from "./modules/versions";
export default createStore({
  state: {
    globalRole: null,
    globalToken: null,
    /* auth state */
    authToken: null,
    refreshToken: null,
    signInLoading: false,
    signInError: null,
    signUpErrorMessage: null,
    changePassword: {
      success: null,
      errorMessage: null,
    },
    subUserSignInError: null,
    subUserSignInSuccess: null,
    forgotPasswordSendMessage: {
      success: null,
      failure: null,
    },
    resetForgotPasswordMessage: {
      success: null,
      failure: null,
    },

    resetPassword: {
      success: null,
      errorMessage: null
    },
    loginUserPageToken: null,
    loginUserPageError: null,
  },
  getters: {
    getGlobalRole: (state) => state.globalRole,
    getGlobalToken: (state) => state.globalToken,

    /* auth getters */
    getSignUpErrorMessage: (state) => state.signUpErrorMessage,
    getSignInError: (state) => state.signInError,
    getSignInLoading: (state) => state.signInLoading,
    getSubUserSignInError: (state) => state.subUserSignInError,
    getSubUserSignInSuccess: (state) => state.subUserSignInSuccess,
    getAuthToken: (state) => state.authToken,
    getRefreshToken: (state) => state.refreshToken,
    getChangePassword: (state) => state.changePassword,
    getForgotPasswordSendMessage: (state) => state.forgotPasswordSendMessage,
    getResetForgotPasswordMessage: (state) => state.resetForgotPasswordMessage,
    getLoginUserPageToken: (state) => state.loginUserPageToken,
    getLoginUserPageError: (state) => state.loginUserPageError,
    getResetPassword: (state) => state.resetPassword,
  },
  mutations: {
    /* auth mutations */
    signUp: (state, data) => {
      if (data.success) {
        state.signUpErrorMessage = true;
      } else {
        state.signUpErrorMessage = data.message;
      }
    },
    hideSignUpErrorMessage: (state) => {
      state.signUpErrorMessage = null;
    },
    signIn: (state, signInData) => {
      if (signInData.accessToken) {
        state.authToken = signInData.accessToken;
        state.refreshToken = signInData.refreshToken
        state.signInError = null;
      } else {
        state.signInError = signInData.errorMessage;
        state.authToken = null;
      }
    },
    loginUserPage: (state, data) => {
      if (data.loginUserPageError) {
        state.loginUserPageError = data.loginUserPageError;
        state.loginUserPageToken = null;
      } else {
        state.loginUserPageError = false;
        state.loginUserPageToken = data.loginUserPageToken;
      }
    },

    signInSubUser: (state, data) => {
      if (data.failure == false) {
        state.subUserSignInSuccess = true;
      } else if (data.failure == true && !data.message) {
        state.subUserSignInError == "The user email is not unique";
      } else {
        state.subUserSignInError = data.message;
      }
    },
    hideErrorSubUserSignIn: (state) => {
      state.subUserSignInError = null;
      state.subUserSignInSuccess = null;
    },
    logOut: (state) => (state.authToken = null),
    logoutUserPage: (state) => {
      if (localStorage.getItem("globalRole")) {
        localStorage.setItem("vsl-token", state.authToken);
        localStorage.setItem("refresh-token", state.refreshToken)
        localStorage.removeItem("globalRefreshToken");
        localStorage.removeItem("globalToken");
        localStorage.removeItem("globalRole");
      }
    },
    resetAdminLoginasUserPageError: (state) => {
      state.loginUserPageError = null;
    },
    changePassword: (state, data) => {
      if (data.successMessage) {
        state.changePassword.success = true;
        state.changePassword.errorMessage = null;
      } else {
        state.changePassword.success = null;
        state.changePassword.errorMessage = data.errMessage;
      }
    },
    resetAlert: (state) => {
      setTimeout(() => {
        state.changePassword.success = null;
        state.changePassword.errorMessage = null;
      }, 3500);
    },

    forgotPassword: (state, data) => {
      if (data.success) {
        state.forgotPasswordSendMessage.failure = data.message;
      } else {
        state.forgotPasswordSendMessage.success = data.message;
      }
    },
    hideErrorForgotPassword: (state) => {
      state.forgotPasswordSendMessage.failure = null;
      state.forgotPasswordSendMessage.success = null;
    },
    resetForgotPassword: (state, data) => {
      if (data.failure) {
        state.resetForgotPasswordMessage.failure = data.message;
      } else {
        state.resetForgotPasswordMessage.success = data.message;
      }
    },


    /* users */
    resetPassword: (state, data) => {
      if (data.successMessage) {
        state.resetPassword.success = true;
        state.resetPassword.errorMessage = null;
      } else {
        state.resetPassword.success = null;
        state.resetPassword.errorMessage = data.errMessage;
      }
    },
  },
  actions: {
    /* auth actions */
    signUp({ commit }, userData) {
      let signUpData = {};
      post("/auth/sign-up", userData)
        .then((data) => {
          if (data.status == "200" || data.status == "201") {
            signUpData.success = true;
            signUpData.message = null;
          } else if (data.response.status == "409") {
            signUpData.success = false;
            signUpData.message = "The user email is not unique";
          } else {
            signUpData.success = false;
            signUpData.message = data.response.data.message;
          }
        })
        .catch((err) => {
          signUpData.success = false;
          signUpData.message = err.response.data.error;
        })
        .finally(() => {
          commit("signUp", signUpData);
        });
    },
    hideSignUpErrorMessage({ commit }) {
      commit("hideSignUpErrorMessage");
    },
    signIn({ commit, state }, userData) {
      state.signInLoading = true;
      let signInData = {};
      post("/auth/sign-in", {
        email: userData.email,
        password: userData.password,
      })
        .then((response) => {
          if (response.status == 201) {
            localStorage.setItem("vsl-token", response.data.accessToken);
            localStorage.setItem("refresh-token", response.data.refreshToken);
            signInData.accessToken = response.data.accessToken;
            signInData.refreshToken = response.data.refreshToken;
            signInData.errorMessage = null;
          } else {
            signInData.accessToken = null;
            signInData.refreshToken = null;
            signInData.errorMessage =
              typeof response.response.data.message == "string"
                ? response.response.data.message
                : response.response.data.message[0];
          }
        })
        .catch(() => {
          signInData.accessToken = null;
          signInData.refreshToken = null;
          signInData.errorMessage = "Login or password not valid";
        })
        .finally(() => {
          commit("signIn", signInData);
          state.signInLoading = false;
        });
    },
    signInSubUser({ commit }, userData) {
      let usData = {};
      post("/auth/sub-user-registration/", userData)
        .then((data) => {
          if (data.status == 201) {
            usData.failure = false;
            usData.message = null;
          } else {
            usData.failure = true;
            usData.message = null;
          }
        })
        .catch((err) => {
          usData.failure = true;
          usData.message = err.response.data.error;
        })
        .finally(() => {
          commit("signInSubUser", usData);
        });
    },
    hideErrorSubUserSignIn({ commit }) {
      commit("hideErrorSubUserSignIn");
    },
    logOut({ commit }) {
      if (localStorage.getItem("vsl-token")) {
        localStorage.removeItem("vsl-token");
        localStorage.removeItem("globalRole");
        localStorage.removeItem("globalToken");
        localStorage.removeItem("globalRefreshToken");
        localStorage.removeItem("refresh-token");
      }
      commit("logOut");
    },
    logoutUserPage({ commit, state }) {
      if (localStorage.getItem("globalRole")) {
        state.authToken = localStorage.getItem("globalToken");
        localStorage.setItem("vsl-token", state.authToken);
        localStorage.removeItem("globalRole");
        localStorage.removeItem("globalToken");
        localStorage.removeItem("globalRefreshToken");
        get("/users/me")
          .then((response) => {
            if (response.status == 200) {
              commit("updateCurrentUser", response.data);
              commit("logoutUserPage");
            }
          })
          .catch(() => {
            // commit("updateCurrentUser", null);
            // console.log("updateData is failed");
          });
      }
    },
    resetAdminLoginasUserPageError({ commit }) {
      commit("resetAdminLoginasUserPageError");
    },
    changePassword({ commit }, passwordData) {
      let passData = {};
      patch("/auth/password", {
        currentPassword: passwordData.password,
        newPassword: passwordData.newPassword,
        confirmPassword: passwordData.confirmPassword,
      })
        .then((data) => {
          if (data.status == "200") {
            passData.successMessage = true;
            passData.errMessage = null;
          } else {
            let errorMessage =
              typeof data.response.data.message == "object"
                ? data.response.data.message[0]
                : data.response.data.message;
            passData.successMessage = false;
            passData.errMessage = errorMessage;
          }
        })
        .catch(() => {
          passData.successMessage = false;
          passData.errMessage = "Password is to weak";
        })
        .finally(() => {
          commit("changePassword", passData);
        });
    },
    resetAlert({ commit }) {
      commit("resetAlert");
    },
    forgotPassword({ commit }, payload) {
      let forgData = {};
      post("/auth/password-reset-request", { email: payload })
        .then((data) => {
          if (data.status == 201) {
            forgData.message = "Send email";
            forgData.success = false;
          } else {
            forgData.message = data.response.data.message;
            forgData.success = true;
          }
        })
        .catch((err) => {
          forgData.message = err;
          forgData.success = true;
        })
        .finally(() => {
          commit("forgotPassword", forgData);
        });
    },
    hideErrorForgotPassword({ commit }) {
      commit("hideErrorForgotPassword");
    },
    resetForgotPassword({ commit }, payload) {
      let resData = {};
      patch("/auth/password-reset", payload)
        .then((data) => {
          if (data.status) {
            resData.failure = false;
            resData.message = "Your password has been  successfully changed!";
          } else {
            resData.failure = true;
            resData.message = "You have failed to change your password!";
          }
        })
        .catch(() => {
          resData.failure = true;
          resData.message = "You have failed to change your password!";
        })
        .finally(() => {
          commit("resetForgotPassword", resData);
        });
    },
    loginUserPage({ commit }, index) {
      let loginData = {};
      commit("currentUserLoading", true);
      post(`/auth/sign-in/users/${index}`)
        .then((response) => {
          if (response.status == 201) {
            loginData.loginUserPageToken = response.data.accessToken;
            loginData.loginUserPageRefreshToken = response.data.refreshToken;
            loginData.loginUserPageError = null;
            loginData.globalToken = localStorage.getItem("vsl-token");
            loginData.refreshToken = localStorage.getItem("refresh-token")
            localStorage.setItem("globalRole", "Admin");
            localStorage.setItem("globalToken", loginData.globalToken);
            localStorage.setItem("globalRefreshToken", loginData.refreshToken)
            localStorage.setItem("vsl-token", loginData.loginUserPageToken);
            localStorage.setItem("refresh-token", loginData.loginUserPageRefreshToken)
            get("/users/me")
              .then((response) => {
                if (response.status == 200) {
                  commit("updateCurrentUser", response.data);
                  commit("loginUserPage", loginData);
                } else {
                  loginData.loginUserPageToken = null;
                  loginData.loginUserPageError = "Not found";
                  commit("loginUserPage", loginData);
                }
              })
              .catch(() => {
                loginData.loginUserPageToken = null;
                loginData.loginUserPageError = "Not found";
                commit("loginUserPage", loginData);
                // commit("updateCurrentUser", null);
                // console.log("updateData is failed");
              })
              .finally(() => {
                commit("currentUserLoading", false);
              });
          } else {
            loginData.loginUserPageToken = null;
            loginData.loginUserPageError = response.data.response.data.error;
            commit("loginUserPage", loginData);
          }
        })
        .catch(() => {
          loginData.loginUserPageToken = null;
          loginData.loginUserPageError = "Not found";
          commit("loginUserPage", loginData);
          commit("currentUserLoading", false);
        });
    },

    resetUserPassword({ commit }, { userId, newPassword, confirmPassword }) {
      let passData = {};
      return post(`/users/${userId}/reset-password`, {
        newPassword: newPassword,
        confirmPassword: confirmPassword
      })
        .then((response) => {
          if (response.status === 200) {
            passData.successMessage = true;
            passData.errMessage = null;
          } else {
            passData.successMessage = false;
            passData.errMessage = response.data ? response.data.message : 'An error occurred';
          }
        })
        .catch((error) => {
          passData.successMessage = false;
          passData.errMessage = error.response.data ? error.response.data.error : 'An error occurred';
        })
        .finally(() => {
          commit("resetPassword", passData);
        });
    },
    /* users */
  },
  modules: {
    subscriptions,
    invitedUsers,
    licenses,
    users,
    charts,
    credits,
    products,
    versions
  },
});
