import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import DashBoard from "../views/DashBoard.vue";
import LoginPage from "@/views/LoginPage.vue";
import Register from "@/views/RegisterPage.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import BillingUser from "@/views/BillingUser.vue";
import Integrations from "@/views/IntegrationsProfile.vue";
import Contact from "@/views/MyContact.vue";
import ChangePassword from "@/views/ChangePassword";
import SubUsersPage from "@/views/SubUsersPage";
import ResetForgotPasswordPage from "@/views/ResetForgotPasswordPage";
import DeactivateProfilePage from "@/views/DeactivateProfilePage";
import DeactivateUserLicenseKeyPage from "@/views/DeactivateUserLicenseKeyPage";
import InvitationRequest from "@/views/InvitationRequest";
import PurchaseNewSubscribtion from "@/views/PurchaseNewSubscribtion.vue"
import TheDefaultLayout from "@/layouts/TheDefaultLayout";
import TheLoginLayout from "@/layouts/TheLoginLayout";


const routes = [
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      layout: TheLoginLayout,
    },
  },
  {
    path: "/change-password",
    component: ChangePassword,
    meta: {
      auth: true,
      layout: TheDefaultLayout
    },
  },
  {
    path: "/",
    component: DashBoard,
    meta: {
      auth: true,
      layout: TheDefaultLayout

    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      auth: false,
      layout: TheLoginLayout,
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      auth: false,
      layout: TheLoginLayout,
    },
  },
  {
    path: "/reset-password",
    name: "resetpassword",
    component: ResetPassword,
    meta: {
      auth: false,
      layout: TheLoginLayout,
    },
  },
  {
    path: "/dashboard",
    name: "DashBoard",
    component: DashBoard,
    meta: {
      auth: true,
      layout: TheDefaultLayout

    },
  },

  {
    path: "/billing",
    name: "billing",
    component: BillingUser,
    meta: {
      auth: true,
      layout: TheDefaultLayout

    },
  },
  {
    path: "/integrations",
    name: "integrations",
    component: Integrations,
    meta: {
      auth: true,
      layout: TheDefaultLayout,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      auth: true,
      layout: TheDefaultLayout,
    },
  },
  {
    path: "/users",
    name: "subUsers",
    component: SubUsersPage,
    meta: {
      auth: true,
      layout: TheDefaultLayout,

    },
  },
  {
    path: "/invitation-request",
    name: "invitation-request",
    component: InvitationRequest,
    meta: {
      auth: false,
      layout: TheLoginLayout,
    },
  },
  {
    path: "/reset-password-request",
    name: "reset-password-request",
    component: ResetForgotPasswordPage,
    meta: {
      auth: false,
      layout: TheLoginLayout,
    },
  },
  {
    path: "/deactivate-profile-page",
    name: "DeactivateProfilePage",
    component: DeactivateProfilePage,
    meta: {
      auth: false,
      layout: TheLoginLayout,
    },
  },
  {
    path: "/deactivate-user-license-page",
    name: "DeactivateUserLicenseKeyPage",
    component: DeactivateUserLicenseKeyPage,
    meta: {
      auth: false,
      layout: TheLoginLayout,
    },
  },
  {
    path: "/purchase-new-subscription",
    name: "PurchaseNewSubscribtion",
    component: PurchaseNewSubscribtion,
    meta: {
      auth: false,
      layout: TheLoginLayout,
    },
  },];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!localStorage.getItem("vsl-token")) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    if (localStorage.getItem("vsl-token")) {
      if (to.name === "login" || to.name === "register" || to.name === "/") {
        next({ path: "/login" });
      }
    }
    next();
  }
});
export default router;
