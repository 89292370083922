<template>
  <div class="d-flex align-items-center container-fluid loadingStart">
    <!-- <strong>Loading...</strong> -->
    <div class="spinner-border" role="status" aria-hidden="true"></div>
  </div>
</template>

<style>
.loadingStart {
  justify-content: flex-start !important;
}
.spinner-border {
  margin-left: 50%;
  color: blue;
  position: unset !important;
}
</style>
