import { get, post, patch } from "@/utils";

function getDateRange() {
  const now = new Date();
  const fromDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
  const toDate = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0];
  return { fromDate, toDate };
}

const credits = {
  state: () => ({
    extraCredit: null,
    creditUsage: null,
    creditUpgradeSuccess: null,
    creditUpgradeFailure: null,
    userData: {},
    chatGptUsage: null,
    deepGramUsage: null,
    userCredits: {},
    pendingExtraCredits: {}
  }),
  getters: {
    getExtraCredit: (state) => state.extraCredit,
    getCreditUsage: (state) => state.creditUsage,
    getCreditUpgradeSuccess: (state) => state.creditUpgradeSuccess,
    getCreditUpgradeFailure: (state) => state.creditUpgradeFailure,
    getUserData: (state) => (userId) => {
      return state.userData.find(user => user.id === userId);
    },
    getUserCredits: (state) => (userId) => {
      return state.userCredits[userId] || {};
    },
    getChatGptUsageThisMonth: (state) => {
      if (!state.chatGptUsage) return "N/A";
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear().toString();
      const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      
      const baseUsage = state.chatGptUsage.baseCredit.find(item => item.year === currentYear && item.month === currentMonth);
      // const extraUsage = state.chatGptUsage.extraCredit.find(item => item.year === currentYear && item.month === currentMonth);
      
      return (parseFloat(baseUsage?.totalScryptGenerator) || 0) ;
    },
    getDeepGramUsageThisMonth: (state) => {
      if (!state.deepGramUsage) return "N/A";
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear().toString();
      const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      
      const baseUsage = state.deepGramUsage.baseCredit.find(item => item.year === currentYear && item.month === currentMonth);
      // const extraUsage = state.deepGramUsage.extraCredit.find(item => item.year === currentYear && item.month === currentMonth);
      
      const totalSeconds = (parseFloat(baseUsage?.totalAudioText) || 0);
      return (totalSeconds / 3600).toFixed(2);
    },
    getPendingExtraCredits: (state) => (userId) => {
      return state.pendingExtraCredits[userId] || null;
    },
    
  },
  actions: {
    async fetchExtraCredit({ commit }) {
      try {
        const response = await get("/credits");
        console.log("Credits API Response:", response.data); // Log the data
        commit("SET_CREDIT", response.data);
      } catch (error) {
        console.error("Error fetching extra credit:", error);
      }
    },
    async fetchCreditUsage({ commit }) {
      const response = await get("/credits/usage");
      commit("SET_CREDIT_USAGE", response.data);
      
    },
    async fetchChatGptUsage({ commit }) {
      const { fromDate, toDate } = getDateRange();
      const response = await get("/credits/usage/chat-gpt", { params: { fromDate, toDate } });
      commit("SET_CHAT_GPT_USAGE", response.data);
      console.log(response.data)
    },
    async fetchDeepGramUsage({ commit }) {
      try {
        const { fromDate, toDate } = getDateRange();
        const response = await get("/credits/usage/deep-gram", { params: { fromDate, toDate } });
        commit("SET_DEEP_GRAM_USAGE", response.data);
        
      } catch (error) {
        console.error('Error fetching Deepgram usage:', error);
        commit("SET_DEEP_GRAM_USAGE", null);
      }
    },
    async getUserCredits({ commit }, userId) {
      try {
        const response = await get(`/credits/user/${userId}`);
        commit('SET_USER_CREDITS', { userId, credits: response.data });
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching user credits:', error);
      }
    },
    updateUserExtraCredits({ commit }, { userId, extraCredits }) {
     
      commit('UPDATE_EXTRA_CREDITS', { userId, extraCredits });
    },

    async addExtraCredits({ commit }, { productId, quantity }) {
      try {
        const response = await post("/payment/extra-credits", {
          productId,
          quantity,
        
        });
        commit("SET_CREDIT", response.data);
        return { success: true, data: response.data };
      } catch (error) {
        console.error("Error adding extra credits:", error);
        return { success: false, error: error.message };
      }
    },
    async setAutoRefillCredits({ commit }, { productId, quantity }) {
      try {
        const response = await post("/payment/auto-refill-extra-credits", {
          productId,
          quantity
        });
        commit("SET_AUTO_REFILL", response.data);
        return { success: true, data: response.data };
      } catch (error) {
        console.error("Error setting auto-refill credits:", error);
        return { success: false, error: error.message };
      }
    },
    async addExtraUserCredits({ commit }, { userId, audioText, scryptGenerator }) {
      try {
        const response = await patch(`/credits/user/${userId}`, { audioText, scryptGenerator });
        commit('UPDATE_USER_CREDITS', { userId, credits: response.data });
        return response.data;
      } catch (error) {
        console.error('Error adding extra credits:', error);
        throw error;
      }
    },
  },
  mutations: {
    SET_CREDIT(state, data) {
      state.extraCredit = data;
    },
    SET_CREDIT_USAGE(state, data) {
      state.creditUsage = data;
    },
    SET_CHAT_GPT_USAGE(state, data) {
      state.chatGptUsage = data;
    },
    SET_DEEP_GRAM_USAGE(state, data) {
      state.deepGramUsage = data;
    },
    SET_USER_CREDITS(state, { userId, credits }) {
      state.userCredits = {
        ...state.userCredits,
        [userId]: credits,
      };
    },
    UPDATE_EXTRA_CREDITS(state, { userId, extraCredits }) {
      if (state.userCredits[userId]) {
        state.userCredits[userId].extraCredit = extraCredits;
      }
    },
    UPGRADE_CREDITS(state, data) {
      if (data.failure) {
        state.creditUpgradeFailure = data.message;
        state.creditUpgradeSuccess = null;
      } else {
        state.creditUpgradeSuccess = data.message;
        state.creditUpgradeFailure = null;
        state.extraCredit = data.extraCredit;
        state.userData = data.userData;
      }
    },
    UPDATE_USER_CREDITS(state, { userId, credits }) {
      const updatedCredits = {
        ...credits,
        extraCredit: {
          ...credits.extraCredit,
          manuallyAddedExtraCredit: {
            audioText: (credits.extraCredit?.manuallyAddedExtraCredit?.audioText || 0) + (credits.extraCredit?.audioText || 0),
            scryptGenerator: (credits.extraCredit?.manuallyAddedExtraCredit?.scryptGenerator || 0) + (credits.extraCredit?.scryptGenerator || 0)
          }
        }
      };
      
      state.userCredits = {
        ...state.userCredits,
        [userId]: updatedCredits,
      };
    },
    SET_AUTO_REFILL(state, data) {
      state.autoRefill = data;
    },
    SET_PENDING_EXTRA_CREDITS(state, { userId, credits }) {
      state.pendingExtraCredits[userId] = credits;
    },
    CLEAR_PENDING_EXTRA_CREDITS(state, userId) {
      delete state.pendingExtraCredits[userId];
    },
  },
};

export default credits;
