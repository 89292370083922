import { get } from "@/utils";

const invitedUsers = {
  state: () => ({
    invitedSubUsers: [],
    invitedSubUsersLoading: false,
    invitedSubUsersErrorMessage: null,
  }),
  getters: {
    getInvitedSubUsers: (state) => state.invitedSubUsers,
    getInvitedSubUsersErrorMessage: (state) =>
      state.invitedSubUsersErrorMessage,
    getInvitedSubUsersLoading: (state) => state.invitedSubUsersLoading,
  },
  mutations: {
    getInvitedSubUsers: (state, data) => {
      if (data.errorMessage) {
        state.invitedSubUsersErrorMessage = data.errorMessage;
      } else {
        state.invitedSubUsers = data;
      }
    },
    getInvitedSubUsersParentData: (state, data) => {
      if (data.errorMessage) {
        state.invitedSubUsersErrorMessage = data.errorMessage;
      } else if (state.invitedSubUsers.items.length > 0) {
        if (typeof data.index == "number") {
          state.invitedSubUsers.items[data.index].licenseKey = data.licenseKey;
          state.invitedSubUsers.items[data.index].licenseKeyStatus = data.licenseKeyStatus;
        } else {
          state.invitedSubUsers.items[data.index].licenseKey = null;
          state.invitedSubUsers.items[data.index].licenseKeyStatus = 0;

        }
      }
    },
    getInvitedSubUsersLoading: (state, bool) => {
      state.invitedSubUsersLoading = bool;
    },
  },
  actions: {
    getInvitedSubUsers({ commit }, payload) {
      commit("getInvitedSubUsersLoading", true);
      let p = payload;
      if (!p.limit) {
        p.limit = 10;
      }
      if (!p.page) {
        p.page = 1;
      }
      let query = `/invited-user/`;
      //&status=PENDING
      get(query)
        .then(async (response) => {
          if (response.status == 200) {
            let resData = await response.data;
            commit("getInvitedSubUsers", response.data);
            if (resData.items.length > 0) {
              resData.items.forEach((item, index) => {
                get(`/licenses/${item.licenseKeyId}/storage`)
                  .then(({ data }) => {
                    console.log(data, "data")

                    commit("getInvitedSubUsersParentData", {
                      licenseKey: data.licenseKey,
                      index,
                      errorMessage: false,
                      licenseKeyStatus: data.status
                    });
                  })
                  .catch(() => {
                    commit("getInvitedSubUsersParentData", {
                      licenseKey: null,
                      index,
                      errorMessage: false,
                    });
                  })
                  .finally(() => {
                    commit("getInvitedSubUsersLoading", false);
                    // get(`/users/${item.invitedUserId}`)
                    //   .then((data) => {
                    //     console.log(data,'data')
                    //   })
                    //   .catch(() => {})
                    //   .finally(() => {
                    //     commit("getInvitedSubUsersLoading", false);

                    //   });

                  });
              });
            } else {
              // console.log();
            }
          } else {
            commit("getInvitedSubUsers", {
              licenseKey: null,
              index: null,
              errorMessage: "Error message",
            });
          }
        })
        .catch(() => {
          commit("getInvitedSubUsers", {
            licenseKey: null,
            index: null,
            errorMessage: "Error message",
          });
        })
        .finally(() => {
          commit("getInvitedSubUsersLoading", false);
        });
    },
  },
};
export default invitedUsers;
