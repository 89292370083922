<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col" v-for="(title, index) in props.data" :key="index">
          {{ title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(user, index) in allUsersItems" :key="user.id">
        <td>
          {{
            `${user.invitedUser.firstName ? user.invitedUser.firstName : ""} ${
              user.invitedUser.lastName ? user.invitedUser.lastName : ""
            }`
          }}
        </td>
        <td>{{ user.invitedUser.email }}</td>
        <td>{{ invitationUserDate(user.createdAt) }}</td>
        <td>{{ user.invitedUser.status }}</td>
        <td>{{ user.licenseKey ?  user.licenseKey : "Expired"}}</td>
        <td>{{ user.status == "INVALID" ? "Expired" : user.status }}</td>
        <td class="table__body--actions">
          <template v-if="user.status === 'PENDING'">
            <UtilsIcon
              id="pencil1"
              name="pencil"
              width="19"
              height="19"
              color="red"
            ></UtilsIcon>
          </template>
          <template v-else-if="user.status === 'ACCEPTED'">
            <UtilsIcon
              v-if="user.invitedUser.password !== null"
              data-bs-placement="top"
              title="Tooltip on top"
              id="pencil1"
              name="pencil"
              width="19"
              height="19"
              color="#CACFDF"
              data-bs-toggle="modal"
              data-bs-target="#editSubUserModal"
              @click="() => editSubUser(index)"
            ></UtilsIcon>
          </template>
          <template v-else-if="user.status === 'DEACTIVATED'"></template>
          <UtilsIcon
            v-if="user.status === 'ACCEPTED'"
            name="recycle"
            width="19"
            height="19"
            color="#CACFDF"
            data-bs-toggle="modal"
            data-bs-target="#staticDefaultModal"
            @click="getLicenseKeyId(user.licenseKeyId)"
            id="recycle"
          ></UtilsIcon>
          <UtilsIcon
            v-else-if="
              user.status === 'INVALID' ||
              user.status === 'PENDING'
            "
            id="recycle3"
            name="recycle"
            width="19"
            height="19"
            color="red"
            data-bs-toggle="modal"
            data-bs-target="#staticDefaultModal1"
            @click="getLicenseKeyId(user.licenseKeyId)"
          ></UtilsIcon>
          <!-- <UtilsIcon
            v-if="
              user.status === 'ACCEPTED' && user.invitedUser.password !== null
            "
            id="pencil"
            name="pencil"
            width="19"
            height="19"
            color="#CACFDF"
            data-bs-toggle="modal"
            data-bs-target="#editSubUserModal"
            @click="() => editSubUser(index)"
          ></UtilsIcon>
          <UtilsIcon
            v-else
            id="pencil3"
            name="pencil"
            width="19"
            height="19"
            color="red"
          ></UtilsIcon>
          <template v-if="user.licenseKeyId">
            <UtilsIcon
              v-if="user.licenseKeyStatus === 1"
              name="recycle"
              width="19"
              height="19"
              color="#CACFDF"
              data-bs-toggle="modal"
              data-bs-target="#staticDefaultModal"
              @click="getLicenseKeyId(user.licenseKeyId)"
              id="recycle"
            ></UtilsIcon>
            <UtilsIcon
              v-else-if="user.licenseKeyStatus === 0"
              id="recycle3"
              name="recycle"
              width="19"
              height="19"
              color="red"
              data-bs-toggle="modal"
              data-bs-target="#staticDefaultModal1"
              @click="getLicenseKeyId(user.licenseKeyId)"
            ></UtilsIcon>
          </template> -->
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import UtilsIcon from "@/components/utils/UtilsIcon.vue";
const props = defineProps({
  allUsersItems: Array,
  data: Array,
});
const emits = defineEmits(["editSubUser", "getLicenseKeyId"]);
const editSubUser = (index) => {
  emits("editSubUser", index);
};
const getLicenseKeyId = (id) => {
  emits("getLicenseKeyId", id);
};

const invitationUserDate = (date) => {
  let formattedDate;

    const dateObject = new Date(date)
    
    const day = dateObject.getUTCDate().toString().padStart(2, '0');
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getUTCFullYear().toString().slice(-2);
    
    formattedDate = `${day}/${month}/${year}`;

    return formattedDate
};
</script>

<style lang="scss" scoped>
.container-fluid {
  margin: 0;
  text-align: left;
}
.table {
  margin-top: 23px;
  padding: 0 36px;
  background-color: #ffffff;
  padding: 0 0 0 15px !important;
  margin: 0 !important;
  border-left: 0;

  thead {
    th {
      word-break: break-all;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      padding: 15px 5px;
    }
  }
  tbody {
    td {
      word-break: break-all;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: #333333;
      padding: 15px 5px;
    }
  }
  &__head {
    &--title {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      height: 64px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    &--actions {
      display: flex;
      min-height: 55.5px;
      z-index: 1000;
      flex-direction: row;
      gap: 34px;
      position: sticky !important;
      right: -145px !important;

      background-color: white;
      div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }
  }
  .row {
    padding: 0 12px;
    align-items: center;
    height: 64px;
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
.tableAdmin {
  border-radius: 10px;
  thead,
  tbody tr {
    display: table;
    width: calc(3500px - 1em);
    table-layout: fixed;
  }

  margin-top: 23px;
  background-color: #ffffff;
  margin: 0 !important;

  thead {
    width: calc(3500px - 1em);

    th {
      word-break: break-all;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      padding: 15px 5px;
    }
  }
  tbody {
    width: calc(3500px - 1em);
    tr {
      border-bottom: 1px solid #e9ecf4;
    }
    td {
      word-break: break-all;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: #333333;
      padding: 15px 5px;
      border-bottom: none !important;
    }
  }
  &__head {
    &--title {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      height: 64px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    &--actions {
      display: flex;
      min-height: 55.5px;
      z-index: 1000;
      flex-direction: row;
      gap: 34px;
      position: sticky !important;
      right: -145px !important;
      position: sticky !important;
      right: -145px !important;
      background-color: white;
      div {
        cursor: pointer;
      }
    }
  }
  .row {
    padding: 0 12px;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #e9ecf4;
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
.adminTableBox {
  border-radius: 10px;
  border: 1.5px solid #e9ecf4;
  background-color: #fff;
  padding: 0 15px 15px 15px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
  gap: 10px;
  li {
    a {
      border-radius: 8px;
    }
  }
}

.disableLink {
  color: gray !important;
}
.enableeLink {
  color: #0a58ca !important;
}

#pencil:hover,
#profile:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(14%) saturate(3425%)
    hue-rotate(190deg) brightness(94%) contrast(90%);
}
#recycle:hover {
  filter: brightness(0) saturate(100%) invert(29%) sepia(98%) saturate(7173%)
    hue-rotate(356deg) brightness(103%) contrast(118%);
}
::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px; /* width of vertical scrollbar */
  border: 1px solid #a3adcd;
  background: #e9ecf4;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px !important;
  background-color: #a3adcd;
  cursor: pointer;
}
td:hover {
  overflow: visible;
  z-index: 100;
  backdrop-filter: blur(5px);
}
.sticky {
  position: sticky;
  right: -145px;
  background-color: white;
}
</style>