<template>
  <div class="login-layout">
    <div class="login-layout_pages">
      <slot name="login"></slot>
    </div>
  </div>
</template>
<script setup>
</script>

