<template>
  <div
    class="modal fade"
    id="staticBackdrop5"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel5"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel5">
            Invite Sub-user
          </h1>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input
              v-model.trim="createUserData.firstName"
              @input="
                () => validateInput(createUserData.firstName, 'firstName')
              "
              :class="
                successMesages.firstName
                  ? 'successBorder'
                  : errorMessages.firstName
                  ? 'errorBorder'
                  : ''
              "
              id="firstName"
              type="text"
              class="form-control"
            />
            <span v-show="errorMessages.firstName" class="errorMessage">{{
              errorMessages.firstName
            }}</span>
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input
              v-model.trim="createUserData.lastName"
              @input="() => validateInput(createUserData.lastName, 'lastName')"
              id="lastName"
              type="text"
              class="form-control"
              :class="
                successMesages.lastName
                  ? 'successBorder'
                  : errorMessages.lastName
                  ? 'errorBorder'
                  : ''
              "
            />
            <span v-show="errorMessages.lastName" class="errorMessage">{{
              errorMessages.lastName
            }}</span>
          </div>
          <div class="form-group">
            <label for="email">Email Address</label>
            <input
              v-model="createUserData.email"
              @input="() => validateEmail(createUserData.email)"
              id="email"
              type="email"
              :class="
                successMesages.email
                  ? 'successBorder'
                  : errorMessages.email
                  ? 'errorBorder'
                  : ''
              "
              class="form-control"
            />
            <span v-show="errorMessages.email" class="errorMessage">{{
              errorMessages.email
            }}</span>
          </div>
          <div class="form-group">
            <label for="selectLicenseKey">Main License Status</label>
            <select
              name="licenseKeys"
              id="selectLicenseKey"
              class="form-select"
              aria-label="Default select example"
              @change="onChange"
            >
              <option hidden active value="">Choose license key</option>
              <option
                v-for="licensekey in licenseKeys"
                :key="licensekey.id"
                :value="licensekey.id"
              >
                {{ licensekey.licenseKey }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline cancelButton"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            v-if="
              successMesages.email &&
              successMesages.firstName &&
              successMesages.lastName &&
              successMesages.selectKey
            "
            type="submit"
            class="btn createButton"
            data-bs-dismiss="modal"
            @click="
              () => {
                emits('createSubUser', createUserData);
                createUserData.email = '';
                createUserData.firstName = '';
                createUserData.lastName = '';
                successMesages.email = false;
                successMesages.firstName = false;
                successMesages.lastName = false;
              }
            "
          >
            Create
          </button>
          <button v-else disabled type="submit" class="btn disabledButton">
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "@vue/reactivity";
import { ref, defineEmits, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();
const licenseKeys = computed(() => {
  return store.getters.getLicenseKeys?.items.filter(
    (item) => item.status !== "IS_USED" || item.status !== "PENDING"
  );
});

const emits = defineEmits(["createSubUser"]);
/* eslint-disable */
var validRegex = ref(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
const createUserData = ref({
  firstName: "",
  lastName: "",
  email: "",
  selectId: "",
});

const errorMessages = ref({
  firstName: "",
  lastName: "",
  email: "",
});

const successMesages = ref({
  firstName: false,
  lastName: false,
  email: false,
  selectKey: false,
});

function validateEmail(email) {
  if (validRegex.value.test(email)) {
    successMesages.value.email = true;
    errorMessages.value.email = "";
  } else {
    errorMessages.value.email = "Invalid Email";
    successMesages.value.email = false;
  }
}

function validateInput(string, name) {
  if (string.length > 2) {
    successMesages.value[name] = true;
    errorMessages.value[name] = "";
  } else {
    successMesages.value[name] = false;
    if (name == "firstName") {
      errorMessages.value[name] = `First Name is not valid !`;
    } else if (name == "lastName") {
      errorMessages.value[name] = `Last Name is not valid !`;
    }
  }
}

function onChange(e) {
  if (e.target.value != "") {
    successMesages.value.selectKey = true;
    createUserData.value.selectId = e.target.value;
  } else {
    successMesages.value.selectKey = false;
  }
}

onBeforeMount(() => {
  createUserData.value = {
    firstName: "",
    lastName: "",
    email: "",
    selectId: "",
  };
  errorMessages.value = {
    firstName: "",
    lastName: "",
    email: "",
  };

  successMesages.value = {
    firstName: false,
    lastName: false,
    email: false,
    selectKey: false,
  };
});

onMounted(() => {
  store.dispatch("updateLicenseKeys", { page: 1, limit: 50, status: "VALID" });
});
</script>

<style lang="scss" scoped>
.modal-dialog {
  top: 150px;
}
.modal-content {
  width: 536px;
  padding: 24px 36px;
}
.modal-header {
  padding: 0 0 15px 0;
}
.modal-title {
  font-size: 20px !important;
  font-weight: 400;
  color: #a3adcd;
}
.modal-body {
  padding: 0;
}
label {
  justify-content: flex-start;
  display: flex;
  margin: 20px 0 8px;
}
.cancelButton {
  background-color: #ebedf3 !important;
  color: #333333;
}
.createButton:hover {
  background-color: #69c900 !important;
  color: #ffffff !important;
}
.createButton {
  background-color: #fff !important;
  color: #69c900 !important;
  border: 1px solid #69c900;
}
.modal-footer {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4%;
  width: 100%;
  margin: 28px 0 0 0;
  padding: 0;
  button {
    margin: 0;
    padding: 6px 0;
    width: 48%;
  }
}

.successBorder {
  border: 1px solid #69c900 !important;
}
.errorBorder {
  border: 1px solid #ff0000 !important;
}
.errorMessage {
  color: #f00;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
}

input:focus {
  box-shadow: none !important;
}
.disabledButton {
}
</style>
