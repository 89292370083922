<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="box">
            <div class="login-acct">
              <div class="logo">
                <img src="../assets/_logo1.png" alt="supportive" />
              </div>
              <h3>Change your EasyVSL password</h3>
              <p v-if="errors?.length">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li
                    v-for="error in errors"
                    :key="error"
                  ><span class="error">{{ error }}</span></li>
                </ul>
              </p>
              <div class="form">
                <input
                  id="exampleInputEmail1"
                  v-model="password"
                  type="password"
                  class="form-control"
                  placeholder="Current password"
                />

                <input
                  id="exampleInputEmail2"
                  v-model="newPassword"
                  type="password"
                  class="form-control"
                  placeholder="New password"
                />
                <input
                  id="exampleInputEmail3"
                  v-model="confirmPassword"
                  type="password"
                  class="form-control"
                  placeholder="Confirm password"
                />
                <button
                  type="submit"
                  class="submitButton"
                  @click.prevent="changePassword"
                >
                  Save
                </button>
              </div>

              <div class="account text-start">
                <div class="bottom-part">
                  <h6>Don't have an account?</h6>
                  <router-link to="/register" exact
                    >Create an account</router-link
                  >
                </div>
                <div class="bottom-right">
                  <router-link to="/reset-password" exact
                    >Forgot Password?</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  <script setup>
import { ref } from "vue";

import { useStore } from "vuex";

const store = useStore();

const password = ref();
const newPassword = ref();
const confirmPassword = ref();
const errors = ref();
function changePassword() {
    errors.value = [];
    if (!password.value) {
      errors.value.push("Current password is required");
    }
    if (!newPassword.value) {
      errors.value.push("New password is required");
    }
    if (!confirmPassword.value) {
      errors.value.push("Confirm password is required");
    }
    if (
      password.value &&
      newPassword.value &&
      confirmPassword.value &&
      newPassword.value !== confirmPassword.value
    ) {
      errors.value.push("Password and Confirm password not match");
    }
    if (
      password.value &&
      newPassword.value &&
      confirmPassword.value &&
      newPassword.value === confirmPassword.value
    ) {
      store.dispatch('changePassword',{password:password.value,newPassword:newPassword.value,confirmPassword:confirmPassword.value})
    }
}
</script>