<template>
  <section v-if="role == 'Admin'" class="admin">
    <div class="admin__users">
      <div class="admin__users--title">
        <div>
          <UtilsIcon
            name="admin"
            width="36"
            height="21"
            color="#A3ADCD"
          ></UtilsIcon>
          <span>Admin</span>
        </div>
        <div class="line" />
      </div>
      <div class="container-fluid admin__users--dashboard dashboard">
        <div class="row justify-content-between">
          <DashboardSummary type="Admin"></DashboardSummary>
          <div
            class="col-sm-12 col-md-12 col-lg-6  dashboard__subscribe"
          >
            <div class="col-12 dashboard__subscripe--box">
              <div class="summaryTitle">
                All subscription plans by subscribers
              </div>
              <div class="form-group chartFilter">
                <div class="chartFilter__box">
                  <VueDatePicker
                    class="datePicker"
                    v-model="subscriptionDate"
                    placeholder="Date range"
                    range
                    :hide-navigation="['time']"
                    :enable-time-picker="false"
                  >
                    <template #input-icon v-if="!subscriptionDate">
                      <img
                        class="input-slot-image"
                        src="../assets//arrowDown.png"
                      />
                    </template>
                    <template #action-row="{ selectDate, closePicker }">
                      <div class="action-row">
                        <button
                          class="select-button btn btn-light"
                          @click="closePicker"
                        >
                          Reset
                        </button>
                        <button
                          class="select-button btn btn-outline-primary"
                          @click="selectDate"
                        >
                          Done
                        </button>
                      </div>
                    </template>
                  </VueDatePicker>
                </div>
              </div>
              <SubscriptionChart
                :chartLoading="chartLoading"
                :chartErrorMessage="chartErrorMessage"
                :subscriptionChartInfo="subscriptionChartInfo"
              ></SubscriptionChart>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid admin__users--charts charts">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-5">
        <div class="col-12">
          <div class="summaryTitle">ChatGPT Credit Usage</div>
        </div>
        <div class="gpt-date_container">
          <VueDatePicker
            v-model="gptDate"
            placeholder="Date range"
            range
            :hide-navigation="['time']"
            :enable-time-picker="false"
            class="gpt-date"
            @update:model-value="updateChatGptChart"
          >
            <template #input-icon v-if="!gptDate">
              <img
                class="input-slot-image"
                src="@/assets/arrowDown.png"
                alt=""
              />
            </template>

            <template #action-row="{ selectDate, closePicker }">
              <div class="action-row">
                <button
                  class="select-button btn btn-light"
                  @click="resetChatGptDate(closePicker)"
                >
                  Reset 
                </button>
                <button
                  class="select-button btn btn-outline-primary"
                  @click="selectDate"
                >
                  Done
                </button>
              </div>
            </template>
          </VueDatePicker>
        </div>
        <ChatGptChart :userId="currentUserId" :dateRange="gptDate"></ChatGptChart>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-5 offset-lg-1">
        <div class="col-12">
          <div class="summaryTitle">DeepGram Credit Usage</div>
          <div class="deep-date_container">
            <VueDatePicker
              v-model="deepDate"
              placeholder="Date range"
              range
              :hide-navigation="['time']"
              :enable-time-picker="false"
              class="deep-date"
              @update:model-value="updateDeepGramChart"
            >
              <template #input-icon v-if="!deepDate">
                <img
                  class="input-slot-image"
                  src="@/assets/arrowDown.png"
                  alt=""
                />
              </template>
      
              <template #action-row="{ selectDate, closePicker }">
                <div class="action-row">
                  <button
                    class="select-button btn btn-light"
                    @click="resetDeepGramDate(closePicker)"
                  >
                    Reset 
                  </button>
                  <button
                    class="select-button btn btn-outline-primary"
                    @click="selectDate"
                  >
                    Done
                  </button>
                </div>
              </template>
            </VueDatePicker>
          </div>
          <DeepGramChart :userId="currentUserId" :dateRange="deepDate"></DeepGramChart>
        </div>
      </div>
    </div>

  </div>
  <div>
    <div class="d-flex px-3 py-4 align-items-center justify-content-start gap-4">
      <p class="mb-0">
        Current Version: <span class="version-span">{{ currentVersion }}</span>
      </p>
      <button class="version-update-btn" @click="openUpdateModal">Update</button>
    </div>
    
    <VersionUpdateModal />
  </div>

      <div class="container-fluid admin__users--filters filters">
        <div class="filters__row mt-4">
          <div class="filters__row--searchInput">
            <label for="search" class="searchInput">
              <input
                type="text"
                placeholder="Search"
                class="form-control"
                id="search"
                name="search"
                v-model="searchData"
              />
              <div>
                Search by email address, full name, transaction id or
                subscription
              </div>
            </label>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.11111 15.2222C12.0385 15.2222 15.2222 12.0385 15.2222 8.11111C15.2222 4.18375 12.0385 1 8.11111 1C4.18375 1 1 4.18375 1 8.11111C1 12.0385 4.18375 15.2222 8.11111 15.2222Z"
                stroke="#CACFDF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.9995 16.9995L13.1328 13.1328"
                stroke="#CACFDF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="filters__row--productSelect">
            <VueMultiselect
              v-model="value"
              :options="options"
              :multiple="true"
              :close-on-select="false"
              placeholder="Product types"
              label="name"
              :searchable="false"
              track-by="name"
            >
              <template #caret="{ toggle }">
                <UtilsIcon
                  class="caretArrowDown"
                  @mousedown.prevent.stop="toggle()"
                  name="arrowDown"
                  width="16"
                  height="16"
                ></UtilsIcon>
              </template>
            </VueMultiselect>
          </div>
          <div class="filters__row--roleSelect">
            <VueMultiselect
              v-model="roleValue"
              :options="roleOptions"
              :multiple="false"
              :searchable="false"
              :close-on-select="false"
              placeholder="Role"
              label="title"
              track-by="title"
            >
              <template #caret="{ toggle }">
                <UtilsIcon
                  class="caretArrowDown"
                  @mousedown.prevent.stop="toggle()"
                  name="arrowDown"
                  width="16"
                  height="16"
                ></UtilsIcon> </template
            ></VueMultiselect>
          </div>
          <div class="filters__row--datePicker">
            <VueDatePicker
              v-model="fromToFilterDate"
              placeholder="Date range"
              range
              :hide-navigation="['time']"
              :enable-time-picker="false"
              >
              <!-- month-picker -->
              <template #input-icon v-if="!fromToFilterDate">
                <img
                  class="input-slot-image"
                  src="../assets//arrowDown.png"
                  alt=""
                />
              </template>

              <template #action-row="{ selectDate, closePicker }">
                <div class="action-row">
                  <button
                    class="select-button btn btn-light"
                    @click="closePicker"
                  >
                    Reset 
                  </button>
                  <button
                    class="select-button btn btn-outline-primary"
                    @click="selectDate"
                  >
                    Done
                  </button>
                </div>
              </template>
            </VueDatePicker>
          </div>
          <div class="filters__row--button">
            <button
              class="btn btn-outline-primary"
              style="width: 100%"
              @click="resetBtn"
            >
              Reset
            </button>
          </div>
          <div class="filters__row--button">
            <button
              class="btn btn-outline-primary"
              style="width: 100%"
              @click="applyFilter"
            >
              Apply
            </button>
          </div>
        </div>
      </div>

      <div class="admin__users--content">
        <TableLoader v-if="allUsersLoading" class="loading"></TableLoader>
        <div
          class="admin__users--content__table"
          v-else-if="allUsersItems?.length > 0"
        >
          <UsersCustomTable
            :data="tableData.titles"
            :allUsers="allUsers"
            type="Admin"
            @deactivateLicenseKeySubUser="disabledLicenseKey"
            @editSubUser="editSubUser"
            @getUsersPerPage="getUsersPerPage"
            @editUserStatus="editUserStatus"
            @loginUserPage="loginUserPage"
          ></UsersCustomTable>
        </div>
        <div v-else class="noData">
          <UtilsIcon name="noData" width="131" height="174"></UtilsIcon>
        </div>
      </div>
    </div>
  </section>

  <section v-if="role == 'User'" class="user">
    <div class="user__subUsers">
      <div class="user__subUsers--title flex-md-row flex-column justify-content-between">
        <div>
          <UtilsIcon
            name="users"
            width="36"
            height="21"
            color="#A3ADCD"
          ></UtilsIcon>
          <span>User</span>
        </div>
        <div class="user__subUsers--subtitle">
        <div class="user__subUsers--subtitle__dateFilter filters__row">
          <div class="form-group chartFilter">
            <div class="chartFilter__box">
              <VueDatePicker
                class="datePicker"
                v-model="userFilterDate"
                placeholder="Date range"
                range
                :hide-navigation="['time']"
                :enable-time-picker="false"
              >
                <template #input-icon v-if="!userFilterDate">
                  <img
                    class="input-slot-image"
                    src="@/assets//arrowDown.png"
                  />
                </template>
                <template #action-row="{ selectDate, closePicker }">
                  <div class="action-row">
                    <button
                      class="select-button btn btn-light"
                      @click="closePicker"
                    >
                      Reset
                    </button>
                    <button
                      class="select-button btn btn-outline-primary"
                      @click="selectDate"
                    >
                      Done
                    </button>
                  </div>
                </template>
              </VueDatePicker>
            </div>
          </div>
          <div
            class="btn btn-outline-primary btnDone"
            @click="() => console.log(userFilterDate, 'userFilterDate')"
          >
            Filter
          </div>
        </div>
        <div class="user__subUsers--subtitle__button">
          <button
            class="btn btn-primary create-sub-btn"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop5"
          >
            <div class="plus">+</div>
            <div class="text">Create Sub-User</div>
          </button>
        </div>
      </div>
      </div>
      <div class="container-fluid users__subUsers--dashboard dashboard d-none">
        <div class="row">
          <DashboardSummary type="User"></DashboardSummary>
        </div>
      </div>
      <div class="container-fluid user__subUsers--charts charts d-none">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="col-12">
              <div class="summaryTitle">ChatGPT Credit Usage</div>
            </div>
            <div class="deep-date_container">
                <VueDatePicker
                  v-model="deepDate"
                  placeholder="Date range"
                  range
                  :hide-navigation="['time']"
                  :enable-time-picker="false"
                  class="deep-date"
                  >
                  <!-- month-picker -->
                  <template #input-icon v-if="!deepDate">
                    <img
                      class="input-slot-image"
                      src="@/assets//arrowDown.png"
                      alt=""
                    />
                  </template>
                  <template #action-row="{ selectDate, closePicker }">
                    <div class="action-row">
                      <button
                        class="select-button btn btn-light"
                        @click="closePicker"
                      >
                        Reset 
                      </button>
                      <button
                        class="select-button btn btn-outline-primary"
                        @click="selectDate"
                      >
                        Done
                      </button>
                    </div>
                  </template>
                </VueDatePicker>
              </div>
            <ChatGptChart :testChart="false" :userId="currentUserId"></ChatGptChart>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-5 offset-lg-1">
            <div class="col-12">
              <div class="summaryTitle">DeepGram Credit Usage</div>
              <div class="deep-date_container">
                <VueDatePicker
                  v-model="deepDate"
                  placeholder="Date range"
                  range
                  :hide-navigation="['time']"
                  :enable-time-picker="false"
                  class="deep-date"
                  >
                  <!-- month-picker -->
                  <template #input-icon v-if="!deepDate">
                    <img
                      class="input-slot-image"
                      src="@/assets//arrowDown.png"
                      alt=""
                    />
                  </template>
          
                  <template #action-row="{ selectDate, closePicker }">
                    <div class="action-row">
                      <button
                        class="select-button btn btn-light"
                        @click="closePicker"
                      >
                        Reset 
                      </button>
                      <button
                        class="select-button btn btn-outline-primary"
                        @click="selectDate"
                      >
                        Done
                      </button>
                    </div>
                  </template>
                </VueDatePicker>
              </div>
              <ChatGptChart :testChart="true"></ChatGptChart>
            </div>
          </div>
        </div>
      </div>
      
      <div class="user__subUsers--content">
        <TableLoader v-if="subUsersLoading" class="loading"></TableLoader>
        <div
          class="user__subUsers--content__table"
          v-else-if="subUsersItems?.length > 0"
        >
          <UsersCustomTable
            :data="tableData.subUserTitles"
            :allUsers="subUsers"
            type="User"
            @deactivateLicenseKeySubUser="disabledLicenseKey"
            @editSubUser="editSubUser"
            @getUsersPerPage="getUsersPerPage"
            @editUserStatus="editUserStatus"
            @enabledLicenseKeySubUser="enableLicenseKeySubUser"
          ></UsersCustomTable>
        </div>
        <div v-else class="noData">
          <UtilsIcon name="noData" width="131" height="174"></UtilsIcon>
        </div>
      </div>
    </div>
  </section>
  <section>
    <CreateSubUserModal
      v-if="role !== 'SubUser'"
      class="modal fade"
      id="staticBackdrop5"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel5"
      aria-hidden="true"
      @createSubUser="createSubUser"
    ></CreateSubUserModal>
    <div class="subUsers__subtitle">
      <ToastAlert
        :message="disabledLicenseKeyMessage"
        :message-text-success="disabledLicenseKeyMessage.success"
        :message-text-error="disabledLicenseKeyMessage.failure"
        @hideElement="resetDisabledLicenseKeyMessage"
      ></ToastAlert>
      <ToastAlert
        :message="enabledLicenseKeyMessage"
        :message-text-success="enabledLicenseKeyMessage.success"
        :message-text-error="enabledLicenseKeyMessage.failure"
        @hideElement="resetEnabledLicenseKeyMessage"
      ></ToastAlert>
      <ToastAlert
        :message="changeUserFullNameMessage"
        :message-text-success="changeUserFullNameMessage.success"
        :message-text-error="changeUserFullNameMessage.failure"
        @hideElement="resetChangeUserFullNameMessage"
      ></ToastAlert>
      <div v-if="subUserInviteSuccess !== null" class="toastAlert">
        <div
          class="alertType alert"
          :class="
            subUserInviteSuccess === true ? 'alert-success' : 'alert-danger'
          "
        >
          <div class="textW" style="width: 89%">
            {{
              subUserInviteMessage.length > 100
                ? subUserInviteMessage.split(".")[0] + " !"
                : subUserInviteMessage
            }}
          </div>
          <div>
            <button
              @click="store.dispatch('hideToastAlert')"
              type="button"
              class="close btn"
              :class="
                subUserInviteSuccess === true
                  ? 'btn-outline-success'
                  : 'btn-outline-danger'
              "
              data-dismiss="alert"
              aria-label="Close"
            >
              &times;
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import CreateSubUserModal from "@/components/popups/CreateSubUserModal.vue";
import UsersCustomTable from "@/components/tables/UsersCustomTable.vue";
import TableLoader from "@/components/utils/TableLoader.vue";
import ToastAlert from "@/components/utils/ToastAlert.vue";
import UtilsIcon from "@/components/utils/UtilsIcon.vue";
import DashboardSummary from "@/components/dashboard/DashboardSummary.vue";
import ChatGptChart from "@/components/charts/ChatGptChart.vue";
import DeepGramChart from "@/components/charts/DeepGramChart.vue";
import SubscriptionChart from "@/components/charts/SubscriptionChart.vue";
import VersionUpdateModal from '@/components/popups/VersionUpdateModal.vue';
import router from "@/router";
import { ref, computed, watch, onMounted, inject, nextTick } from "vue";
import { useStore } from "vuex";
import { Modal } from 'bootstrap';
/* all */
const store = useStore();
const emitter = inject("emitter");
const currentUserData = computed(() => {
  return store.getters.getCurrentUser;
});

const currentUserId = computed(() => currentUserData.value.id);
console.log('Initial Current User:', currentUserData.value);
console.log('Initial Current User ID:', currentUserId.value);
const value = ref([]);
const options = ref([
  { name: "Creative Club", code: 1 },
  { name: "PressPlay", code: 2 },
  { name: "Automatic Script", code: 3 },
]);
const roleValue = ref([]);
const roleOptions = [
  { title: "Admin", id: 1 },
  { title: "User", id: 2 },
  { title: "SubUser", id: 3 },
];

const currentVersion = computed(() => {
  const versions = store.getters.getLatestVersions;
  if (versions.length > 0) {
    return versions[0].version;
  }
  return 'N/A';
});


// const windowsVersion = computed(() => {
//   const windowsVersion = store.getters.getLatestVersions.find(v => v.device === 'WINDOWS');
//   return windowsVersion ? windowsVersion.version : 'N/A';
// });

// const macVersion = computed(() => {
//   const macVersion = store.getters.getLatestVersions.find(v => v.device === 'MAC');
//   return macVersion ? macVersion.version : 'N/A';
// });

const openUpdateModal = () => {
  const modalElement = document.getElementById('versionUpdateModal');
  const modal = new Modal(modalElement);
  modal.show();
};

onMounted(() => {
  console.log('Component mounted');
  store.dispatch('getLatestVersions');
  if (currentUserId.value) {
    console.log('Dispatching getChartMonthly with user ID:', currentUserId.value);
    store.dispatch('getChartMonthly', currentUserId.value);
  } else {
    console.log('User ID not available on mount. Current user:', currentUserData.value);
  }
});

const role = computed(() => currentUserData.value.role);

/* admin variables*/
const allUsers = computed(() => store.getters.getAllUsers);
const allUsersItems = computed(() => store.getters.getAllUsers?.items);
const allUsersLoading = computed(() => store.getters.getAllUsersLoading);
/* charts block start */
const getChartUsersData = () => {
  store.dispatch("getChartsUserProducts");
};
const subscriptionChartInfo = computed(
  () => store.getters.getSubscriptionCharts
);

const gptDate = ref(null);
const deepDate = ref(null);

const updateChatGptChart = (date) => {
  if (date && date.length === 2) {
    store.dispatch('fetchChatGptUsage', {
      fromDate: date[0].toISOString().split('T')[0],
      toDate: date[1].toISOString().split('T')[0]
    });
  }
};

const updateDeepGramChart = (date) => {
  if (date && date.length === 2) {
    store.dispatch('fetchDeepGramUsage', {
      fromDate: date[0].toISOString().split('T')[0],
      toDate: date[1].toISOString().split('T')[0]
    });
  }
};

const resetChatGptDate = (closePicker) => {
  gptDate.value = null;
  store.dispatch('fetchChatGptUsage');
  closePicker();
};

const resetDeepGramDate = (closePicker) => {
  deepDate.value = null;
  store.dispatch('fetchDeepGramUsage');
  closePicker();
};

const chartLoading = computed(() => store.getters.getChartsDataLoading);
const chartErrorMessage = computed(() => store.getters.getChartsErrorMessage);



const getChartMonthly = () => {
  store.dispatch("getChartMonthly");
};


/* charts block end */

const disabledLicenseKeyMessage = computed(
  () => store.getters.getDisabledLicenseKeyMessage
);
const enabledLicenseKeyMessage = computed(
  () => store.getters.getEnabledLicenseKeyMessage
);
const currentPageData = ref();
// const currentLicenseId = ref();
const tableData = ref({
  titles: [
    "Full Name",
    "Email Address",
    "Subscription Plan",
    "Creative Club Status",
    "Role",
    "Subscription Date",
    "Profile Status",
    "ChatGPT Usage",
    "DeepGram Usage",
    "Transaction ID",
    "Subscription ID",
    "EULA",
    "Actions",
  ],
  subUserTitles: [
    "Full Name",
    "Email address",
    "Invitation Date",
    "Invitation Status",
    "License Key",
    "Profile Status",
    "Actions",
  ],
});
const searchData = ref("");
const fromToFilterDate = ref();
const subscriptionDate = ref();
const changeUserFullNameMessage = computed(
  () => store.getters.getChangeUserFullNameMessage
);
const adminLoginasUserPageError = computed(
  () => store.getters.getLoginUserPageError
);

/* user variables */

const subUserInviteMessage = computed(() => {
  return store.getters.getSubUserInviteMessage;
});
const subUserInviteSuccess = computed(() => {
  return store.getters.getSubUserInviteSuccess;
});
const subUsers = computed(() => {
  return store.getters.getInvitedSubUsers;
});
const subUsersItems = computed(() => store.getters.getInvitedSubUsers?.items);

const subUsersLoading = computed(() => store.getters.getInvitedSubUsersLoading);

const userFilterDate = ref();

const filterData = ref({
  page: 1,
  limit: 10,
});
/* admin functions */
// function deactivateUser(index, currentPage, role) {
//   currentPageData.value = currentPage;
//   store.dispatch("deactivateUser", index);
//   if (role === "SubUser") {
//     getSubUserData(currentPageData);
//   } else {
//     getAllUsersFunc(currentPageData);
//   }
// }

const disabledLicenseKey = (index, currentPage, role) => {
  currentPageData.value = currentPage;
  store.dispatch("disabledLicenseKey", index);
  nextTick(() => {
    if (role === "SubUser") {
      getSubUserData(currentPageData);
    } else {
      filterData.value.page = currentPageData;
      getAllUsersFuncForAdmin(filterData.value);

      // getAllUsersFunc(currentPageData);
    }
  });
};
const enableLicenseKeySubUser = (index, currentPage, role) => {
  currentPageData.value = currentPage;
  store.dispatch("enabledLicenseKey", index);
  nextTick(() => {
    if (role === "SubUser") {
      getSubUserData(currentPageData);
    } else {
      // getAllUsersFunc(currentPageData);
      filterData.value.page = currentPageData;
      getAllUsersFuncForAdmin(filterData.value);
    }
  });
};
function getUsersPerPage(page, itemsPerPage = 10) {
  if (role.value == "Admin") {
    filterData.value.page = page;
    filterData.value.limit = itemsPerPage;
    getAllUsersFuncForAdmin(filterData.value);
  } else {
    store.dispatch("allUsers", { page, limit: itemsPerPage });
  }
}
  // if (roleValue.value?.title) {
  // store.dispatch("allUsers", {
  //   page,
  //   limit: 10,
  //   role: roleValue.value.title,
  // });

  // } else {
  // store.dispatch("allUsers", { page, limit: 10 });
  // }

function editUserStatus(userData) {
  let { firstName, lastName, id, status } = userData;
  store.dispatch("changeUserFullNameWithAdmin", {
    firstName,
    lastName,
    id,
    status,
  });
  if (role.value == "User") {
    getSubUserData();
  }
}
function loginUserPage(index) {
  store.dispatch("loginUserPage", index);
  emitter.emit("loginUserPage", true);
}

// function resetDeactivateUserMessage() {
//   store.dispatch("resetDeactivateUserMessage");
// }
function resetDisabledLicenseKeyMessage() {
  store.dispatch("resetDisabledLicenseKeyMessage");
}
function resetEnabledLicenseKeyMessage() {
  store.dispatch("resetEnabledLicenseKeyMessage");
}
function resetChangeUserFullNameMessage() {
  store.dispatch("resetChangeUserFullNameMessage");
}

// function getAllUsersFunc(page = 1, limit = 10, role) {
//   if (role) {
//     store.dispatch("allUsers", { page, limit, role });
//   } else {
//     store.dispatch("allUsers", { page, limit });
//   }
// }

const getAllUsersFuncForAdmin = (dataFilter) => {
  store.dispatch("allUsersForAdmin", dataFilter);
};

function resetBtn() {
  searchData.value = "";
  value.value = "";
  roleValue.value = ""; 
  fromToFilterDate.value = null

  filterData.value = {
    page: 1,
    limit: 10,
    searchData: "",
    role: [],
    subscriptionType: "",
    fromDate: null,
    toDate: null,
  };

  // Fetch default table data
  if (role.value === "Admin") {
    getAllUsersFuncForAdmin(filterData.value);
  }
}

function applyFilter() {
  if (fromToFilterDate.value) {
    if (fromToFilterDate.value[0]) {
      filterData.value.fromDate = JSON.stringify(fromToFilterDate.value[0]);
    }

    if (fromToFilterDate.value[1]) {
      filterData.value.toDate = JSON.stringify(fromToFilterDate.value[1]);
    }
  }
  filterData.value.page = 1
  filterData.value.limit = 10
  filterData.value.searchData = searchData.value;
  filterData.value.role = roleValue.value;


  if (role.value === "Admin") {
    getAllUsersFuncForAdmin(filterData.value);
  }
  // if (roleValue.value?.title) {
  // let role = roleValue.value.title;
  // getAllUsersFunc(1, 10, role);
  // } else {
  //   getAllUsersFunc(1, 10);
  // }
}
/*user function*/

function editSubUser(data) {
  console.log(data, "editSubUser");
}

function getSubUserData(page = 1, limit = 10) {
  store.dispatch("getInvitedSubUsers", { page, limit });
}

function createSubUser(user) {
  let { firstName, lastName, selectId, email } = user;
  let fullName = firstName + " " + lastName;
  store.dispatch("subUserInvite", {
    fullName,
    invitationUserEmail: email,
    licenseId: +selectId,
  });
}

onMounted(() => {
  if (currentUserId.value) {
    store.dispatch('getChartMonthly', currentUserId.value);
  }
});
/* all watch */
watch(
  () => role.value,
  () => {
    if (role.value == "Admin") {
      // getAllUsersFunc();
      getAllUsersFuncForAdmin(filterData.value);
      getChartUsersData();
      getChartMonthly();
    } else if (role.value == "User") {
      getSubUserData();
    } else {
      router.push("/dashboard");
    }
  }
);
/* admin watch */
watch(
  () => adminLoginasUserPageError.value,
  () => {
    if (!adminLoginasUserPageError.value) {
      store.dispatch("resetAdminLoginasUserPageError");
      router.push("/contact");
    }
  }
);

watch(disabledLicenseKeyMessage.value, () => {
  // getAllUsersFunc(currentPageData.value);
  if (
    disabledLicenseKeyMessage.value?.success ||
    disabledLicenseKeyMessage.value?.failure
  ) {
    setTimeout(() => {
      resetDisabledLicenseKeyMessage();
    }, 1500);
  }
});
watch(enabledLicenseKeyMessage.value, () => {
  // getAllUsersFunc(currentPageData.value);
  if (
    enabledLicenseKeyMessage.value?.success ||
    enabledLicenseKeyMessage.value?.failure
  ) {
    setTimeout(() => {
      resetEnabledLicenseKeyMessage();
    }, 1500);
  }
});
watch(changeUserFullNameMessage.value, () => {
  if (
    changeUserFullNameMessage.value?.success ||
    changeUserFullNameMessage.value?.failure
  ) {
    setTimeout(() => {
      resetChangeUserFullNameMessage();
    }, 3000);
  }
  if (changeUserFullNameMessage.value?.success) {
    setTimeout(() => {
      filterData.value.page = currentPageData.value;
      getAllUsersFuncForAdmin(filterData.value);
      // getAllUsersFunc(currentPageData.value);
    }, 200);
  }
});
/* user watch */
watch(
  () => subUserInviteSuccess.value,
  () => {
    if (
      subUserInviteSuccess.value === true ||
      subUserInviteSuccess.value === false
    ) {
      getSubUserData();
      setTimeout(() => {
        store.dispatch("hideToastAlert");
      }, 5000);
    }
  }
);

onMounted(() => {
  if (role.value == "Admin") {
    // getAllUsersFunc();
    getAllUsersFuncForAdmin(filterData.value);
    getChartMonthly();
  } else if (role.value == "User") {
    getSubUserData();
  } else if (role.value == "SubUser") {
    router.push("/dashboard");
  }
});
</script>

<style lang="scss" scoped>
/* user */
.user {
  background: #f7f8fc;
  width: 100%;
  height: 100vh;
  &__subUsers {
    padding: 23px 31px !important;
    margin: 0 !important;
    width: 100%;
    background: #f7f8fc;
    &--title {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        span {
          font-family: "Montserrat Regular";
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 2.5px;
          color: #2a3f7a;
        }
      }
    }

    &--subtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__text {
        font-family: "Montserrat Bold";
        font-size: 13px;
        line-height: 19.5px;
        color: #a3adcd;
      }
      &__button {
        width: 182px;
        height: 39px;
        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          gap: 11px;
          font-size: 16px;
          border-radius: 6px;
          background-color: #2a61ff !important;
          .text {
            color: #ffffff;
          }
          .plus {
            font-size: 30px;
            line-height: 17px;
            color: #ffffff;
          }
        }
      }
      &__dateFilter {
        display: flex !important;
        gap: 15px !important;
        align-items: center !important;
        margin: 20px 0 !important;
        .btnDone {
          align-items: center;
          display: flex;
          height: 43px !important;
          width: 101px;
          text-align: center;
          display: flex;
          justify-content: center;
        }
      }
    }
    &--content {
      margin-top: 35px;
      border-radius: 6px !important;
      border: 1.5px solid #ebedf3 !important;
      div {
        left: 0 !important;
        top: 220px;
      }
    }
    &--charts {
      margin-top: 66px !important;
    }
  }
}
/* admin */
.admin {
  background: #f7f8fc;
  width: 100%;
  height: 100vh;
  &__users {
    padding: 23px 31px !important;
    margin: 0 !important;
    width: 100%;
    background: #f7f8fc;
    &--title {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        span {
          font-family: "Montserrat Regular";
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 2.5px;
          color: #2a3f7a;
        }
      }
    }
    .dashboard {
      &__subscripe {
        &--box {
          position: relative;

          .chartFilter {
            width: 300px;
            position: absolute;
            top: 45px;
            right: 0;
            &__box {
              position: relative;
              z-index: 100;
            }
            label {
              position: absolute;
              top: 40%;
              right: 10px;
            }
          }
        }
      }
    }
    .charts {
      margin-top: 66px;
    }
    .filters {
      &__row {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin: 66px 0;
        &--searchInput {
          width: 362px;
          svg {
            position: absolute;
            top: 12px;
            right: 15px;
          }
        }
        &--searchInput,
        &--dropdowns {
          position: relative;
        }
        &--button {
          width: 151px;
          button {
            height: 43px;
          }
        }

        .icons {
          position: absolute;
          right: 15px;
          bottom: 39%;
        }
        .searchInput {
          width: 100%;
          height: 100%;
          input {
            color: #333 !important;
            height: 43px;
          }
          input::placeholder {
            color: #a3adcd;
            opacity: 1; /* Firefox */
            font-family: "Montserrat Medium";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #a3adcd;
            font-family: "Montserrat Medium";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #a3adcd;
            font-family: "Montserrat Medium";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }

          div {
            color: #b3b3b3;
            font-family: "Montserrat Regular";
            font-size: 11px;
            text-align: left;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
        &--productSelect {
          width: 407px;
        }
        &--roleSelect {
          width: 317px;
        }
      }
    }
    &--subtitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      &--text {
        font-family: "Montserrat Bold";
        font-size: 13px;
        line-height: 19.5px;
        color: #a3adcd;
      }
      &--button {
        width: 182px;
        height: 39px;
        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          gap: 11px;
          font-size: 16px;
          border-radius: 6px;
          background-color: #2a61ff !important;
          .text {
            color: #ffffff;
          }
          .plus {
            font-size: 30px;
            line-height: 17px;
            color: #ffffff;
          }
        }
      }
    }
    &--content {
      &__table {
        border-radius: 14px;
      }
      margin-top: 35px;
      div {
        left: 0 !important;
        top: 220px;
      }
    }
  }
}



/* others */
.toastAlert {
  width: 350px;
  position: absolute;
  right: 30px;
  top: 200px;
  .alertType {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    button {
      top: 15px;
      right: 15px;
      position: absolute;
      width: 24px;
      height: 24px;
      display: flex;
      font-size: 21px;
      align-items: center;
      justify-content: center;
      border-radius: 4px !important;
    }
  }
}

.alert-danger {
  div {
    button {
      border: 2px solid #842029;
      color: #842029;
      &:hover {
        background-color: #842029 !important;
        color: #fff !important;
      }
    }
  }
}
.line {
  width: 100%;
}
.loading {
  background-color: #fff !important;
}
.noData {
  height: 500px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.summaryTitle {
  color: #2a3f7a;
  font-family: "Montserrat Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
  margin-bottom: 23px;
}
.input-slot-image {
  height: 8px;
  width: auto;
  margin-right: 12px;
}
.action-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  button {
    width: 48%;
  }
}

::v-deep {
  .dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
    padding-left: 15px !important;
    height: 43px !important;
  }

  .dp__input_wrap {
    width: fit-content !important;
    position: absolute;
    max-width: 210px;
    right: 20px;
    z-index: 99;
  }

  .dp__input_wrap .dp__input {
    font-size: 14px !important;
    font-family: "Montserrat", sans-serif;
  }

  .filters__row .dp__input_wrap {
    position: relative !important;
    right: 0 !important;
  }
  .dp__input_wrap {
    input::placeholder {
      color: #a3adcd;
      opacity: 1; /* Firefox */
      font-family: "Montserrat Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a3adcd;
      font-family: "Montserrat Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #a3adcd;
      font-family: "Montserrat Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
  .dp__input_icon {
    right: 0 !important;
    left: auto !important;
  }
  .dp__cell_auto_range_start,
  .dp__date_hover_start:hover,
  .dp__range_start,
  .dp__range_between,
  .dp__today,
  .dp__cell_auto_range_end,
  .dp__date_hover_end:hover,
  .dp__range_end {
    border-radius: 50%;
  }
  .dp__calendar_header_item {
    color: #94a3b8;
    font-family: "Montserrat Semi Bold";
    font-size: 12px;
  }
  .dp__menu {
    width: 295px !important;
    border-radius: 9px !important;
    top: 45px !important;
    right: 0 !important;
    left: unset !important;
    box-shadow: 0px 3.5025827884674072px 35.02582931518555px 0px #eee,
      0px 3.5025827884674072px 7.0051655769348145px 0px rgba(66, 71, 76, 0.05),
      0px 0px 0.4378228485584259px 0px rgba(66, 71, 76, 0.32);
  }

  .filters__row .dp__menu {
    right: -80px !important
  }
  .dp__month_year_select {
    font-size: 15px !important;
    font-family: "Montserrat Semi Bold" !important;
    color: #000 !important;
  }
  .dp__calendar_item {
    font-size: 14px;
    font-family: "Montserrat Medium";
  }
  .dp__calendar_header_separator {
    height: 0px !important;
  }
  .dp__pointer
    .dp__input_readonly
    .dp__input
    .dp__input_icon_pad
    .dp__input_reg {
    color: #333 !important;
  }
  .dp__arrow_top {
    display: none !important;
  }

  .caret {
    display: none;
  }
  .apexcharts-toolbar {
    display: none !important; 
  }
}

.multiselect__element .multiselect__option--highlight[data-select]:hover::after  {
  content: 'whatever it is you want to add' !important;
  background-color: #03d670 !important;
}

::v-deep {
  .multiselect__height {
    min-height: 43px;
  }
  .multiselect__tag-icon::after {
    color: #eee !important;
  }
  .multiselect__option--highlight {
    background-color: #03d670 !important;
  }
  .multiselect__option--highlight:after {
    background-color: #03d670 !important;
    color: #03d670 !important;
  }
  .multiselect__single {
    color: #333 !important;
  }
  .multiselect__placeholder {
    color: #a3adcd;
    font-family: "Montserrat Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .multiselect__tag {
    background-color: #03d670 !important;
  }
  .multiselect__option--highlight
    .multiselect__option--selected
    .multiselect__option {
    background-color: #03d670 !important;
  }
  .multiselect__option--selected.multiselect__option--highlight {
    background-color: #f00 !important;
  }
  .multiselect__option--selected.multiselect__option--highlight::after {
    background-color: #f00 !important;
    color: #f00 !important;
  }
}
.caretArrowDown {
  position: absolute !important;
  right: 16px !important;
  top: 19px !important;
  text-transform: rotate(180deg);
}

.gpt-date_container, .deep-date_container {
  position: relative;
  margin-bottom: 5px;
}
.gpt-date, .deep-date {
  display: flex !important;
  justify-content: right !important;
}
@media(max-width: 768px) {
.create-sub-btn div {
  font-size: 12px;
}
}

.version-update-btn {
  padding: 8px 12px;
  border-radius: 12px;
  font-family: Nunito Medium;
  font-weight: 600;
  background-color: #1d2031;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease ;
}

.version-span {
  font-size: 16px;
  font-weight: 700;
  color: #333;
}
</style>
