<template>
  <div class="trainingText">
    <div class="trainingContent">
      <h4>TRAINING</h4>
      <div class="dashLine" />
    </div>
    <div class="sec-training-Content text-start">
      <p>
        Need help with getting started with EasyVSL? Before contact support, we
        recommend watching the training videos below.
      </p>
    </div>
    <div class="title row">
      <div class="col-lg-6 col-sm-6">
        <div class="vdo-content">
          <h2>Installing EasyVSL</h2>
          <div style="padding:56.25% 0 0 0;position:relative; border-radius: 12px;"><iframe src="https://player.vimeo.com/video/1003744469?badge=0&autopause=0&player_id=0&app_id=58479 " frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="installing-evsl"></iframe></div></div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="vdo-content">
          <h2>Understanding the Video Types</h2>
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1003748568?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="understanding-the-video-types"></iframe></div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="vdo-content">
          <h2>Creating a Classic Style VSL</h2>
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1003760703?badge=0&autopause=0&player_id=0&app_id=58479 " frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="classic-vsl"></iframe></div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="vdo-content">
          <h2>Creating a Kinetic Style VSL</h2>
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1003765916?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="kinetic-style"></iframe></div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="vdo-content">
          <h2>Creating a Live Action VSL</h2>
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1003777930?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="liveaction-vsl"></iframe></div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="vdo-content">
          <h2>Controlling Your Captions</h2>
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1004114925?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="captions"></iframe></div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="vdo-content">
          <h2>Adding Audio to Your VSLs</h2>
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1004118085?badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<style>
  .vdo-content {
    margin-bottom: 20px;
  }
</style>