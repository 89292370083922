<template>
  <table class="table">
    <thead>
      <tr>
        <th
          scope="col"
          v-for="(title, index) in props.data"
          :key="title?.id"
          :class="index == props?.data?.length - 1 ? 'sticky' : ''"
        >
          <div v-if="index == '7' || index == '8'" class="d-flex gap-2">
            {{ title }}
            <UtilsIcon name="sort" width="16" height="13"></UtilsIcon>
          </div>
          <div v-else>{{ title }}</div>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="user in allUsersItems" :key="user?.id">
        <td class="text-truncate">
          {{ `${user?.fullName}` }}
        </td>
        <td class="text-truncate">{{ user.email }}</td>
        <td class="text-truncate">
          <!-- should be the product types (Creative club, PressPlay, Automatic script) -->
          {{ user?.subscriptionStatus ? user.subscriptionStatus : "INACTIVE" }}
        </td>

        <td class="text-truncate">
          <div>
            {{
              user?.creativeClubStatus ? user.creativeClubStatus : "INACTIVE"
            }}
          </div>
        </td>
        <td class="text-truncate">{{ user?.role }}</td>
        <td class="text-truncate">
          {{
            user?.creditUsageData[0]?.day
              ? user.creditUsageData[0].day + " /"
              : ""
          }}
          {{
            user?.creditUsageData[0]?.month
              ? user.creditUsageData[0].month
              : ""
          }}
          /
          {{
            user?.creditUsageData[0]?.year
              ? user.creditUsageData[0].year
              : ""
          }}
        </td>
        <td class="text-truncate">
          {{ user?.subscriptionStatus ? user.subscriptionStatus : "INACTIVE" }}
        </td>
        <td class="text-truncate">
          <div>{{ user?.totalAudioText ? user?.totalAudioText : 0 }} Words</div>
        </td>
        <td class="text-truncate">
          <div>
            {{ user?.totalScryptGenerator ? user?.totalScryptGenerator : 0 }}
            Hours
          </div>
        </td>
        <td class="text-truncate">
          <div>
            {{ user?.transactionId ? user.transactionId : "" }}
          </div>
        </td>
        <td class="text-truncate">
          <div>
            {{ user?.invoiceId ? user.invoiceId : "" }}
          </div>
        </td>
        <td class="text-truncate">
            <div style="text-transform: uppercase;">
              {{ user?.eula?.agreed }}
            </div>
        </td>
        <td class="table__body--actions actions text-truncate">
          <div v-if="user.role === 'Admin'"></div>
          <div v-else-if="user.role === 'User'">
            <template v-if="user.subscriptionStatus === 'ACTIVE'">
              <UtilsIcon
                id="profile"
                name="profile"
                width="19"
                height="19"
                color="#CACFDF"
                tooltipTitle="Go to user profile page"
                @click="() => loginUserPage(user?.id)"
              ></UtilsIcon>
            </template>
            <template v-else>
              <UtilsIcon
                id="profile2"
                name="profile"
                width="19"
                height="19"
                color="red"
              ></UtilsIcon>
            </template>

            <UtilsIcon
              v-if="user.subscriptionStatus === 'ACTIVE'"
              id="pencil"
              tooltipTitle="Edit this user's full name and status"
              name="pencil"
              width="19"
              height="19"
              color="#CACFDF"
              data-bs-toggle="modal"
              data-bs-target="#editSubUserModal"
              @click.prevent="() => editSubUser(user?.id)"
            ></UtilsIcon>
            <UtilsIcon
              v-else
              id="pencil3"
              name="pencil"
              width="19"
              height="19"
              color="red"
            ></UtilsIcon>

            <UtilsIcon
              v-if="user.subscriptionStatus === 'ACTIVE'"
              name="recycle"
              width="19"
              height="19"
              color="#CACFDF"
              data-bs-toggle="modal"
              data-bs-target="#staticDefaultModal"
              @click="getLicenseKeyId(user.licenseKeyId)"
              id="recycle"
            ></UtilsIcon>
            <UtilsIcon
              v-else
              name="recycle"
              width="19"
              height="19"
              color="red"
              data-bs-toggle="modal"
              data-bs-target="#staticDefaultModal"
              @click="getLicenseKeyId(user.licenseKeyId)"
              id="recycle"
            ></UtilsIcon>
          </div>
          <div v-else-if="user.role === 'SubUser'">
            <template v-if="user.subscriptionStatus !== 'ACTIVE'">
              <UtilsIcon
                id="profile2"
                name="profile"
                width="19"
                height="19"
                color="red"
              ></UtilsIcon>
              <UtilsIcon
                id="pencil3"
                name="pencil"
                width="19"
                height="19"
                color="red"
              ></UtilsIcon>
              <UtilsIcon
                name="recycle"
                width="19"
                height="19"
                color="red"
                data-bs-toggle="modal"
                data-bs-target="#staticDefaultModal"
                @click="getLicenseKeyId(user.licenseKeyId)"
                id="recycle"
              ></UtilsIcon>
            </template>
            <template v-else>
              <UtilsIcon
                tooltipTitle="Go to sub-user profile page"
                id="profile"
                name="profile"
                width="19"
                height="19"
                color="#CACFDF"
                @click="() => loginUserPage(user?.id)"
              ></UtilsIcon>
              <UtilsIcon
                tooltipTitle="Edit this sub-user's full name and status"
                id="pencil"
                name="pencil"
                width="19"
                height="19"
                color="#CACFDF"
                data-bs-toggle="modal"
                data-bs-target="#editSubUserModal"
                @click.prevent="() => editSubUser(user?.id)"
              ></UtilsIcon>
              <UtilsIcon
                name="recycle"
                width="19"
                height="19"
                color="#CACFDF"
                data-bs-toggle="modal"
                data-bs-target="#staticDefaultModal"
                @click="getLicenseKeyId(user.licenseKeyId)"
                id="recycle"
              ></UtilsIcon>
            </template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import UtilsIcon from "@/components/utils/UtilsIcon.vue";
const props = defineProps({
  data: Array,
  allUsersItems: Array,
});
const emits = defineEmits(["loginUserPage", "editSubUser", "getLicenseKeyId"]);

const getLicenseKeyId = (id) => {
  emits("getLicenseKeyId", id);
};

const editSubUser = (id) => {
  emits("editSubUser", id);
};

const loginUserPage = (id) => {

  emits("loginUserPage", id);
};
</script>
<style lang="scss" scoped>
.container-fluid {
  margin: 0;
  text-align: left;
}
.table {
  margin-top: 23px;
  padding: 0 36px;
  background-color: #ffffff;
  padding: 0 0 0 15px !important;
  margin: 0 !important;
  border-left: 0;

  thead {
    th {
      word-break: break-all;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      padding: 15px 5px;
    }
  }
  tbody {
    td {
      word-break: break-all;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: #333333;
      padding: 15px 5px;
    }
  }
  &__head {
    &--title {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      height: 64px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    &--actions {
      display: flex;
      min-height: 55.5px;
      z-index: 1000;
      flex-direction: row;
      gap: 34px;
      position: sticky !important;
      right: -145px !important;

      background-color: white;
      div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }
  }
  .row {
    padding: 0 12px;
    align-items: center;
    height: 64px;
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
.tableAdmin {
  border-radius: 10px;
  thead,
  tbody tr {
    display: table;
    width: calc(3500px - 1em);
    table-layout: fixed;
  }

  margin-top: 23px;
  background-color: #ffffff;
  margin: 0 !important;

  thead {
    width: calc(3500px - 1em);

    th {
      word-break: break-all;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      padding: 15px 5px;
    }
  }
  tbody {
    width: calc(3500px - 1em);
    tr {
      border-bottom: 1px solid #e9ecf4;
    }
    td {
      word-break: break-all;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: #333333;
      padding: 15px 5px;
      border-bottom: none !important;
    }
  }
  &__head {
    &--title {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #333333;
      height: 64px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    &--actions {
      display: flex;
      min-height: 55.5px;
      z-index: 1000;
      flex-direction: row;
      gap: 34px;
      position: sticky !important;
      right: -145px !important;
      position: sticky !important;
      right: -145px !important;
      background-color: white;
      div {
        cursor: pointer;
      }
    }
  }
  .row {
    padding: 0 12px;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #e9ecf4;
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
.adminTableBox {
  border-radius: 10px;
  border: 1.5px solid #e9ecf4;
  background-color: #fff;
  padding: 0 15px 15px 15px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
  gap: 10px;
  li {
    a {
      border-radius: 8px;
    }
  }
}

.disableLink {
  color: gray !important;
}
.enableeLink {
  color: #0a58ca !important;
}

#pencil:hover,
#profile:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(14%) saturate(3425%)
    hue-rotate(190deg) brightness(94%) contrast(90%);
}
#recycle:hover {
  filter: brightness(0) saturate(100%) invert(29%) sepia(98%) saturate(7173%)
    hue-rotate(356deg) brightness(103%) contrast(118%);
}
::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px; /* width of vertical scrollbar */
  border: 1px solid #a3adcd;
  background: #e9ecf4;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px !important;
  background-color: #a3adcd;
  cursor: pointer;
}
td:hover {
  overflow: visible;
  z-index: 100;
  backdrop-filter: blur(5px);
}
.sticky {
  position: sticky;
  right: -145px;
  background-color: white;
}
</style>

