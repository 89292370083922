import { get } from "@/utils";

class SaasProductService {
  async getUserProduct() {
    try {
      const response = await get('/users/me');
      const userDetails = response.data;
      
      if (!userDetails || !userDetails.subscription) {
        console.log('User has no subscription');
        return null;
      }

      const productId = userDetails.subscription.productId;

      if (!productId) {
        console.log('User subscription has no productId');
        return null;
      }

      return await this.findProductById(productId);
    } catch (error) {
      console.error('Error fetching user product:', error);
      return null;
    }
  }

  async getAll(productType = "") {
    const params = { order: "ASC", page: 1, limit: 10 };
    if (productType) {
      params.type = productType.toUpperCase();
    }
    const response = await get('/products', { params });
    return response.data;
  }

  async getProductsCC(billingPeriod = "") {
    const products = await this.getAll("SUBSCRIPTION");
    let ccProducts = products.items.filter(
      (p) => p.productName.indexOf("Creative") !== -1
    );
    if (billingPeriod) {
      ccProducts = ccProducts.filter((p) => p.period === billingPeriod);
    }
    return ccProducts;
  }

  async findProductById(productId) {
    const response = await get(`/products/${productId}`);
    return response.data;
  }
}

export default new SaasProductService();