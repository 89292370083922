<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="box">
            <div class="login-acct">
              <div class="logo">
                <img src="../assets/_logo1.png" alt="supportive" />
              </div>
              <div v-if="!forgotPasswordSendMessage.success">
                <h3>Reset Your Password</h3>
                <div
                  v-if="
                    forgotPasswordSendMessage?.success ||
                    forgotPasswordSendMessage?.failure
                  "
                  class="alert"
                  :class="
                    forgotPasswordSendMessage?.failure ? 'alert-danger' : ''
                  "
                >
                  {{
                    forgotPasswordSendMessage?.failure
                      ? typeof forgotPasswordSendMessage?.failure == "string"
                        ? forgotPasswordSendMessage?.failure
                            .charAt(0)
                            .toUpperCase() +
                          forgotPasswordSendMessage?.failure.slice(1)
                        : forgotPasswordSendMessage?.failure[0]
                            .charAt(0)
                            .toUpperCase() +
                          forgotPasswordSendMessage?.failure[0].slice(1)
                      : forgotPasswordSendMessage?.success
                      ? forgotPasswordSendMessage.success
                      : ""
                  }}
                </div>
                <p class="text-start">
                  Enter the email address associated with your EasyVSL account
                  and we will send you an email to reset your password.
                </p>
                <div class="form">
                  <input
                    v-model="email"
                    @focusin="oninput"
                    id="exampleInputEmail1"
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Email Address"
                  />
                  <button
                    type="submit"
                    class="submitButton"
                    @click="forgotPassword"
                  >
                    Send Instructions
                  </button>
                </div>
                <div class="account">
                  <div class="bottom-part">
                    <a>
                      <router-link :to="{ name: 'login' }" exact
                        >Login in here</router-link
                      >
                    </a>
                  </div>
                </div>
              </div>
              <div v-else class="successBox">
                <h3>Reset Your Password</h3>
                <div class="alert alert-success successBox__message">
                  <UtilsIcon
                    name="checkSuccess"
                    width="16"
                    height="16"
                  ></UtilsIcon>
                  We have sent you an email.
                </div>
                <p>Please check it to reset your password.</p>
                <a href="/login">Login here</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import UtilsIcon from "@/components/utils/UtilsIcon.vue";
import { computed, ref } from "@vue/reactivity";
import { onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();
const email = ref("");
const forgotPasswordSendMessage = computed(() => {
  return store.getters.getForgotPasswordSendMessage;
});

function forgotPassword() {
  store.dispatch("forgotPassword", email.value);
}

function oninput() {
  if (
    forgotPasswordSendMessage.value.failure ||
    forgotPasswordSendMessage.value.success
  ) {
    store.dispatch("hideErrorForgotPassword");
  }
}

onMounted(() => {
});
</script>
<style lang="scss" scoped>
.successBox {
  h3 {
    margin-top: 52px;
  }
  p {
    font-size: 22px !important;
    color: #353535;
  }
  div {
    margin: 28px 0;
  }
  font-size: 16px !important;
}
.successBox {
  &__message {
    justify-content: center !important;
    align-items: center !important;
    height: 50px !important;
    display: flex !important;
    gap: 20px !important;
  }
}
</style>
