<template>
  <div v-if="!chartLoading" id="chart">
    <apexchart
      :options="options"
      :series="series"
    ></apexchart>
  </div>
  <div v-else>
    <TableLoader></TableLoader>
  </div>
</template>
<script setup>
import Apexchart from "vue3-apexcharts";
import TableLoader from "../utils/TableLoader.vue";

import { defineProps, computed } from "vue";
const props = defineProps({
  subscriptionChartInfo: {
    type: Object,
    require: true,
  },
  chartLoading: {
    type: Boolean,
    require: true,
  },
  chartErrorMessage: {
    type: String || null,
    require: true,
  },
});

const options = computed(() => {
  return {
    chart: {
      type: "line",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#2A61FF", "#03d670", "#9EB5F3"],
    yaxis: {
      labels: {
        formatter: (val) => {
          if (val == 0) {
            return 0;
          }
          return val;
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    xaxis: {
      categories: props.subscriptionChartInfo?.months
        ? props.subscriptionChartInfo?.months
        : null,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      markers: {
        radius: 12,
      },
    },
  };
});

const series = computed(() =>
  props.subscriptionChartInfo.charts
    ? props.subscriptionChartInfo?.charts
    : null
);
</script>
<style lang="scss" scoped></style>
